/* eslint-disable react/react-in-jsx-scope */
import { useEffect, useState } from 'react'
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api'
import axios from 'axios'
import { locationSuggestion } from '../../services/Service'
import './MapComponent.css'

const MapComponent = ({
  inputFields,
  setInputFields,
  markerPosition,
  onMapPositionChange
}) => {
  const mapStyles = {
    height: '200px',
    width: '100%'
  }

  const [mapCenter, setMapCenter] = useState({
    lat: Number(inputFields.latitude) || 51.16,
    lng: Number(inputFields.longitude) || 10.45
  })
  // const [markerPosition, setMarkerPosition] = useState(mapCenter);

  const handleMapClick = (e) => {
    const { latLng } = e
    const lat = latLng.lat()
    const lng = latLng.lng()
    onMapPositionChange(lat, lng)
  }
  const fetchLocationFromInput = async (
    city_name,
    zip_code,
    country,
    province_name
  ) => {
    try {
      const apiKey = locationSuggestion
      const address = `${country},${province_name},${city_name},${zip_code}`
      const response = await axios.get(
        `https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=${apiKey}`
      )

      if (response.data.status === 'OK') {
        const { location } = response.data.results[0].geometry
        setMapCenter({
          lat: location.lat,
          lng: location.lng
        })
        // setMarkerPosition(null)
        setInputFields((prev) => ({
          ...prev,
          latitude: location.lat,
          longitude: location.lng
        }))
      } else {
        console.error(
          'Geocode was not successful for the following reason:',
          response.data.status
        )
      }
    } catch (error) {
      console.error('Error fetching location:', error)
    }
  }

  useEffect(() => {
    if (
      inputFields.city_name ||
      inputFields.zip_code ||
      inputFields.country ||
      inputFields.province_name
    ) {
      fetchLocationFromInput(
        inputFields.city_name,
        inputFields.zip_code,
        inputFields.country,
        inputFields.province_name
      )
    }
  }, [
    inputFields.city_name,
    inputFields.zip_code,
    inputFields.country,
    inputFields.province_name
  ])

  useEffect(() => {
    if (inputFields.latitude && inputFields.longitude) {
      setMapCenter({
        lat: Number(inputFields.latitude),
        lng: Number(inputFields.longitude)
      })
    }
  }, [inputFields.latitude, inputFields.longitude])

  useEffect(() => {
    if (inputFields.latitude && inputFields.longitude) {
      const newMarkerPosition = {
        lat: Number(inputFields.latitude),
        lng: Number(inputFields.longitude)
      }
      setMapCenter(newMarkerPosition)
      onMapPositionChange(newMarkerPosition.lat, newMarkerPosition.lng)
    }
  }, [inputFields.latitude, inputFields.longitude])

  useEffect(() => {
    if (markerPosition) {
      setMapCenter(markerPosition)
    }
  }, [markerPosition])

  const mapOptions = {
    streetViewControl: false,
    fullscreenControl: true
  }

  return (
    <LoadScript googleMapsApiKey={locationSuggestion}>
      <GoogleMap
        mapContainerStyle={mapStyles}
        zoom={13}
        center={mapCenter}
        options={mapOptions}
        onClick={handleMapClick}
      >
        {markerPosition && <Marker position={markerPosition} />}
      </GoogleMap>
    </LoadScript>
  )
}

export default MapComponent
