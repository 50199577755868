import React, { createContext, useContext, useMemo, useState } from 'react'

const VideoProgressContext = createContext()

export const VideoProgressProvider = ({ children }) => {
  const [videoProgress, setVideoProgress] = useState({})
  const [podcastProgress, setPodcastProgress] = useState({})
  const [shotProgress, setShotProgress] = useState({})
  const [videoComProgress, setVideoComProgress] = useState({})
  const [podcastComProgress, setPodcastComProgress] = useState({})
  const [shotComProgress, setShotComProgress] = useState({})

  const updateVideoProgress = (videoId, newProgress) => {
    setVideoProgress((prevProgress) => ({
      ...prevProgress,
      [videoId]: newProgress
    }))
  }
  const updateVideoComProgress = (videoId, newProgress) => {
    setVideoComProgress((prevProgress) => ({
      ...prevProgress,
      [videoId]: newProgress
    }))
  }
  const updatePodcastProgress = (podcastId, newProgress) => {
    setPodcastProgress((prevProgress) => ({
      ...prevProgress,
      [podcastId]: newProgress
    }))
  }
  const updatePodcastComProgress = (podcastId, newProgress) => {
    setPodcastComProgress((prevProgress) => ({
      ...prevProgress,
      [podcastId]: newProgress
    }))
  }
  const updateShotProgress = (shotId, newProgress) => {
    setShotProgress((prevProgress) => ({
      ...prevProgress,
      [shotId]: newProgress
    }))
  }
  const updateShotComProgress = (shotId, newProgress) => {
    setShotComProgress((prevProgress) => ({
      ...prevProgress,
      [shotId]: newProgress
    }))
  }

  const values = useMemo(
    () => ({
      videoProgress,
      updateVideoProgress,
      videoComProgress,
      updateVideoComProgress,
      podcastProgress,
      updatePodcastProgress,
      podcastComProgress,
      updatePodcastComProgress,
      shotProgress,
      updateShotProgress,
      shotComProgress,
      updateShotComProgress
    }),
    [
      videoProgress,
      podcastProgress,
      shotProgress,
      videoComProgress,
      podcastComProgress,
      shotComProgress
    ]
  )

  return (
    <VideoProgressContext.Provider value={values}>
      {children}
    </VideoProgressContext.Provider>
  )
}

// Custom hook to use the VideoProgressContext
export const useVideoProgress = () => {
  return useContext(VideoProgressContext)
}
