import React, { useEffect } from 'react'
import './CreatorProfile.css'
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import Feeds from '../Feeds/Feeds'
import VideoComponent from '../RecentVideos/VideoComponent'
import ShotsComponent from '../RecentShots/ShotsComponent'
import PodcastComponent from '../RecentPodcast/PodcastComponent'
// import { getAddOns } from '../../services/Service'
// import { dataDecrypt } from '../../services/http-services'
import HomeIco from '../../images/HomeIco.svg'
import FeedIco from '../../images/FeedIco.svg'
import VideoIco from '../../images/VideoIco.svg'
import ShotsIco from '../../images/ShotsIco.svg'
import PodcastIco from '../../images/PodcastIco.svg'
import ProfileHomePage from './profileHomePage'
// import { useParams } from "react-router-dom";

const ProfileBuilder = () => {
  const { state } = useLocation()
  // const [, setAddOnsData] = useState([])
  // const params = useParams();
  console.log(window.location.href.search('profile-builder') !== -1)
  useEffect(() => {
    window.scroll(0, 0)
    // getAddOns().then((res) => {
    //   if (res.data.success) {
    //     setAddOnsData(dataDecrypt(res.data.data))
    //   }
    // })
  }, [])

  const TabsComponents = () => {
    return (
      <Tabs defaultIndex={state?.defaultIndex ? state?.defaultIndex : 0}>
        <TabList>
          <Tab>
            <img className="component_icon" src={HomeIco} alt="home icon" />
            Home
          </Tab>
          <Tab>
            <img className="component_icon" src={FeedIco} alt="feed icon" />
            Feed
          </Tab>
          <Tab>
            <img className="component_icon" src={VideoIco} alt="video icon" />
            Videos
          </Tab>
          <Tab>
            <img className="component_icon" src={ShotsIco} alt="shot icon" />
            Shots
          </Tab>
          <Tab>
            <img className="component_icon" src={PodcastIco} alt="podcast icon" />
            Podcasts
          </Tab>
        </TabList>
        <TabPanel>
          <ProfileHomePage />
        </TabPanel>
        <TabPanel>
          <div>
            <Feeds />
          </div>
        </TabPanel>
        <TabPanel>
          <div>
            <VideoComponent />
          </div>
        </TabPanel>
        <TabPanel>
          <div>
            <ShotsComponent />
          </div>
        </TabPanel>
        <TabPanel>
          <div>
            <PodcastComponent />
          </div>
        </TabPanel>
      </Tabs>
    )
  }

  const navigate = useNavigate()
  const { id } = useParams()
  console.log('params', id)
  useEffect(() => {
    const handleBeforeUnload = () => {
      navigate(`/profile-builder/${id}`)
    }

    window.addEventListener('beforeunload', handleBeforeUnload)

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload)
    }
  }, [])

  return (
    <div className="creator-profile-main creator-profile-create">
      {/* {window.location.href.search('profile-builder') !== -1 && (
      <ProfileHeadCreator />
        )} */}
      {/* <ProfileHeadCreator /> */}
      {/* {window.location.href.search('store-builder') !== -1 && (
      <ProfileHeadStore />
        )} */}
      <div className="details-tab-view pt-0 mt-15">
        <div className="tabs-for-details-view">
          <TabsComponents />
        </div>
      </div>
    </div>
  )
}
export default ProfileBuilder
