/* eslint-disable no-nested-ternary */
/* eslint-disable no-undef */
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react'
import { toast } from 'react-toastify'

import Skeleton from 'react-loading-skeleton'
import Plus from '../../images/plus-rounded.svg'
import RatingStar from '../../images/rating-start.svg'
import './AddProduct.css'
import Dropdown from '../Dropdown/Dropdown'
import Remove from '../../images/remove.svg'
import plusimg from '../../images/plusimg.svg'
import upload from '../../images/upload.svg'
import {
  AddProductLibrary,
  addGeneralProduct,
  deleteProductLibrary,
  editProductLibrary,
  getCategory,
  getGeneralProduct,
  imageUploadStore,
  productDetails,
  removeGeneralProduct,
  showProductLibrary,
  subCategoryAll,
  urlImageProductPathStore
} from '../../services/Service'
import { dataDecrypt } from '../../services/http-services'
import Loader from '../Loader/Loader'
import CloseIco from '../../images/CloseIco.svg'
import AddIco from '../../images/AddIco.svg'
import { debounce, lockScroll, unlockScroll } from '../../helpers/Utility'
import CommonImageUpload from '../ImageCroper/StoreImageCroper'
import CommonButton from '../ButtonComponent/ButtonCommon'
import CommonInput from '../InputComponet/CommonInput'

const AddProductFilter = () => {
  const [ShowProductsModel, setShowProductsModel] = useState(false)
  const [ShowConfirmModel, setShowConfirmModel] = useState(false)
  const [ShowLibraryConfirmModel, setShowLibraryConfirmModel] = useState(false)
  const [selectedImages, setSelectedImages] = useState([])
  const [ShowProduct, setShowProduct] = useState(false)
  const [category, setCategory] = useState([])
  const [EditProduct, setEditProduct] = useState(false)
  const [subCategory, setSubCategory] = useState([])
  const [product, setProduct] = useState([])
  const [loading, setLoading] = useState(false)
  const [updatingIndex, setUpdatingIndex] = useState(null)
  const [proDeleteIndex, setProDeleteIndex] = useState(null)
  const [isFormValid, setIsFormValid] = useState(false)
  const [isEdited, setIsEdited] = useState(false)
  const [draggedIndex, setDraggedIndex] = useState(null)
  const [inputFields, setInputFields] = useState({
    name: '',
    regular_price: '',
    discount_price: 0,
    category_id: '',
    sub_category_id: '',
    product_link: '',
    description: '',
    main_image: '',
    image_1: '',
    image_2: '',
    image_3: '',
    search: '',
    id: ''
  })
  const [generalProduct, setGeneralProduct] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [isLibraryLoading, setIsLibraryLoading] = useState(false)
  const [isImageLoading, setIsImageLoading] = useState({
    index: null,
    loading: false
  })

  const AddProducts = () => {
    setShowProductsModel(true)
    ProductLibrary()
  }

  const AddLibrary = () => {
    setShowProduct(true)
    ProductLibrary()
  }

  const ResetValue = () => {
    setSelectedImages([])
    setInputFields({
      name: '',
      regular_price: '',
      discount_price: 0,
      category_id: '',
      sub_category_id: '',
      product_link: '',
      description: '',
      main_image: '',
      image_1: '',
      image_2: '',
      image_3: '',
      search: '',
      id: ''
    })
    setSubCategory([])
  }

  const HandleCancel = () => {
    setShowProductsModel(false)
  }

  const ProductAddCancel = () => {
    setShowProduct(false)
    ResetValue()
  }

  const HandleCancelConfirm = () => {
    setShowConfirmModel(false)
    setShowLibraryConfirmModel(false)
  }
  const componentRef = useRef()

  const handleSubmit = (event) => {
    event.preventDefault()
    if (isFormValid) {
      ProductDataSubmit()
      ProductLibrary()
      ResetValue()
    }
  }

  const handleEditSubmit = (event) => {
    event.preventDefault()
  }

  const ShowConfirm = () => {
    setShowConfirmModel(true)
  }

  const deleteLibraryConfirm = () => {
    setShowLibraryConfirmModel(true)
  }

  // const handleImageChange = async (e, indexToReplace) => {
  //   const { files } = e.target
  //   if (files.length > 0) {
  //     const updatedImages = selectedImages.slice(0)

  //     if (
  //       indexToReplace === undefined &&
  //       updatedImages.length + files.length > 4
  //     ) {
  //       toast.error('Please select a maximum of 4 images.')
  //       e.target.value = null
  //       return
  //     }
  //     setIsEdited(true)
  //     const url = await finishSubmit(files[0])
  //     if (indexToReplace || indexToReplace === 0) {
  //       updatedImages.splice(indexToReplace, 1, url)
  //     } else {
  //       updatedImages.push(url)
  //     }
  //     setSelectedImages(updatedImages)
  //   }
  //   return false
  // }

  const handleImageChange = async (file, indexToReplace) => {
    if (!file) return

    const updatedImages = selectedImages.slice(0)

    if (indexToReplace === undefined && updatedImages.length + 1 > 4) {
      toast.error('Please select a maximum of 4 images.')
      return
    }

    setIsEdited(true)

    const url = await finishSubmit(file)

    if (indexToReplace !== undefined) {
      updatedImages.splice(indexToReplace, 1, url)
    } else {
      updatedImages.push(url)
    }
    setSelectedImages(updatedImages)
  }

  const removeImage = (index) => {
    const updatedImages = [...selectedImages]
    updatedImages.splice(index, 1)
    setSelectedImages(updatedImages)
    setIsEdited(true)
  }

  // const replaceImage = (index) => {
  //   const input = document.createElement('input')
  //   input.type = 'file'

  //   const handleChange = (e) => {
  //     console.log('handleChangeCalled')
  //     handleImageChange(e, index)
  //     input.removeEventListener('change', handleChange)
  //   }

  //   if (input) {
  //     input.value = ''
  //     input.removeEventListener('change', handleChange)
  //     input.click()
  //     input.addEventListener('change', handleChange, { once: true })
  //     input.remove()
  //     setIsEdited(true)
  //   }
  // }

  const handleChange = (e) => {
    setInputFields({ ...inputFields, [e.target.name]: e.target.value })
  }
  const handleEditChange = (e) => {
    setInputFields({ ...inputFields, [e.target.name]: e.target.value })
    setIsEdited(true)
  }

  const PlatformPreview = () => {
    setShowProduct(false)
    ResetValue()
  }
  const EditPlatformBack = () => {
    setEditProduct(false)
    ResetValue()
  }
  // const EditPlatformPreview = () => {
  //   setEditProduct(false);
  //   ResetValue();
  // };

  // const ProductPreview = () => {
  //   setShowProduct(false);
  // };

  const EditSubmit = (data) => {
    EditProductLib(data)
    setEditProduct(false)
    ResetValue()
    setIsEdited(false)
  }

  const GeneralSubmit = (data) => {
    AddGeneral(data)
    getGeneral(data)
    setShowProductsModel(false)
  }

  const finishSubmit = (file) => {
    const imageType = 'product'
    return new Promise((resolve) => {
      imageUploadStore(file, imageType)
        .then((res) => {
          if (res.data.success) {
            resolve(dataDecrypt(res.data.data))
          } else {
            toast.error(res?.data?.message)
            resolve(null)
          }
        })
        .catch(() => {
          resolve(null)
        })
    })
  }

  const ProductDataSubmit = () => {
    inputFields.main_image = selectedImages[0] ?? ''

    inputFields.image_1 = selectedImages[1] ?? ''

    inputFields.image_2 = selectedImages[2] ?? ''

    inputFields.image_3 = selectedImages[3] ?? ''
    AddProductLibrary(inputFields).then((res) => {
      if (res.data.success) {
        setShowProduct(false)
        ProductLibrary()
        setIsEdited(false)
        toast.success(res?.data?.message)
      } else {
        toast.error(res?.data?.message)
      }
    })
  }
  const MainCategory = () => {
    getCategory({}).then((res) => {
      if (res.data.success) {
        setCategory(dataDecrypt(res.data.data))
      }
    })
  }
  const SubCategory = (data) => {
    subCategoryAll(data).then((res) => {
      if (res.data.success) {
        setSubCategory(dataDecrypt(res.data.data)[0]?.sub_category_data)
      }
    })
  }
  const ProductLibrary = (search) => {
    setIsLibraryLoading(true)
    const data = {
      search
    }
    showProductLibrary(data).then((res) => {
      if (res.data.success) {
        setProduct(dataDecrypt(res.data.data))
        setIsLibraryLoading(false)
      }
    })
  }
  const ProductLibFilterData = (category_id) => {
    setIsLibraryLoading(true)
    const data = {
      category_id
    }
    showProductLibrary(data).then((res) => {
      if (res.data.success) {
        setProduct(dataDecrypt(res.data.data))
        setIsLibraryLoading(false)
      }
    })
  }

  const handleSearch2 = useCallback(
    debounce((inputVal) => ProductLibrary(inputVal), 500),
    []
  )

  const deleteProductLib = (data) => {
    const platFormData = {
      product_id: data?.id
    }
    setLoading(true)
    deleteProductLibrary(platFormData).then((res) => {
      if (res.data.success) {
        ProductLibrary()
        getGeneral()
        setLoading(false)
        setShowLibraryConfirmModel(false)
        toast.success(res?.data?.message)
      } else {
        toast.error(res?.data?.message)
      }
    })
  }

  const EditLibrary = (data) => {
    setEditProduct(true)
    setSelectedImages([data.image])
    oneProductDetails(data)
  }

  const EditProductLib = (data) => {
    const platFormData = {
      product_libraries_id: data?.id,
      name: data.name,
      regular_price: data?.regular_price,
      discount_price: data?.discount_price,
      category_id: data?.category_id,
      sub_category_id: data?.sub_category_id,
      product_link: data?.product_link,
      description: data?.description,
      main_image: data?.image,
      image_1: data?.image_1,
      image_2: data?.image_2,
      image_3: data?.image_3
    }
    if (!data?.image) {
      platFormData.main_image = selectedImages[0] ?? ''
    }
    if (!data?.image_1) {
      platFormData.image_1 = selectedImages[1] ?? ''
    }
    if (!data?.image_2) {
      platFormData.image_2 = selectedImages[2] ?? ''
    }
    if (!data?.image_3) {
      platFormData.image_3 = selectedImages[3] ?? ''
    }
    editProductLibrary(platFormData).then((res) => {
      if (res.data.success) {
        ProductLibrary()
        getGeneral()
        toast.success(res?.data?.message)
      } else {
        toast.error(res?.data?.message)
      }
    })
  }

  const AddGeneral = (data) => {
    const dataId = localStorage.getItem('app_id')
    const parsedData = JSON.parse(dataId)
    const platFormData = {
      app_id: parsedData.id,
      store_general_products: [data.id]
    }
    setLoading(true)
    addGeneralProduct(platFormData).then((res) => {
      if (res.data.success) {
        getGeneral()
        setLoading(false)
        toast.success(res?.data?.message)
      } else {
        toast.error(res?.data?.message)
      }
    })
  }

  const getGeneral = async (search) => {
    setIsLoading(true)
    const dataId = localStorage.getItem('app_id')
    const parsedData = JSON.parse(dataId)
    const platFormData = {
      app_id: parsedData.id,
      search
    }
    setLoading(true)
    await getGeneralProduct(platFormData).then((res) => {
      if (res.data.success) {
        setLoading(false)
        setGeneralProduct(dataDecrypt(res.data.data))
        setIsLoading(false)
      }
    })
  }

  const getFilterData = async (category_id) => {
    setIsLoading(true)
    const dataId = localStorage.getItem('app_id')
    const parsedData = JSON.parse(dataId)
    const platFormData = {
      app_id: parsedData.id,
      category_id
    }
    await getGeneralProduct(platFormData).then((res) => {
      if (res.data.success) {
        setGeneralProduct(dataDecrypt(res.data.data))
        setIsLoading(false)
      }
    })
  }

  const handleSearch = useCallback(
    debounce((inputVal) => getGeneral(inputVal), 500),
    []
  )

  const deleteGeneral = (data) => {
    const dataId = localStorage.getItem('app_id')
    const parsedData = JSON.parse(dataId)
    const platFormData = {
      app_id: parsedData.id,
      delete_general_products: [data.id]
    }
    setLoading(true)
    removeGeneralProduct(platFormData).then((res) => {
      if (res.data.success) {
        setLoading(false)
        getGeneral()
        setShowConfirmModel(false)
        toast.success(res?.data?.message)
      } else {
        toast.error(res?.data?.message)
      }
    })
  }

  const oneProductDetails = (data) => {
    const dataId = localStorage.getItem('app_id')
    const parsedData = JSON.parse(dataId)
    const platFormData = {
      app_id: parsedData.id,
      plateform: 'web',
      product_id: data.id
    }
    productDetails(platFormData).then((res) => {
      if (res.data.success) {
        const productDetails = dataDecrypt(res.data.data)
        SubCategory({ category_id: productDetails.category_id })
        setInputFields(productDetails)
        const Urls = productDetails?.images?.map((i) => i.image)
        setSelectedImages(Urls)
      } else {
        toast.error(res?.data?.message)
      }
    })
  }

  const validateForm = () => {
    const {
      name,
      regular_price,
      category_id,
      sub_category_id,
      product_link,
      description
    } = inputFields

    const isValid =
      name &&
      regular_price &&
      category_id &&
      sub_category_id &&
      product_link &&
      description &&
      selectedImages.length > 0

    setIsFormValid(isValid)
  }

  const handleDragStart = (index) => {
    setDraggedIndex(index)
  }

  const handleDrop = (index) => {
    if (index !== draggedIndex) {
      setSelectedImages((state) => {
        const copy = [...state]
        const b = copy[draggedIndex]
        copy[draggedIndex] = copy[index]
        copy[index] = b

        return copy
      })
      setIsEdited(true)
      setDraggedIndex(null)
    }
  }

  const handleLoad = (index) => {
    setIsImageLoading({
      index,
      loading: true
    })
    setTimeout(() => {
      setIsImageLoading({
        index: null,
        loading: false
      })
    }, 500)
  }

  const renderImg = useMemo(() => {
    return (
      <>
        {selectedImages.map((image, index) => (
          <div
            className={`image position-relative ${
              index === 0 ? 'first-image' : ''
            }`}
            key={index}
            draggable
            onDragStart={() => handleDragStart(index)}
            onDragOver={(event) => event.preventDefault()}
            onDrop={(event) => {
              event.preventDefault()
              handleDrop(index)
            }}
          >
            {isImageLoading.index === index && isImageLoading.loading && (
              <Loader />
            )}
            <img
              className={`image position-relative ${
                index === 0 ? 'first-image' : ''
              }`}
              src={
                typeof image === 'string'
                  ? urlImageProductPathStore + image
                  : URL.createObjectURL(image)
              }
              alt={`image-${index}`}
              style={{ width: '100%', height: '100%', objectFit: 'cover' }}
              onLoad={() => handleLoad(index)}
            />
            <button type="button" onClick={() => removeImage(index)}>
              <img src={Remove} alt="remove" />
            </button>
            <button type="button" className="plus-img">
              <CommonImageUpload
                handleImageChange={(file) => {
                  handleImageChange(file, index)
                }}
                selectedImg=""
                defaultImg={plusimg}
                className="upload_label"
              />
            </button>
          </div>
        ))}
      </>
    )
  }, [selectedImages, handleDragStart])

  const getCategoryName = (categoryId) => {
    const categoryObj = category?.find((cat) => cat?.id === categoryId)
    return categoryObj ? categoryObj?.name : ''
  }

  const getSubCategoryNameById = (subCategoryId) => {
    const subCategoryItem = subCategory?.find(
      (sc) => sc?.sub_category_id === subCategoryId
    )
    return subCategoryItem
      ? subCategoryItem?.sub_category_name
      : 'Select subcategory'
  }

  const [localStorageValue, setLocalStorageValue] = useState(() => {
    return JSON.parse(localStorage.getItem('app_id')) || null
  })

  useEffect(() => {
    validateForm()
  }, [inputFields, selectedImages])

  useEffect(() => {
    MainCategory()
    ProductLibrary()
  }, [])

  useEffect(() => {
    const handleStorageChange = () => {
      setLocalStorageValue(JSON.parse(localStorage.getItem('app_id')) || null)
    }

    window.addEventListener('storage', handleStorageChange)

    return () => window.removeEventListener('storage', handleStorageChange)
  }, [])

  useEffect(() => {
    if (localStorageValue !== null) {
      getGeneral()
    }
  }, [localStorageValue])

  useEffect(() => {
    if (
      ShowConfirmModel ||
      EditProduct ||
      ShowProductsModel ||
      ShowProduct ||
      ShowLibraryConfirmModel
    ) {
      lockScroll()
    } else {
      unlockScroll()
    }

    return () => {
      unlockScroll()
    }
  }, [
    ShowConfirmModel,
    EditProduct,
    ShowProductsModel,
    ShowProduct,
    ShowLibraryConfirmModel
  ])

  return (
    <div className="content-manager-awards-wrapper common-card-body mt-40 add-profile-video">
      <div className="discussions-wrapper-header common-contnt-wrapper pb-3">
        <h2>All Products</h2>
        <div className="items-filter">
          <div className="position-relative input-position">
            <CommonInput
              placeholder="Search Products"
              onChange={(e) => {
                handleSearch(e.target.value)
              }}
            />
          </div>
        </div>
      </div>
      <div className="drop-row mb-30">
        <div className="drop-sub-row">
          <Dropdown
            title="Categories"
            onChanged={(d) => {
              getFilterData(d.id)
              setInputFields({
                ...inputFields,
                category_id: d.id
              })
            }}
            Data={category?.map((d) => ({
              title: d.name,
              id: d.id
            }))}
            customClass="store_dropdown"
          />
          <Dropdown
            title="Ratings"
            Data={[
              { title: 'Scaloo' },
              { title: 'Spacure' },
              { title: 'PETZINO' },
              { title: 'A-Z FIT' },
              { title: 'TESTIEE' }
            ]}
            customClass="disabledDropdown"
          />
          <Dropdown
            title="Duration"
            Data={[
              { title: 'Scaloo' },
              { title: 'Spacure' },
              { title: 'PETZINO' },
              { title: 'A-Z FIT' },
              { title: 'TESTIEE' }
            ]}
            customClass="disabledDropdown"
          />
        </div>
        <div className="add-video-rows">
          <CommonButton
            label="Add New Products"
            imgSrc={AddIco}
            onClick={AddProducts}
            isPrimary
            className="add_new_content"
          />
        </div>
      </div>
      <div className="awards-row">
        {generalProduct?.length === 0 && !isLoading ? (
          <div className="awards-item">
            <div className="add-profile-video-cover add-product-video-cover">
              <button type="button" onClick={AddProducts}>
                <img src={Plus} alt="add library" />
              </button>
            </div>
            <div className="product-footer">
              <p className="add-value" />
              <span className="add-value" />
            </div>
          </div>
        ) : isLoading ? (
          <div className="awards-item">
            <div className="add-profile-video-cover add-product-video-cover">
              <button type="button">
                <img src={Plus} alt="add library" />
              </button>
            </div>
            <div className="product-footer">
              <p className="add-value">
                <Skeleton className="add-value" width={202} height={35} />
              </p>
              <span className="add-value">
                <Skeleton className="add-value" width={67} height={35} />
              </span>
            </div>
          </div>
        ) : (
          generalProduct?.map((data, i) => {
            return (
              <div key={i} className="product-grid-item position-relative">
                <div className="image-buttons z-1">
                  <label>
                    <button
                      type="button"
                      className="d-none"
                      onClick={() => EditLibrary(data)}
                    />
                  </label>
                  <button type="button" className="p-0" onClick={ShowConfirm} />
                  {ShowConfirmModel && (
                    <div className="add-item-model">
                      <div
                        className="add-item-contents h-auto w-auto"
                        ref={componentRef}
                      >
                        <div className="item-details-input pt-0">
                          <h3 className="mb-0 text-center">
                            Do you want to delete this?
                          </h3>
                          <div className="button-row justify-content-center">
                            <button
                              type="button"
                              className="secondary-btn-modelConfirm"
                              onClick={HandleCancelConfirm}
                            >
                              <span>Cancel</span>
                            </button>
                            <button
                              type="button"
                              className="primary-btn-modelConfirm"
                              onClick={() => {
                                setUpdatingIndex(i)
                                deleteGeneral(data)
                              }}
                            >
                              Confirm
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                {EditProduct && (
                  <form onSubmit={handleEditSubmit}>
                    <div className="add-item-model add-product-content">
                      <div className="add-item-content add-item-content-store">
                        <div className="product-add-image">
                          <div className="product-label store_header_sticky">
                            <h3 className="edit-new-label">Edit Product</h3>
                            <button
                              type="button"
                              className="border-0 bg-none p-0"
                              onClick={EditPlatformBack}
                            >
                              <span>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="22"
                                  height="22"
                                  viewBox="0 0 22 22"
                                  fill="none"
                                >
                                  <path
                                    d="M21 1L1 20.9147M21 20.9147L1 1L21 20.9147Z"
                                    stroke="black"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                  />
                                </svg>
                              </span>
                            </button>
                          </div>

                          <div className="store_scroll_bar_add">
                            <div className="input-image-show align-items-end">
                              <div className="input-img-label">
                                <div className="upload_crop">
                                  <CommonImageUpload
                                    handleImageChange={handleImageChange}
                                    buttonText="Upload Image"
                                    selectedImg=""
                                    defaultImg={upload}
                                    className="upload_label"
                                  />
                                </div>
                              </div>
                              {renderImg}
                            </div>
                            <div className="vertical-divider mt-4" />
                            <form className="item-details-input pt-3">
                              <div className="name-field">
                                <div>
                                  <label>Product Name</label>
                                  <CommonInput
                                    name="name"
                                    placeholder="Name"
                                    value={inputFields.name}
                                    onChange={handleEditChange}
                                    style={{ textTransform: 'capitalize' }}
                                  />
                                  <CommonInput
                                    name="id"
                                    type="hidden"
                                    value={inputFields.id}
                                  />
                                </div>
                              </div>
                              <div className="input-grid">
                                <div>
                                  <label>Regular Price</label>
                                  <CommonInput
                                    name="regular_price"
                                    type="number"
                                    placeholder="Price"
                                    onWheel={(e) => e.target.blur()}
                                    value={inputFields.regular_price}
                                    onChange={handleEditChange}
                                  />
                                </div>
                                <div>
                                  <label>Discount Price</label>
                                  <CommonInput
                                    name="discount_price"
                                    type="number"
                                    placeholder="Price"
                                    onWheel={(e) => e.target.blur()}
                                    value={inputFields.discount_price}
                                    onChange={handleEditChange}
                                  />
                                </div>
                              </div>
                              <div className="input-grid">
                                <div>
                                  <label>Category</label>
                                  <Dropdown
                                    title={getCategoryName(
                                      inputFields.category_id
                                    )}
                                    onChanged={(d) => {
                                      SubCategory({
                                        category_id: d.id
                                      })
                                      setInputFields({
                                        ...inputFields,
                                        category_id: d.id
                                      })
                                    }}
                                    Data={category?.map((d) => ({
                                      title: d.name,
                                      id: d.id
                                    }))}
                                    name="category_id"
                                    customClass="store_add_dropdown"
                                  />
                                </div>
                                <div>
                                  <label>Subcategory</label>
                                  <Dropdown
                                    title={getSubCategoryNameById(
                                      inputFields.sub_category_id
                                    )}
                                    onChanged={(d) => setInputFields({
                                        ...inputFields,
                                        sub_category_id: d.id
                                      })}
                                    Data={subCategory?.map((d) => ({
                                      title: d.sub_category_name,
                                      id: d.sub_category_id
                                    }))}
                                    name="sub_category_id"
                                    value={inputFields.sub_category_id}
                                    customClass="store_add_dropdown"
                                  />
                                </div>
                              </div>
                              <div className="input-grid">
                                <div>
                                  <label>Product-Link</label>
                                  <CommonInput
                                    name="product_link"
                                    placeholder="Product link"
                                    value={inputFields.product_link}
                                    onChange={handleEditChange}
                                  />
                                </div>
                              </div>
                              <div>
                                <label>Decription</label>
                                <CommonInput
                                  name="description"
                                  placeholder="Type here"
                                  value={inputFields.description}
                                  onChange={handleEditChange}
                                  className="description-input"
                                  isTextArea
                                />
                              </div>
                            </form>
                          </div>
                        </div>
                        <div className="button-row d-flex align-items-center justify-content-between store_sticky_btn pb-0">
                          <div>
                            <CommonButton
                              label="Back"
                              type="button"
                              onClick={EditPlatformBack}
                              isPrimary={false}
                              context="Profile"
                              className="me-4"
                            />
                            <CommonButton
                              label="Preview"
                              type="button"
                              isPrimary={false}
                              context="Profile"
                              className="common-button"
                              disabled
                            />
                          </div>
                          <CommonButton
                            label="Publish"
                            type="button"
                            onClick={() => EditSubmit(inputFields)}
                            isPrimary
                            disabled={!isEdited}
                            context="Profile"
                          />
                        </div>
                      </div>
                    </div>
                  </form>
                )}

                {loading && updatingIndex === i && <Loader />}
                <div
                  className="product-item-cover"
                  style={{
                    backgroundImage: `url(${urlImageProductPathStore + data.image})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center'
                  }}
                >
                  <div className="rating">
                    <img src={RatingStar} alt="star" />
                    <span>4.9</span>
                  </div>
                </div>
                <div className="product-item-details d-flex  justify-content-between">
                  <span>{data.name}</span>
                  {/* <span>{data.description}</span> */}
                  <div>
                    {data.discount_price > 0 ? (
                      <>
                        <h4>{data.discount_price}</h4>
                        <h4 className="actual-price">{data.regular_price}</h4>
                      </>
                    ) : (
                      <h4>{data.regular_price}</h4>
                    )}
                  </div>
                </div>
              </div>
            )
          })
        )}
        {/* <div className="awards-item">
          <div className="add-profile-video-cover add-product-video-cover">
            <button type="button" onClick={AddProducts}>
              <img src={Plus} alt="add product" />
            </button>
          </div>
          <div className="product-footer">
            <p className="add-value" />
            <span className="add-value" />
          </div>
        </div> */}
      </div>
      {ShowProductsModel && (
        <div className="add-item-model">
          <div
            className="add-item-content add-item-content-store d-flex flex-column justify-content-between store_right_padding"
            ref={componentRef}
          >
            <div className="">
              {/* <div className="text-end">
                <button type="button" className="border-0 bg-none" onClick={HandleCancel}>
                  <span>
                    <img src={CloseIco} alt="close icon" />
                  </span>
                </button>
              </div> */}
              <div className="store_header_sticky">
                <div className="discussions-wrapper-header common-contnt-wrapper pb-3">
                  <h2>Product Library</h2>
                  <div className="items-filter">
                    <div className="position-relative input-position">
                      <CommonInput
                        placeholder="Search Products"
                        onChange={(e) => {
                        handleSearch2(e.target.value)
                      }}
                      />
                    </div>
                  </div>
                </div>
                <div className="drop-row mb-2">
                  <div className="drop-sub-row">
                    <Dropdown
                      title="Categories"
                      onChanged={(d) => {
                      ProductLibFilterData(d.id)
                      setInputFields({
                        ...inputFields,
                        category_id: d.id
                      })
                    }}
                      Data={category?.map((d) => ({
                      title: d.name,
                      id: d.id
                    }))}
                      customClass="store_dropdown"
                    />
                    <Dropdown
                      title="Ratings"
                      Data={[
                      { title: 'Scaloo' },
                      { title: 'Spacure' },
                      { title: 'PETZINO' },
                      { title: 'A-Z FIT' },
                      { title: 'TESTIEE' }
                    ]}
                      customClass="disabledDropdown"
                    />
                    <Dropdown
                      title="Duration"
                      Data={[
                      { title: 'Scaloo' },
                      { title: 'Spacure' },
                      { title: 'PETZINO' },
                      { title: 'A-Z FIT' },
                      { title: 'TESTIEE' }
                    ]}
                      customClass="disabledDropdown"
                    />
                  </div>
                  <div className="add-video-rows">
                    <CommonButton
                      label="Add New Products"
                      imgSrc={AddIco}
                      onClick={AddLibrary}
                      isPrimary
                      className="add_new_content add_new_store"
                    />
                  </div>
                </div>
              </div>
              <div className="awards-row store-container store_scroll_bar">
                {/* <div className="awards-item">
                  <div className="add-profile-video-cover add-product-video-cover">
                    <button type="button" onClick={AddLibrary}>
                      <img src={Plus} alt="add library" />
                    </button>
                  </div>
                  <div className="product-footer">
                    <p className="add-value" />
                    <span className="add-value" />
                  </div>
                </div> */}
                {ShowProduct && (
                  <form onSubmit={handleSubmit}>
                    <div className="add-item-model add-product-content">
                      <div className="add-item-content add-item-content-store store_right_padding">
                        <div className="product-add-image">
                          <div className="product-label store_header_sticky">
                            <h3 className="edit-new-label">Add New Product</h3>
                            <button
                              type="button"
                              className="border-0 bg-none p-0"
                              onClick={ProductAddCancel}
                            >
                              <span>
                                <img src={CloseIco} alt="close icon" />
                              </span>
                            </button>
                          </div>
                          <div className="store_scroll_bar_add">
                            <div className="input-image-show align-items-end">
                              <div className="input-img-label">
                                <div className="upload_crop">
                                  <CommonImageUpload
                                    handleImageChange={handleImageChange}
                                    buttonText="Upload Image"
                                    selectedImg=""
                                    defaultImg={upload}
                                    className="upload_label"
                                  />
                                </div>
                              </div>
                              {renderImg}
                            </div>
                            <div className="vertical-divider mt-4" />
                            <form className="item-details-input pt-3">
                              <div className="name-field">
                                <div>
                                  <label>Product Name</label>
                                  <CommonInput
                                    name="name"
                                    placeholder="Name"
                                    onChange={handleChange}
                                    style={{ textTransform: 'capitalize' }}
                                  />
                                </div>
                              </div>
                              <div className="input-grid">
                                <div>
                                  <label>Regular Price</label>
                                  <CommonInput
                                    name="regular_price"
                                    type="number"
                                    placeholder="Price"
                                    onChange={handleChange}
                                    onWheel={(e) => e.target.blur()}
                                  />
                                </div>
                                <div>
                                  <label>Discount Price</label>
                                  <CommonInput
                                    name="discount_price"
                                    type="number"
                                    placeholder="Discount price"
                                    onChange={handleChange}
                                    onWheel={(e) => e.target.blur()}
                                  />
                                </div>
                              </div>
                              <div className="input-grid">
                                <div>
                                  <label>Category</label>
                                  <Dropdown
                                    title="Category"
                                    onChanged={(d) => {
                                      SubCategory({ category_id: d.id })
                                      setInputFields({
                                        ...inputFields,
                                        category_id: d.id
                                      })
                                    }}
                                    Data={category?.map((d) => ({
                                      title: d.name,
                                      id: d.id
                                    }))}
                                    name="category_id"
                                    customClass="store_add_dropdown"
                                  />
                                </div>
                                <div>
                                  <label>Subcategory</label>
                                  <Dropdown
                                    title="Sub category"
                                    onChanged={(d) => setInputFields({
                                        ...inputFields,
                                        sub_category_id: d.id
                                      })}
                                    Data={subCategory?.map((d) => ({
                                      title: d.sub_category_name,
                                      id: d.sub_category_id
                                    }))}
                                    name="sub_category_id"
                                    customClass="store_add_dropdown"
                                  />
                                </div>
                              </div>
                              <div className="input-grid">
                                <div>
                                  <label>Product-Link</label>
                                  <CommonInput
                                    name="product_link"
                                    placeholder="Product link"
                                    onChange={handleChange}
                                  />
                                </div>
                              </div>
                              <div>
                                <label>Decription</label>
                                <CommonInput
                                  name="description"
                                  placeholder="Type here"
                                  onChange={handleChange}
                                  className="description-input"
                                  isTextArea
                                />
                              </div>
                            </form>
                          </div>
                        </div>
                        <div className="button-row d-flex align-items-center justify-content-between store_sticky_btn pb-0">
                          <div>
                            <CommonButton
                              label="Back"
                              type="button"
                              onClick={PlatformPreview}
                              isPrimary={false}
                              context="Profile"
                              className="me-4"
                            />
                            <CommonButton
                              label="Preview"
                              type="button"
                              isPrimary={false}
                              context="Profile"
                              className="common-button"
                              disabled
                            />
                          </div>
                          <CommonButton
                            label="Add Product"
                            type="submit"
                            isPrimary
                            disabled={!isFormValid}
                            context="Profile"
                          />
                        </div>
                      </div>
                    </div>
                  </form>
                )}
                {product?.length === 0 && !isLibraryLoading ? (
                  <div className="awards-item">
                    <div className="add-profile-video-cover add-product-video-cover">
                      <button type="button" onClick={AddLibrary}>
                        <img src={Plus} alt="add library" />
                      </button>
                    </div>
                    <div className="product-footer">
                      <p className="add-value" />
                      <span className="add-value" />
                    </div>
                  </div>
                ) : isLibraryLoading ? (
                  <div className="awards-item">
                    <div className="add-profile-video-cover add-product-video-cover">
                      <button type="button">
                        <img src={Plus} alt="add library" />
                      </button>
                    </div>
                    <div className="product-footer">
                      <p className="add-value">
                        <Skeleton
                          className="add-value"
                          width={202}
                          height={35}
                        />
                      </p>
                      <span className="add-value">
                        <Skeleton
                          className="add-value"
                          width={67}
                          height={35}
                        />
                      </span>
                    </div>
                  </div>
                ) : (
                  product?.map((data, i) => (
                    <div
                      key={i}
                      className="product-grid-item position-relative"
                    >
                      <div className="image-buttons z-1">
                        <label>
                          <button
                            type="button"
                            className="d-none"
                            onClick={() => EditLibrary(data)}
                          />
                        </label>
                        <button
                          type="button"
                          className="p-0"
                          onClick={deleteLibraryConfirm}
                        />
                        {ShowLibraryConfirmModel && (
                          <div className="add-item-model">
                            <div className="add-item-contents h-auto w-auto">
                              <div className="item-details-input pt-0">
                                <h3 className="mb-0 text-center">
                                  Do you want to delete this?
                                </h3>
                                <div className="button-row justify-content-center">
                                  <button
                                    type="button"
                                    className="secondary-btn-modelConfirm"
                                    onClick={HandleCancelConfirm}
                                  >
                                    <span>Cancel</span>
                                  </button>
                                  <button
                                    type="button"
                                    className="primary-btn-modelConfirm"
                                    onClick={() => {
                                      setProDeleteIndex(i)
                                      deleteProductLib(data)
                                    }}
                                  >
                                    Confirm
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                      {loading && proDeleteIndex === i && <Loader />}
                      {EditProduct && (
                        <form onSubmit={handleEditSubmit}>
                          <div className="add-item-model add-product-content">
                            <div className="add-item-content add-item-content-store">
                              <div className="product-add-image">
                                <div className="product-label store_header_sticky">
                                  <h3 className="edit-new-label">
                                    Edit Product
                                  </h3>
                                  <button
                                    type="button"
                                    className="border-0 bg-none p-0"
                                    onClick={EditPlatformBack}
                                  >
                                    <span>
                                      <img src={CloseIco} alt="close icon" />
                                    </span>
                                  </button>
                                </div>
                                <div className="store_scroll_bar_add">
                                  <div className="input-image-show align-items-end">
                                    <div className="input-img-label">
                                      <div className="upload_crop">
                                        <CommonImageUpload
                                          handleImageChange={handleImageChange}
                                          buttonText="Upload Image"
                                          selectedImg=""
                                          defaultImg={upload}
                                          className="upload_label"
                                        />
                                      </div>
                                    </div>
                                    {renderImg}
                                  </div>
                                  <div className="vertical-divider mt-4" />
                                  <form className="item-details-input pt-3">
                                    <div className="name-field">
                                      <div>
                                        <label>Product Name</label>
                                        <CommonInput
                                          name="name"
                                          placeholder="Name"
                                          value={inputFields.name}
                                          onChange={handleEditChange}
                                          style={{ textTransform: 'capitalize' }}
                                        />
                                        <CommonInput
                                          name="id"
                                          type="hidden"
                                          value={inputFields.id}
                                        />
                                      </div>
                                    </div>
                                    <div className="input-grid">
                                      <div>
                                        <label>Regular Price</label>
                                        <CommonInput
                                          name="regular_price"
                                          type="number"
                                          placeholder="Price"
                                          onWheel={(e) => e.target.blur()}
                                          value={inputFields.regular_price}
                                          onChange={handleEditChange}
                                        />
                                      </div>
                                      <div>
                                        <label>Discount Price</label>
                                        <CommonInput
                                          name="discount_price"
                                          type="number"
                                          placeholder="Price"
                                          onWheel={(e) => e.target.blur()}
                                          value={inputFields.discount_price}
                                          onChange={handleEditChange}
                                        />
                                      </div>
                                    </div>
                                    <div className="input-grid">
                                      <div>
                                        <label>Category</label>
                                        <Dropdown
                                          title={getCategoryName(
                                            inputFields.category_id
                                          )}
                                          onChanged={(d) => {
                                            SubCategory({
                                              category_id: d.id
                                            })
                                            setInputFields({
                                              ...inputFields,
                                              category_id: d.id
                                            })
                                          }}
                                          Data={category?.map((d) => ({
                                            title: d.name,
                                            id: d.id
                                          }))}
                                          name="category_id"
                                          customClass="store_add_dropdown"
                                        />
                                      </div>
                                      <div>
                                        <label>Subcategory</label>
                                        <Dropdown
                                          title={getSubCategoryNameById(
                                            inputFields.sub_category_id
                                          )}
                                          onChanged={(d) => setInputFields({
                                              ...inputFields,
                                              sub_category_id: d.id
                                            })}
                                          Data={subCategory?.map((d) => ({
                                            title: d.sub_category_name,
                                            id: d.sub_category_id
                                          }))}
                                          name="sub_category_id"
                                          value={inputFields.sub_category_id}
                                          customClass="store_add_dropdown"
                                        />
                                      </div>
                                    </div>
                                    <div className="input-grid">
                                      <div>
                                        <label>Product-Link</label>
                                        <CommonInput
                                          name="product_link"
                                          placeholder="Product link"
                                          value={inputFields.product_link}
                                          onChange={handleEditChange}
                                        />
                                      </div>
                                    </div>
                                    <div>
                                      <label>Decription</label>
                                      <textarea
                                        placeholder="Type here"
                                        name="description"
                                        onChange={handleEditChange}
                                        value={inputFields.description}
                                      />
                                    </div>
                                  </form>
                                </div>
                              </div>
                              <div className="button-row d-flex align-items-center justify-content-between store_sticky_btn pb-0">
                                <div>
                                  <CommonButton
                                    label="Back"
                                    type="button"
                                    onClick={EditPlatformBack}
                                    isPrimary={false}
                                    context="Profile"
                                    className="me-4"
                                  />
                                  <CommonButton
                                    label="Preview"
                                    type="button"
                                    isPrimary={false}
                                    context="Profile"
                                    className="common-button"
                                    disabled
                                  />
                                </div>
                                <CommonButton
                                  label="Publish"
                                  type="button"
                                  onClick={() => EditSubmit(inputFields)}
                                  isPrimary
                                  disabled={!isEdited}
                                  context="Profile"
                                />
                              </div>
                            </div>
                          </div>
                        </form>
                      )}
                      <div onClick={() => GeneralSubmit(data)}>
                        <div
                          className="product-item-cover"
                          style={{
                            backgroundImage: `url(${urlImageProductPathStore + data.image})`,
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: 'cover',
                            backgroundPosition: 'center'
                          }}
                        >
                          <div className="rating">
                            <img src={RatingStar} alt="star" />
                            <span>4.9</span>
                          </div>
                        </div>
                        <div className="product-item-details d-flex  justify-content-between">
                          <span>{data.name}</span>
                          {/* <span>{data.description}</span> */}
                          <div>
                            {data.discount_price > 0 ? (
                              <>
                                <h4>{data.discount_price}</h4>
                                <h4 className="actual-price">
                                  {data.regular_price}
                                </h4>
                              </>
                            ) : (
                              <h4>{data.regular_price}</h4>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                )}
              </div>
            </div>
            <div className="item-details-input pt-0">
              <div className="button-row store_sticky_btn pt-2 pb-0">
                <CommonButton
                  label="Cancel"
                  type="button"
                  onClick={HandleCancel}
                  isPrimary={false}
                  context="Profile"
                />
                {/* <CommonButton
                  label="Add"
                  type="button"
                  onClick={AddLibrary}
                  isPrimary={false}
                  context="Profile"
                /> */}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
export default AddProductFilter
