import React from 'react'
import CommonButton from '../ButtonComponent/ButtonCommon'

const ConfirmationModal = ({
  isVisible,
  onConfirm,
  onCancel,
  message = 'Do you want to delete this?',
  isLoading = false
}) => {
  if (!isVisible) return null

  return (
    <div className="add-item-model">
      <div className="add-item-contents h-auto w-auto">
        <div className="item-details-input pt-0">
          <h3 className="mb-0 text-center">{message}</h3>
          <div className="button-row justify-content-center">
            <CommonButton
              label="Cancel"
              type="button"
              // className="secondary-btn-modelConfirm"
              onClick={onCancel}
              disabled={isLoading}
              isPrimary={false}
              context="Profile"

            />
            <CommonButton
              label="Confirm"
              type="button"
              onClick={onConfirm}
              // className="primary-btn-modelConfirm"
              disabled={isLoading}
              isPrimary
              context="Profile"
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default ConfirmationModal
