/* eslint-disable no-restricted-globals */
import React, { useEffect, useRef, useState } from 'react'
import '../HotelComponent/Discount.css'
import './Menu.css'
import Input from 'antd/es/input/Input'
import { Table, Button } from 'antd'
import DatePicker from 'react-datepicker'
import moment from 'moment'
import { toast } from 'react-toastify'
import _ from 'lodash'
import PlusIco from '../../../images/PlusIco.svg'
import Remove from '../../../images/close-black.svg'
import tagsDiscount from '../../../images/local_offer.svg'
// import UploadImg from '../../../images/upload.svg'
// import FoodImage from '../../../images/Food_img.svg'
import Edit from '../../../images/edit_icon.svg'
import Delete from '../../../images/delete_icon.svg'
import CommonInput from '../../InputComponet/CommonInput'
import CommonButton from '../../ButtonComponent/ButtonCommon'
import { capitalizeFirstLetter, lockScroll, unlockScroll } from '../../../helpers/Utility'
import {
  imageUploadLocal,
  resDiscountAdd,
  resDiscountDelete,
  resDiscountEdit,
  resDiscountGet,
  resParticularDiscountDetails,
  s3ImagePathLocal
} from '../../../services/Service'
import { compressImage, dataDecrypt } from '../../../services/http-services'
import ConfirmationModal from '../../ConfirmationModal/ConfirmationModal'
import Loader from '../../Loader/Loader'
import Pagination from '../../Pagination/Pagination'
import CommonImageUpload from '../../ImageCroper/StoreImageCroper'

const Discount = ({ isRestaurantId }) => {
  const [isAddDiscount, setIsAddDiscount] = useState(false)
  const [isRoomFeature, setIsRoomFeature] = useState(false)
  const [searchText, setSearchText] = useState('')
  const [inputFields, setInputFields] = useState({
    discount_name: '',
    coupan_code: '',
    expiry_date: '',
    min_amount: '',
    discount: '',
    restaurant_id: isRestaurantId
  })
  const [discountListData, setDiscountListData] = useState([])
  const [loading, setLoading] = useState(false)
  const [delDiscountId, setDelDiscountId] = useState()
  const [ShowConfirmModel, setShowConfirmModel] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [particularDiscount, setParticularDiscount] = useState([])
  const [isFormComplete, setIsFormComplete] = useState(false)
  const [initialValues, setInitialValues] = useState({})
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const [selectedFilePath, setSelectedFilePath] = useState('')
  const [selectedFileObj, setSelectedFileObj] = useState('')

  const clearValue = () => {
    setInputFields({
      discount_name: '',
      coupan_code: '',
      expiry_date: '',
      min_amount: '',
      discount: ''
    })
    setSelectedFilePath('')
    setSelectedFileObj('')
  }

  const handleChange = (e) => {
    setInputFields({ ...inputFields, [e.target.name]: e.target.value })
  }

  const addDiscount = () => {
    setIsAddDiscount(true)
  }
  const handleCancels = () => {
    setIsAddDiscount(false)
    setIsRoomFeature(false)
    clearValue()
  }

  const handleDeleteCancel = () => {
    setShowConfirmModel(false)
  }

  const ShowConfirm = (record) => {
    setDelDiscountId(record.discount_id)
    setShowConfirmModel(true)
  }
  const showDiscount = (record) => {
    setIsRoomFeature(true)
    particularRoomData(record.discount_id)
  }

  const handleFileChange = (file) => {
    const maxFileSizeMB = 10
    const maxFileSizeBytes = maxFileSizeMB * 1024 * 1024
    if (file.size > maxFileSizeBytes) {
      toast.error(`File size exceeds ${maxFileSizeMB} MB`)
      return
    }
    const reader = new FileReader()
    reader.onload = (e) => {
      setSelectedFilePath(e.target.result)
      setSelectedFileObj(file)
    }
    reader.readAsDataURL(file)
  }

  const EditDiscount = (record) => {
    if (Object.keys(record).length > 0) {
      setIsEdit(true)
      setInputFields({
        restaurant_id: record.restaurant_id,
        coupan_code: record.coupan_code,
        discount: record.discount.replace(/%/g, ''),
        discount_id: record.discount_id,
        discount_name: record.discount_name,
        min_amount: record.min_amount,
        expiry_date: formatDateToISO(record.expiry_date),
        image: record.image
      })
      setSelectedFilePath(s3ImagePathLocal + record.image)
    }
    setInitialValues({
      restaurant_id: record.restaurant_id,
      coupan_code: record.coupan_code,
      discount: record.discount.replace(/%/g, ''),
      discount_id: record.discount_id,
      discount_name: record.discount_name,
      min_amount: record.min_amount,
      expiry_date: formatDateToISO(record.expiry_date),
      image: record.image
    })
    setIsAddDiscount(true)
  }

  const handleSearch = (e) => {
    setSearchText(e.target.value.toLowerCase())
  }

  const columns = [
    {
      title: 'DISCOUNT NAME ',
      dataIndex: 'discount_name',
      key: 'discount_name',
      render: (text, record) => (
        <div className="image_name">
          <span>
            {record.discount_name.length > 15
              ? `${record.discount_name.substring(0, 15)}...`
              : record.discount_name}
          </span>
        </div>
      ),
      sorter: (a, b) => a.discount_name.localeCompare(b.discount_name),
      onFilter: (value, record) => record.discount_name.toLowerCase().includes(value.toLowerCase()),
      className: 'custom-column'
    },
    {
      title: 'CODE',
      dataIndex: 'coupan_code',
      key: 'coupan_code',
      sorter: (a, b) => a.coupan_code.localeCompare(b.coupan_code)
    },
    {
      title: 'EXPIRY',
      dataIndex: 'expiry_date',
      key: 'expiry_date',
      sorter: (a, b) => a.expiry_date.localeCompare(b.expiry_date)
    },
    {
      title: 'MIN AMOUNT',
      dataIndex: 'min_amount',
      key: 'min_amount',
      sorter: (a, b) => a.min_amount.localeCompare(b.min_amount)
    },
    {
      title: 'DISCOUNT',
      dataIndex: 'discount',
      key: 'discount',
      sorter: (a, b) => a.discount.localeCompare(b.discount)
    },
    {
      title: 'ACTION',
      key: 'action',
      render: (text, record) => (
        <div className="image-buttons-discounts">
          <Button
            icon={<img src={Edit} alt="Edit" />}
            onClick={(e) => {
              e.stopPropagation()
              EditDiscount(record)
            }}
          />
          <Button
            icon={<img src={Delete} alt="Delete" />}
            onClick={(e) => {
              e.stopPropagation()
              ShowConfirm(record)
            }}
          />
        </div>
      )
    }
  ]

  const filteredData = discountListData.filter((item) => item
  .discount_name.toLowerCase().includes(searchText))

  const datePickerRef = useRef(null)

  const handleDateChange = (date) => {
    const today = new Date()
    const minDate = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate()
    )

    if (date >= minDate) {
      setInputFields({
        ...inputFields,
        expiry_date: moment(date).format('YYYY-MM-DD')
      })
    } else {
      setInputFields({
        ...inputFields,
        expiry_date: null
      })

      if (datePickerRef.current) {
        datePickerRef.current.setOpen(true)
      }
    }
  }

  const formatDateToISO = (dateString) => {
    if (!dateString) return null

    const date = new Date(dateString)
    if (isNaN(date)) return null

    const year = date.getFullYear()
    const month = String(date.getMonth() + 1).padStart(2, '0')
    const day = String(date.getDate()).padStart(2, '0')

    return `${year}-${month}-${day}`
  }

  const today = new Date()
  const minYear = today.getFullYear()
  const maxYear = today.getFullYear() + 10

  const years = []
  for (let year = minYear; year <= maxYear; year++) {
    years.push(year)
  }

  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ]

  const allDiscountData = (currentPage) => {
    const payload = {
      restaurant_id: isRestaurantId
    }
    resDiscountGet(payload, currentPage).then((res) => {
      if (res.data.success) {
        setDiscountListData(dataDecrypt(res.data.data).data)
        setTotalPages(dataDecrypt(res.data.data).last_page)
        setCurrentPage(dataDecrypt(res.data.data).current_page)
      }
    })
  }

  const discountAddApiCall = async () => {
    if (selectedFileObj) {
      const imageFile = await compressImage(selectedFileObj)
      const coverFormData = new FormData()
      coverFormData.append('file', imageFile)
      coverFormData.append('type', 'discount')

      const coverUploadResponse = await imageUploadLocal(coverFormData)
      if (!coverUploadResponse.data.success) {
        throw new Error(
          coverUploadResponse.data.message || 'Cover image upload failed'
        )
      }
      const decryptedCoverData = dataDecrypt(coverUploadResponse.data.data)
      inputFields.image = decryptedCoverData
    }
    const payload = {
      discount_name: inputFields.discount_name,
      coupan_code: inputFields.coupan_code,
      expiry_date: inputFields.expiry_date,
      discount: inputFields.discount,
      restaurant_id: isRestaurantId,
      min_amount: inputFields.min_amount,
      discount_id: inputFields.discount_id,
      image: inputFields.image
    }
    if (isEdit) {
      resDiscountEdit(payload).then((res) => {
        if (res.data.success) {
          setIsAddDiscount(false)
          clearValue()
          toast.success(res?.data?.message)
          setIsEdit(false)
          allDiscountData(currentPage)
        } else {
          toast.error(res?.data?.message)
          setIsEdit(false)
        }
      })
    } else {
      resDiscountAdd(payload).then((res) => {
        if (res.data.success) {
          toast.success(res?.data?.message)
          clearValue()
          setIsAddDiscount(false)
          allDiscountData(currentPage)
        } else {
          toast.error(res?.data?.message)
        }
      })
    }
  }

  const deleteDiscount = () => {
    const data = {
      discount_id: delDiscountId
    }
    setLoading(true)
    resDiscountDelete(data).then((res) => {
      if (res.data.success) {
        setShowConfirmModel(false)
        setLoading(false)
        allDiscountData(currentPage)
        toast.success(res?.data?.message)
      } else {
        toast.error(res?.data?.message)
      }
    })
  }

  const particularRoomData = (discount_id) => {
    const payload = {
      restaurant_id: isRestaurantId,
      discount_id
    }
    resParticularDiscountDetails(payload).then((res) => {
      if (res.data.success) {
        setParticularDiscount(dataDecrypt(res.data.data))
      }
    })
  }

  const validateForm = () => {
    const { discount_name, coupan_code, expiry_date, min_amount, discount } =
      inputFields

    if (discount_name && coupan_code && expiry_date && min_amount && discount) {
      setIsFormComplete(true)
    } else {
      setIsFormComplete(false)
    }
  }

  const handlePageChange = (pageNumber) => {
    allDiscountData(pageNumber)
    setCurrentPage(pageNumber)
  }

  useEffect(() => {
    allDiscountData(currentPage)
  }, [])

  useEffect(() => {
    if (!isEdit) {
      validateForm()
    }
  }, [inputFields])

  useEffect(() => {
    if (isEdit) {
      setIsFormComplete(!_.isEqual(inputFields, initialValues))
    }
  }, [inputFields, initialValues, isEdit])

  useEffect(() => {
    if (isAddDiscount || isRoomFeature || ShowConfirmModel) {
      lockScroll()
    } else {
      unlockScroll()
    }
    return () => {
      unlockScroll()
    }
  }, [isAddDiscount, isRoomFeature, ShowConfirmModel])

  console.log('particularDiscount', particularDiscount)

  return (
    <div className="">
      <div className="position-relative">
        {discountListData.length === 0 ? (
          <div className="add_room_text">
            <img className="d-block" src={tagsDiscount} alt="bedroom" />
            <h3>CREATE YOUR FIRST DEAL</h3>
            <p>You have not created any deal yet</p>
            <div className="add-video-rows" onClick={addDiscount}>
              <div className="add_new_content">
                <img src={PlusIco} alt="" />
                Create Deal
              </div>
            </div>
          </div>
        ) : (
          <div className="local-room-main">
            <h3 className="d-flex align-items-center justify-content-between">
              DISCOUNTS
              <button
                type="button"
                className="primary-btn"
                onClick={addDiscount}
              >
                <img src={PlusIco} alt="add" />
                Create Discount
              </button>
            </h3>
            <div className="discount_list">
              <Input
                placeholder="Search by Item Name"
                onChange={handleSearch}
                style={{ width: 200, marginBottom: '1rem' }}
              />
              <div className="discount_list">
                <Table
                  columns={columns}
                  dataSource={filteredData}
                  pagination={false}
                  onRow={(record) => {
                    return {
                      onClick: () => {
                        showDiscount(record)
                      }
                    }
                  }}
                />
              </div>
              <ConfirmationModal
                isVisible={ShowConfirmModel}
                onConfirm={deleteDiscount}
                onCancel={handleDeleteCancel}
                message="Do you want to delete this?"
                isLoading={loading}
              />
              {loading && <Loader />}
            </div>
            {totalPages > 1 && (
              <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={handlePageChange}
              />
            )}
          </div>
        )}

        {isAddDiscount && (
          <div className="add-item-model">
            <div className="add-item-content local_type_modal">
              <div className="type_close mb-2 local_sticky_header">
                <div className="d-flex align-items-center col-gap-10">
                  CREATE DEAL
                </div>
                <button className="cancel_icon" type="button" onClick={handleCancels}>
                  <img src={Remove} alt="" />
                </button>
              </div>
              <div className="item-details-input pt-0 local_contact_scroll_bars">
                <div className="input-grid">
                  <div>
                    <label>Discount Name</label>
                    <CommonInput
                      placeholder="Enter name"
                      name="discount_name"
                      onChange={handleChange}
                      value={capitalizeFirstLetter(inputFields.discount_name)}
                    />
                  </div>
                  <div>
                    <label>Coupon Code</label>
                    <CommonInput
                      placeholder="Enter code"
                      name="coupan_code"
                      onChange={handleChange}
                      value={inputFields.coupan_code}
                    />
                  </div>
                </div>
                <div className="input-grid">
                  <div>
                    <label>Expiry Date</label>
                    <div className="calendar">
                      <DatePicker
                        ref={datePickerRef}
                        // selected={formatDateToISO(inputFields.expiry_date)}
                        selected={
                          inputFields.expiry_date
                            ? new Date(inputFields.expiry_date)
                            : null
                        }
                        required
                        showMonthDropdown
                        autoComplete="off"
                        showYearDropdown
                        onChange={handleDateChange}
                        name="expiry_date"
                        dateFormat="yyyy-MM-dd"
                        placeholderText="Please Choose Date"
                        minDate={new Date()}
                        onKeyDown={(e) => {
                            e.preventDefault()
                        }}
                        className="custom-datepicker"
                        renderCustomHeader={({
                          date,
                          changeYear,
                          changeMonth,
                          decreaseMonth,
                          increaseMonth,
                          prevMonthButtonDisabled,
                          nextMonthButtonDisabled
                        }) => (
                          <div className="datepicker-header">
                            <button
                              type="button"
                              className="datepicker-button"
                              onClick={(e) => {
                                e.preventDefault()
                                decreaseMonth()
                              }}
                              disabled={prevMonthButtonDisabled}
                            >
                              {'<'}
                            </button>
                            <select
                              className="datepicker-select"
                              value={date.getFullYear()}
                              onChange={({ target: { value } }) => changeYear(Number(value))}
                            >
                              {years.map((option) => (
                                <option key={option} value={option}>
                                  {option}
                                </option>
                              ))}
                            </select>
                            <select
                              className="datepicker-select"
                              value={months[date.getMonth()]}
                              onChange={({ target: { value } }) => {
                                changeMonth(months.indexOf(value))
                              }}
                            >
                              {months.map((option) => (
                                <option key={option} value={option}>
                                  {option}
                                </option>
                              ))}
                            </select>
                            <button
                              type="button"
                              className="datepicker-button"
                              onClick={(e) => {
                                e.preventDefault()
                                increaseMonth()
                              }}
                              disabled={nextMonthButtonDisabled}
                            >
                              {'>'}
                            </button>
                          </div>
                        )}
                      />
                    </div>
                  </div>
                  <div>
                    <label>Minimum Amount</label>
                    <CommonInput
                      placeholder="€ 00.00"
                      name="min_amount"
                      onChange={handleChange}
                      value={inputFields.min_amount}
                      pattern="/^-?\d+\.?\d*$/"
                      maxLength={3}
                      onKeyPress={(e) => {
                      if (e.target.value.length === 3) {
                        e.preventDefault()
                      }
                    }}
                    />
                  </div>
                </div>
                <div className="">
                  <div>
                    <label>Discount</label>
                    <CommonInput
                      name="discount"
                      type="number"
                      placeholder="Enter percentage"
                      onChange={handleChange}
                      value={inputFields.discount}
                      pattern="/^-?\d+\.?\d*$/"
                      maxLength={2}
                      onKeyPress={(e) => {
                      if (e.target.value.length === 2) {
                        e.preventDefault()
                      }
                    }}
                    />
                  </div>
                </div>
                <div className="upload-input upload-input-with-border">
                  <label htmlFor="upload-file">
                    <CommonImageUpload
                      handleImageChange={handleFileChange}
                      index={0}
                      buttonText="Upload Cover"
                      className=""
                      selectedImg={selectedFilePath}
                      defaultImg=""
                      forImage="image"
                      forName="video"
                    />
                  </label>
                </div>
              </div>
              <div className="item-details-input pt-0">
                <div className="local_button_row local_sticky_btn">
                  <CommonButton
                    label="Cancel"
                    onClick={handleCancels}
                    isPrimary={false}
                    context="Profile"
                  />
                  <CommonButton
                    label="Save"
                    type="button"
                    isPrimary
                    disabled={!isFormComplete}
                    onClick={discountAddApiCall}
                    context="Profile"
                  />
                </div>
              </div>
            </div>
          </div>
        )}

        {isRoomFeature && (
          <div className="add-item-model">
            {particularDiscount.map((data, index) => (
              <div className="add-item-content add_room_modal" key={index}>
                <div className="type_close mb-2">
                  <div className="d-flex align-items-center col-gap-10">
                    {data.discount_name}
                  </div>
                  <button className="cancel_icon" type="button" onClick={handleCancels}>
                    <img src={Remove} alt="" />
                  </button>
                </div>
                <div className="item-details-input ">
                  <div className="room_image">
                    <img
                      src={s3ImagePathLocal + data.image}
                      alt="roomImage"
                    />
                  </div>
                </div>
                <div className="item-details-input pt-0">
                  <div className="mb-3 mt-3">
                    <div className="deal-label">
                      <label>Coupon Code</label>
                      <p>
                        {' '}
                        {data.coupan_code}
                      </p>
                    </div>
                  </div>
                  <div className="input-grid detail-grid">
                    <div>
                      <label>Discount Name </label>
                      <p>{data.discount_name}</p>
                    </div>
                    <div>
                      <label>Coupon Valid Till</label>
                      <p>{data.expiry_date}</p>
                    </div>
                  </div>
                  <div className="input-grid detail-grid">
                    <div>
                      <label>Min. Amount</label>
                      <p>
                        €
                        {data.min_amount}
                      </p>
                    </div>
                    <div>
                      <label>Discount</label>
                      <p>
                        {data.discount}
                      </p>
                    </div>
                  </div>
                  <div className="local_button_row">
                    <CommonButton
                      label="CANCEL"
                      onClick={handleCancels}
                      isPrimary={false}
                      context="Profile"
                      className="common-button"
                    />
                  </div>
                </div>
              </div>
          ))}
          </div>
        )}
      </div>
    </div>
  )
}
export default Discount
