import React, { useEffect, useState } from 'react'
import './Local.css'
// import AddIco from '../../images/AddIco.svg'
import HotelPopup from './addPopup/hotelPopup'
import Dropdown from '../Dropdown/Dropdown'
import local_image from '../../images/local_main.svg'
import local_add_img from '../../images/local_add_img.svg'
import DownIco from '../../images/DownIco.svg'
import Remove from '../../images/close-black.svg'
import Add from '../../images/Add.svg'
import Visitor from '../../images/visitor.svg'
import UpPath from '../../images/up-path.svg'
import DownPath from '../../images/down-trend.svg'
import impression from '../../images/impression.svg'
import Clicks from '../../images/click.svg'
import Requests from '../../images/Requests.svg'
import StatusImg from '../../images/status.svg'
import Excursions from '../../images/excursions.svg'
import ServiceProvider from '../../images/serviceprovider.svg'
import Stores from '../../images/stores.svg'
import Events from '../../images/events.svg'
import Restaurant from '../../images/restaurant.svg'
import Hotel from '../../images/hotel.svg'
import RestaurantPopup from './addPopup/restaurantPopup'
import EventPopup from './addPopup/eventPopup'
import CommonInput from '../InputComponet/CommonInput'
import CommonButton from '../ButtonComponent/ButtonCommon'
import { lockScroll, unlockScroll } from '../../helpers/Utility'

const Local = () => {
  const [visibleItems, setVisibleItems] = useState(3)
  const [selectLocalType, setSelectLocalType] = useState(false)
  const [isHotel, setIsHotel] = useState(false)
  const [isRestaurant, setIsRestaurant] = useState(false)
  const [isEvent, setIsEvent] = useState(false)
  const [selectedStep, setSelectedStep] = useState(0)

  const [isExpanded, setIsExpanded] = useState(false)

  const toggleItems = () => {
    if (isExpanded) {
      setVisibleItems(3)
    } else {
      setVisibleItems(locations.length)
    }
    setIsExpanded(!isExpanded)
  }

  const nextClick = () => {
    if (selectedStep === null) return

    setSelectLocalType(false)

    setIsHotel(false)
    setIsRestaurant(false)
    setIsEvent(false)

    switch (selectedStep) {
      case 0:
        setIsHotel(true)
        break
      case 1:
        setIsRestaurant(true)
        break
      case 2:
        setIsEvent(true)
        break
      default:
        break
    }
  }
  const handleCancels = () => {
    setIsHotel(false)
    setIsRestaurant(false)
    setIsEvent(false)
    setSelectLocalType(false)
  }

  const addType = () => {
    setIsHotel(false)
    setIsRestaurant(false)
    setIsEvent(false)
    setSelectLocalType(true)
  }

  const locations = [
    {
      name: 'FONTENAY HAMBURG',
      image: local_image
    },
    {
      name: 'FONTENAY HAMBURG',
      image: local_image
    },
    {
      name: 'FONTENAY HAMBURG',
      image: local_image
    },
    {
      name: 'FONTENAY HAMBURG',
      image: local_image
    },
    {
      name: 'FONTENAY HAMBURG',
      image: local_image
    },
    {
      name: 'FONTENAY HAMBURG',
      image: local_image
    }
  ]

  const handleCancel = () => {
    setSelectLocalType(true)
    setSelectedStep(null)
    setIsHotel(false)
    setIsRestaurant(false)
    setIsEvent(false)
  }

  useEffect(() => {
    if (selectLocalType || isHotel || isRestaurant || isEvent) {
      lockScroll()
    } else {
      unlockScroll()
    }
    return () => {
      unlockScroll()
    }
  }, [selectLocalType, isHotel, isRestaurant, isEvent])

  return (
    <div className="creator-profile-main">
      <div className="feed-container d-block">
        <div className="details-tab-view common-card-body p-20">
          <div className="local_manager d-flex align-items-center justify-content-between">
            LOCAL MANAGER
            <button type="button" className="dark-outline-btn">
              <img src={Add} alt="add" />
              Add Location
            </button>
          </div>
          <div className="local_manager_section">
            <div className="local-manager-item">
              <div className="icon_local">
                <img src={Visitor} alt="visitor" />
              </div>
              <div className="local-manager-item-info">
                <p>VISITORS</p>
                <span>
                  358
                  <img src={UpPath} alt="" />
                  <span className="positive-trend">1.3%</span>
                </span>
              </div>
            </div>
            <div className="local-manager-item">
              <div className="icon_local">
                <img src={impression} alt="impression" />
              </div>
              <div className="local-manager-item-info">
                <p>IMPRESSIONS</p>
                <span>
                  358
                  <img src={UpPath} alt="" />
                  <span className="positive-trend">1.3%</span>
                </span>
              </div>
            </div>
            <div className="local-manager-item">
              <div className="icon_local">
                <img src={Clicks} alt="Clicks" />
              </div>
              <div className="local-manager-item-info">
                <p>CLICKS</p>
                <span>
                  358
                  <img src={UpPath} alt="" />
                  <span className="positive-trend">1.3%</span>
                </span>
              </div>
            </div>
            <div className="local-manager-item">
              <div className="icon_local">
                <img src={Requests} alt="request" />
              </div>
              <div className="local-manager-item-info">
                <p>REQUESTS</p>
                <span>
                  358
                  <img src={DownPath} alt="" />
                  <span className="negetive-trend">1.3%</span>
                </span>
              </div>
            </div>
            <div className="local-manager-item">
              <div className="icon_local">
                <img src={StatusImg} alt="status" />
              </div>
              <div className="local-manager-item-info">
                <p>STATUS</p>
                <span>
                  358
                  <img src={DownPath} alt="" />
                  <span className="negetive-trend">1.3%</span>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="feed-container mt-30 d-block">
        <div className="details-tab-view common-card-body p-20">
          <div className="d-flex align-items-center justify-content-between flex-wrap">
            <div className="local_location">LOCATIONS</div>
            <div className="tool-option d-flex align-items-center drop-search-3-0">
              <Dropdown
                title="Sort"
                Data={[
                  { title: 'Scaloo' },
                  { title: 'Spacure' },
                  { title: 'PETZINO' },
                  { title: 'A-Z FIT' },
                  { title: 'TESTIEE' }
                ]}
              />
              <Dropdown
                title="Filter"
                Data={[
                  { title: 'Scaloo' },
                  { title: 'Spacure' },
                  { title: 'PETZINO' },
                  { title: 'A-Z FIT' },
                  { title: 'TESTIEE' }
                ]}
              />
              <CommonInput
                className="search-input"
                placeholder="Search Location here..."
              />
            </div>
          </div>
          <div className="mt-2 local_product_grid">
            {/* Show only visible items */}
            {locations.slice(0, visibleItems).map((location, index) => (
              <div className="local_product" key={index}>
                <div>
                  <img src={location.image} alt={location.name} />
                </div>
                <div className="local_location">
                  <h1>{location.name}</h1>
                </div>
                <div className="local_button_grp">
                  <CommonButton
                    label="VIEW LOCATION"
                    context="local"
                    isPrimary
                    className="local_view_btn"
                  />
                  <CommonButton
                    label="EDIT LOCATION"
                    context="local"
                    className="edit_local_btn"
                  />
                </div>
              </div>
            ))}

            <div className="local_product">
              <div className="add_new_border">
                <div className="image_icon_text" onClick={addType}>
                  <img src={local_add_img} alt="add_img" />
                  <h3>Add New Location</h3>
                </div>
              </div>
            </div>
          </div>

          <div className="collapse-button text-center mt-3">
            <button type="button" onClick={toggleItems}>
              {isExpanded ? (
                <img className="upIcon" alt="Show less" src={DownIco} />
              ) : (
                <img className="downIcon" alt="Show more" src={DownIco} />
              )}
            </button>
          </div>

          {selectLocalType && (
            <div className="add-item-model">
              <div className="add-item-content local_type_modal">
                <div className="type_close">
                  {/* {step === 0 && <div>Select local type</div>} */}
                  <div>Select local type</div>
                  <button className="cancel_icon" type="button" onClick={handleCancels}>
                    <img src={Remove} alt="" />
                  </button>
                </div>
                <div className="all_type_name">
                  <div
                    className={`type_name text-center ${selectedStep === 0 ? 'selected' : ''}`}
                    onClick={() => setSelectedStep(0)}
                  >
                    <img src={Hotel} alt="hotel" />
                    <h1>HOTEL</h1>
                    <p>
                      Lorem ipsum dolor sit ame consectetur adipiscing elit.
                    </p>
                  </div>

                  <div
                    className={`type_name text-center ${selectedStep === 1 ? 'selected' : ''}`}
                    onClick={() => setSelectedStep(1)}
                  >
                    <img src={Restaurant} alt="restaurant" />
                    <h1>RESTAURANT & BAR</h1>
                    <p>
                      Lorem ipsum dolor sit ame consectetur adipiscing elit.
                    </p>
                  </div>

                  <div
                    className={`type_name text-center ${selectedStep === 2 ? 'selected' : ''}`}
                    onClick={() => setSelectedStep(2)}
                  >
                    <img src={Events} alt="events" />
                    <h1>EVENTS</h1>
                    <p>
                      Lorem ipsum dolor sit ame consectetur adipiscing elit.
                    </p>
                  </div>

                  <div
                    className={`type_name text-center ${selectedStep === 3 ? 'selected' : ''}`}
                    onClick={() => setSelectedStep(3)}
                  >
                    <img src={Stores} alt="stores" />
                    <h1>STORE</h1>
                    <p>
                      Lorem ipsum dolor sit ame consectetur adipiscing elit.
                    </p>
                  </div>

                  <div
                    className={`type_name text-center ${selectedStep === 4 ? 'selected' : ''}`}
                    onClick={() => setSelectedStep(4)}
                  >
                    <img src={ServiceProvider} alt="service-provider" />
                    <h1>SERVICE PROVIDER</h1>
                    <p>
                      Lorem ipsum dolor sit ame consectetur adipiscing elit.
                    </p>
                  </div>

                  <div
                    className={`type_name text-center ${selectedStep === 5 ? 'selected' : ''}`}
                    onClick={() => setSelectedStep(5)}
                  >
                    <img src={Excursions} alt="excursion" />
                    <h1>EXCURSIONS</h1>
                    <p>
                      Lorem ipsum dolor sit ame consectetur adipiscing elit.
                    </p>
                  </div>
                </div>
                <div className="local_button_row">
                  {/* <CommonButton
                    label="Cancel"
                    onClick={handleCancels}
                    context="local"
                    className="local_type_cancel"
                  />
                  <CommonButton
                    label="Next"
                    onClick={nextClick}
                    context="local"
                    className="local_type_next"
                    isPrimary
                  /> */}
                  <CommonButton
                    label="Cancel"
                    onClick={handleCancels}
                    isPrimary={false}
                    context="Profile"
                  />
                  <CommonButton
                    label="Next"
                    type="button"
                    isPrimary
                    onClick={nextClick}
                    context="Profile"
                    disabled={selectedStep === null}
                  />
                </div>
              </div>
            </div>
          )}

          {isHotel && <HotelPopup onCancel={handleCancel} />}
          {isRestaurant && <RestaurantPopup onCancel={handleCancel} />}
          {isEvent && <EventPopup onCancel={handleCancel} />}
        </div>
      </div>
    </div>
  )
}

export default Local
