/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import ProgressBar from '@ramonak/react-progress-bar'
import { toast } from 'react-toastify'
import _ from 'lodash'
import Skeleton from 'react-loading-skeleton'
import Comment from '../../images/ls_comments.svg'
import IconsMore from '../../images/akar-icons_more-horizontal-fill.svg'
import GroupDiscussion from '../../images/Group-discussion.svg'
import Dropdown from '../Dropdown/Dropdown'
import plusBtn from '../../images/plus.svg'
// import AddIco from '../../images/AddIco.svg'
import PlatformTag from './PlatformTag'
import Remove from '../../images/remove.svg'
import {
  addPollData,
  addVoteData,
  deletePollData,
  editPollData,
  getCategoryData,
  getPollsLibrary,
  getSubCategoryData,
  s3ImagePath,
  urlSystemIcon,
  voterListData
} from '../../services/Service'
import Loader from '../Loader/Loader'
import { dataDecrypt } from '../../services/http-services'
import CloseIco from '../../images/CloseIco.svg'
import Pagination from '../Pagination/Pagination'
import { capitalizeFirstLetter, lockScroll, unlockScroll } from '../../helpers/Utility'
import ConfirmationModal from '../ConfirmationModal/ConfirmationModal'
import Plus from '../../images/plus-rounded.svg'
import CommonButton from '../ButtonComponent/ButtonCommon'
import CommonInput from '../InputComponet/CommonInput'

const Polls = () => {
  const componentRef = useRef()

  const [ShowPollPreview, setShowPollPreview] = useState(false)
  const [ShowPollModel, setShowPollModel] = useState(false)
  const [isEditing, setIsEditing] = useState(false)
  const [step, setStep] = useState('')
  const [steps, setSteps] = useState([])
  const [editIndex, setEditIndex] = useState(-1)
  const [editValue, setEditValue] = useState('')
  const [Platformtags, setPlatformTags] = useState([])
  const [inputFields, setInputFields] = useState({
    app_id: [],
    topic: '',
    category_id: 0,
    sub_category_id: 0,
    option_1: '',
    option_2: '',
    option_3: '',
    option_4: ''
  })
  const [delPollId, setDelPollId] = useState()
  const [ShowConfirmModel, setShowConfirmModel] = useState(false)
  const [updatingIndex, setUpdatingIndex] = useState(null)
  const [loading, setLoading] = useState(false)
  const [category, setCategory] = useState([])
  const [subCategory, setSubCategory] = useState([])
  const [pollsDetail, setPollsDetail] = useState([])
  const [voteList, setVoteList] = useState([])
  const [showAll, setShowAll] = useState(false)
  const [isPublishEnabled, setIsPublishEnabled] = useState(false)
  const [votedPolls, setVotedPolls] = useState({})
  const [ShowProfileModel, setShowProfileModel] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [initialValues, setInitialValues] = useState({})
  const [isLoading, setIsLoading] = useState(false)

  const HandleCancel = () => {
    clearValue()
    setShowPollModel(false)
    setShowPollPreview(false)
    setIsEditing(false)
  }
  const handleCancel = () => {
    setShowConfirmModel(false)
  }

  const handleInputChangeStep = (e) => {
    setStep(e.target.value)
  }
  const handleAddStep = () => {
    if (step.trim() !== '' && steps.filter((s) => s.trim() !== '').length < 4) {
      const updatedSteps = [...steps, step]
      setSteps(updatedSteps)
      setInputFields({
        ...inputFields,
        [`option_${updatedSteps.filter((s) => s.trim() !== '').length}`]: step
      })
      setStep('')
      checkIfPublishEnabled(updatedSteps)
    }
  }

  const handleEditStep = (index) => {
    if (steps[index].trim() !== '') {
      setEditIndex(index)
      setEditValue(steps[index])
    }
  }

  const handleRemoveStep = (indexToRemove) => {
    const updatedSteps = steps.filter((_, index) => index !== indexToRemove)
    setSteps(updatedSteps)
    const updatedFields = {
      ...inputFields,
      [`option_${indexToRemove + 1}`]: ''
    }
    for (let i = indexToRemove; i < 3; i++) {
      updatedFields[`option_${i + 1}`] = updatedFields[`option_${i + 2}`] || ''
    }
    setInputFields(updatedFields)
    checkIfPublishEnabled(updatedSteps)
  }

  const handleSaveEditStep = () => {
    const updatedSteps = [...steps]
    updatedSteps[editIndex] = editValue
    setSteps(updatedSteps)
    setInputFields({
      ...inputFields,
      [`option_${editIndex + 1}`]: editValue
    })
    setEditIndex(-1)
    setEditValue('')
    checkIfPublishEnabled(updatedSteps)
  }

  const checkIfPublishEnabled = (updatedSteps) => {
    const nonEmptySteps = updatedSteps.filter((step) => step.trim() !== '')
    const hasChanges =
      JSON.stringify(initialValues) !== JSON.stringify(inputFields)
    setIsPublishEnabled(nonEmptySteps.length > 0 && hasChanges)
  }

  const nonEmptySteps = steps.filter((step) => step.trim() !== '')

  const HandlePollsPreview = () => {
    setShowPollPreview(true)
  }
  // const HandlePreCancel = () => {
  //   setShowPollModel(true)
  //   setShowPollPreview(false)
  // }
  const publishPoll = () => {
    if (isEditing) {
      editPollDataApi()
    } else {
      addPollDataSubmit()
    }
    setShowPollPreview(false)
    setShowPollModel(false)
    setIsEditing(false)
    clearValue()
  }

  const ShowCommunityGiveaway = () => {
    MainCategory()
    setShowPollModel(true)
  }

  const ShowConfirm = (poll_id) => {
    setDelPollId(poll_id)
    setShowConfirmModel(true)
  }

  const clearValue = () => {
    setPlatformTags([])
    setSteps([])
    setCategory([])
    setSubCategory([])
    setStep('')
    setInputFields({
      ...inputFields,
      app_id: [],
      topic: '',
      category_id: '',
      sub_category_id: '',
      option_1: '',
      option_2: '',
      option_3: '',
      option_4: ''
    })
  }

  const handleChange = (e) => {
    setInputFields({ ...inputFields, [e.target.name]: e.target.value })
  }

  const close = () => {
    setShowProfileModel(false)
  }

  const getSelectedAppId = (app) => {
    setInputFields({ ...inputFields, app_id: app.map((a) => a.id) })
  }

  const getPollLibraryData = () => {
    setIsLoading(true)
    getPollsLibrary({}).then((res) => {
      if (res?.data?.success) {
        setPollsDetail(dataDecrypt(res?.data?.data).data)
        // const { data } = dataDecrypt(res?.data?.data)
        // if (data.length !== 0) {
        //   const poll_id = data.map((poll) => poll.poll_id)
        //   voterList(poll_id)
        // }
        const { data } = dataDecrypt(res.data.data)
        // data.forEach((poll) => {
        //   voterList(poll.poll_id, poll)
        // })
        if (data.length !== 0) {
          voterList(data[0].poll_id)
        }
        setIsLoading(false)
      }
    })
  }

  const addPollDataSubmit = () => {
    setLoading(true)
    addPollData(inputFields).then((res) => {
      if (res.data.success) {
        setShowPollPreview(false)
        getPollLibraryData()
        setIsEditing(false)
        setLoading(false)
        clearValue()
        toast.success(res?.data?.message)
      } else {
        toast.error(res?.data?.message)
      }
    })
  }

  const deletePoll = () => {
    const data = {
      poll_id: delPollId
    }
    setLoading(true)
    deletePollData(data).then((res) => {
      if (res.data.success) {
        setShowConfirmModel(false)
        getPollLibraryData()
        setLoading(false)
        toast.success(res?.data?.message)
      }
    })
  }

  const MainCategory = () => {
    getCategoryData({}).then((res) => {
      if (res?.data?.success) {
        setCategory(dataDecrypt(res.data.data))
      }
    })
  }
  const SubCategory = (data) => {
    getSubCategoryData(data).then((res) => {
      if (res?.data?.success) {
        setSubCategory(dataDecrypt(res.data.data)[0]?.sub_category)
      }
    })
  }

  const getCategoryName = (categoryId) => {
    const categoryObj = category.find((cat) => cat.id === categoryId)
    return categoryObj ? categoryObj.category_name : 'Select category'
  }

  const getSubCategoryNameById = (subCategoryId) => {
    const subCategoryItem = subCategory.find(
      (sc) => sc.sub_category_id === subCategoryId
    )
    return subCategoryItem
      ? subCategoryItem.sub_category_name
      : 'Select subcategory'
  }

  const editPollDataApi = () => {
    const data = {
      topic: inputFields.topic,
      category_id: inputFields.category_id,
      sub_category_id: inputFields.sub_category_id,
      option_1: inputFields.option_1,
      option_2: inputFields.option_2,
      option_3: inputFields.option_3,
      option_4: inputFields.option_4,
      app_id: Platformtags.map((d) => d.id),
      poll_id: inputFields.poll_id
    }
    editPollData(data).then((res) => {
      if (res.data.success) {
        setIsEditing(false)
        clearValue()
        setLoading(false)
        toast.success(res?.data?.message)
        getPollLibraryData()
      }
    })
  }

  const onClickEditPoll = (videoData) => {
    setShowPollModel(true)
    setIsEditing(true)
    setInputFields({
      ...inputFields,
      topic: videoData.topic,
      category_id: videoData.category_id,
      sub_category_id: videoData.sub_category_id,
      option_1: videoData.option_1,
      option_2: videoData.option_2,
      option_3: videoData.option_3,
      option_4: videoData.option_4,
      app_id: videoData.app_data,
      poll_id: videoData.poll_id
    })
    setInitialValues({
      topic: videoData.topic,
      category_id: videoData.category_id,
      sub_category_id: videoData.sub_category_id,
      option_1: videoData.option_1,
      option_2: videoData.option_2,
      option_3: videoData.option_3,
      option_4: videoData.option_4,
      app_id: videoData.app_data,
      poll_id: videoData.poll_id
    })
    setPlatformTags(videoData.app_data)
    setSteps([
      videoData.option_1,
      videoData.option_2,
      videoData.option_3,
      videoData.option_4
    ])
    MainCategory({ category_id: videoData.category_id })
    SubCategory({ category_id: videoData.category_id })
  }

  const voterList = async (pollIds) => {
    const dataId = localStorage.getItem('app_id')
    const parsedData = JSON.parse(dataId)
    const platFormData = {
      app_id: parsedData?.id,
      poll_id: pollIds
    }
    try {
      const res = await voterListData(platFormData)
      if (res.data.success) {
        setVoteList(dataDecrypt(res.data.data).data)
      }
    } catch (error) {
      console.error('Error fetching voter list data:', error)
    }
  }

  const voteAdd = (poll_id, optionIndex) => {
    const platFormData = {
      poll_id,
      option: optionIndex
    }
    setLoading(true)
    addVoteData(platFormData).then((res) => {
      setLoading(false)
      if (res.data.success) {
        setVotedPolls(dataDecrypt(res.data.data).data)
        getPollLibraryData()
      }
    })
  }

  console.log(votedPolls, 'votedPolls')

  const showList = (poll_id) => {
    voterList(poll_id)
    setShowProfileModel(true)
  }

  const handleViewAll = () => {
    setShowAll(!showAll)
  }
  const displayedPollsDetail = showAll ? pollsDetail : pollsDetail.slice(0, 1)

  const isAppsSelected = inputFields.app_id?.length > 0

  const validateForm = () => {
    const {
      topic,
      category_id,
      sub_category_id,
      option_1,
      option_2,
      option_3,
      option_4
    } = inputFields
    return (
      topic.trim() !== '' &&
      category_id !== 0 &&
      sub_category_id !== 0 &&
      option_1.trim() !== '' &&
      option_2.trim() !== '' &&
      (steps.length >= 2 || (option_3.trim() !== '' && option_4.trim() !== ''))
    )
  }

  // voter list pagination

  const videosPerPage = 5
  const indexOfLastVideo = currentPage * videosPerPage
  const indexOfFirstVideo = indexOfLastVideo - videosPerPage
  const voteListData = voteList.slice(indexOfFirstVideo, indexOfLastVideo)
  const totalVideos = voteList.length
  const totalPages = Math.ceil(totalVideos / videosPerPage)

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber)
  }

  useEffect(() => {
    MainCategory()
    getPollLibraryData()
    const handleClickOutside = (event) => {
      if (
        componentRef.current &&
        !componentRef.current.contains(event.target)
      ) {
        setShowPollModel(false)
        setShowPollPreview(false)
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  useEffect(() => {
    checkIfPublishEnabled(steps)
  }, [inputFields, steps])

  useEffect(() => {
    if (!isEditing) {
      setIsPublishEnabled(validateForm())
    }
  }, [inputFields, steps])

  useEffect(() => {
    if (isEditing) {
      setIsPublishEnabled(!_.isEqual(inputFields, initialValues))
    }
  }, [inputFields, initialValues, isEditing, steps])

  useEffect(() => {
    if (
      ShowPollModel ||
      ShowPollPreview ||
      ShowProfileModel
    ) {
      lockScroll()
    } else {
      unlockScroll()
    }

    return () => {
      unlockScroll()
    }
  }, [
    ShowPollModel,
    ShowPollPreview,
    ShowProfileModel
  ])

  return (
    <div className="content-manager-Polls-wrapper common-card-body community-margin">
      <div className="polls-wrapper-header common-contnt-wrapper">
        <h2>Polls</h2>
        <Link onClick={ShowCommunityGiveaway}>
          {/* <img src={AddIco} alt="" /> */}
          New Poll
        </Link>
      </div>

      {displayedPollsDetail.length === 0 && !isLoading ? (
        <div className="add-polls-container mb-3">
          <div className="add-polls-item common-card-body">
            <div className="position-relative polls-item-head">
              <h6 className="add-value font-hidden">Heading 2</h6>
              <button
                type="button"
                className="add-item-btn"
                onClick={ShowCommunityGiveaway}
              >
                <img src={Plus} alt="add poll" />
              </button>
            </div>
            <div className="polls-item d-flex align-items-center justify-content-between">
              <div className="polls-item-txt add-value">
                <p className="font-hidden">Poll Item Text 1</p>
              </div>
              <h5 className="add-value font-hidden">Poll Item 1</h5>
            </div>
            <div className="polls-item d-flex align-items-center justify-content-between">
              <div className="polls-item-txt add-value">
                <p className="font-hidden">Poll Item Text 2</p>
              </div>
              <h5 className="add-value font-hidden">Poll Item 2</h5>
            </div>
            <div className="polls-item d-flex align-items-center justify-content-between">
              <div className="polls-item-txt add-value">
                <p className="font-hidden">Poll Item Text 3</p>
              </div>
              <h5 className="add-value font-hidden">Poll Item 3</h5>
            </div>
            <div className="polls-item d-flex align-items-center justify-content-between">
              <div className="polls-item-txt add-value">
                <p className="font-hidden">Poll Item Text 4</p>
              </div>
              <h5 className="add-value font-hidden">Poll Item 4</h5>
            </div>
          </div>
        </div>
      ) : isLoading ? (
        <div
          className="polls-item common-card-body position-relative"

        >
          <div className="">
            <Skeleton width={50} height={25} />
          </div>
          <div className="poll-header">
            <p className="poll_topic"><Skeleton width={600} height={30} /></p>
            <div className="discussion-group">
              <Skeleton width={25} height={25} />
            </div>
            <div className="discussion-comment">
              <Skeleton width={25} height={25} />
              <Skeleton width={25} />
            </div>
            <div className="discussion-button">
              <button type="button">
                <Skeleton width={10} height={35} />
              </button>
            </div>
          </div>

          <div className="polls-option mb-2">
            <p className="profress-line border border-0 shadow-none rounded-0"><Skeleton width={850} height={32} /></p>
            <div className="discussion-comment count-vote-poll-skeleton">
              <Skeleton circle width={25} height={25} />
              <Skeleton width={50} />
            </div>
          </div>
          <div className="polls-option mb-2">
            <p className="profress-line border border-0 shadow-none rounded-0"><Skeleton width={850} height={32} /></p>
            <div className="discussion-comment count-vote-poll-skeleton">
              <Skeleton circle width={25} height={25} />
              <Skeleton width={50} />
            </div>
          </div>
          <div className="polls-option mb-2">
            <p className="profress-line border border-0 shadow-none rounded-0"><Skeleton width={850} height={32} /></p>
            <div className="discussion-comment count-vote-poll-skeleton">
              <Skeleton circle width={25} height={25} />
              <Skeleton width={50} />
            </div>
          </div>
          <div className="polls-option mb-2">
            <p className="profress-line border border-0 shadow-none rounded-0"><Skeleton width={850} height={32} /></p>
            <div className="discussion-comment count-vote-poll-skeleton">
              <Skeleton circle width={25} height={25} />
              <Skeleton width={50} />
            </div>
          </div>

        </div>
      ) : (
        displayedPollsDetail.map((item, index) => {
          const totalVotes =
            item.option_1_vote +
            item.option_2_vote +
            item.option_3_vote +
            item.option_4_vote

          const optionPercentages = [
            ((item.option_1_vote / totalVotes) * 100).toFixed(2),
            ((item.option_2_vote / totalVotes) * 100).toFixed(2),
            ((item.option_3_vote / totalVotes) * 100).toFixed(2),
            ((item.option_4_vote / totalVotes) * 100).toFixed(2)
          ]

          return (
            <div
              className="polls-item common-card-body position-relative"
              key={item.tag}
            >
              <div className="polls-tag">
                <span>
                  {/* {item.tag} */}
                  Web Design
                </span>
              </div>
              <div className="poll-header">
                <p className="poll_topic">{item.topic}</p>
                <div className="comment_people">
                  <div className="discussion-group">
                    {item?.total_votes > 0 &&
                    voteList
                      .slice(0, 3)
                      .map((vote, index) => (
                        <img
                          key={index}
                          src={s3ImagePath + vote.profile_icon}
                          alt={vote.user_name}
                        />
                      ))}
                    <img
                      src={GroupDiscussion}
                      alt=""
                      onClick={() => {
                      if (item.total_votes > 0) {
                        showList(item?.poll_id)
                      }
                    }}
                    />
                    {ShowProfileModel && (
                    <div className="add-item-model edit-profile">
                      <div className="add-item-content" ref={componentRef}>
                        <div className="d-flex justify-content-between header">
                          <h3>Voting List</h3>
                          <button
                            type="button"
                            className="border-0 bg-none p-0"
                            onClick={close}
                          >
                            <span>
                              <img className="close-btns" src={CloseIco} alt="" />
                            </span>
                          </button>
                        </div>

                        <div className="scroll-bars">
                          <div className="item-details-input pt-0">
                            <div className="list_data">
                              {voteListData.map((list, index) => {
                                const calculatedIndex =
                                  (currentPage - 1) * videosPerPage + index + 1
                                return (
                                  <div
                                    className="d-flex justify-content-start list_table"
                                    key={index}
                                  >
                                    <span className="number_index">
                                      {calculatedIndex}
                                    </span>
                                    <img
                                      src={s3ImagePath + list.profile_icon}
                                      className="profile-pic"
                                      alt="profile"
                                    />
                                    <span className="user-name">
                                      {list.user_name}
                                    </span>
                                  </div>
                                )
                              })}
                            </div>
                          </div>
                        </div>
                        {totalPages > 1 && (
                        <Pagination
                          currentPage={currentPage}
                          totalPages={totalPages}
                          onPageChange={handlePageChange}
                        />
                        )}
                      </div>
                    </div>
                  )}
                  </div>
                  <div className="discussion-comment">
                    <img className="poll_comment_icon" src={Comment} alt="" />
                    {item.total_votes}
                  </div>
                  <div className="discussion-button">
                    <button type="button">
                      <img src={IconsMore} alt="" />
                    </button>
                  </div>
                </div>
              </div>

              {[item.option_1, item.option_2, item.option_3, item.option_4]
                .filter((option) => option !== '')
                .map((option, optIndex) => (
                  <div
                    className="polls-option"
                    key={optIndex}
                    onClick={() => {
                      if (item.is_vote === 0) {
                        voteAdd(item.poll_id, optIndex + 1)
                      }
                    }}
                  >
                    <ProgressBar
                      customLabel={`${option}`}
                      className="profress-line"
                      baseBgColor="linear-gradient(134deg, rgba(0, 21, 151, 0.1) 3.03%, rgba(1, 16, 77, 0.1) 47.1%, rgba(2, 12, 28, 0.1) 80.14%, rgba(2, 11, 9, 0.1) 96.45%)"
                      height={43}
                      completed={optionPercentages[optIndex]}
                      borderRadius={15}
                      bgColor={
                        item.total_votes > 0
                          ? 'linear-gradient(134deg, rgba(0, 21, 151, 1) 3.03%, rgba(1, 16, 77, 1) 47.1%, rgba(2, 12, 28, 1) 80.14%, rgba(2, 11, 9, 1) 96.45%)'
                          : 'linear-gradient(134deg, rgba(0, 21, 151, 0.1) 3.03%, rgba(1, 16, 77, 0.1) 47.1%, rgba(2, 12, 28, 0.1) 80.14%, rgba(2, 11, 9, 0.1) 96.45%)'
                      }
                      isLabelVisible={false}
                      labelClassName={
                        item[`option_${optIndex + 1}_vote`] > 0
                          ? 'active_label'
                          : 'polls-label'
                      }
                    />

                    <div className="polls-group">
                      <div className="discussion-group">
                        {item?.total_votes > 0 &&
                          voteList
                            .filter((vote) => vote.option === optIndex + 1)
                            .slice(0, 3)
                            .map((vote, index) => (
                              <img
                                key={index}
                                src={s3ImagePath + vote.profile_icon}
                                alt={vote.user_name}
                              />
                            ))}
                        <img src={GroupDiscussion} alt="Group Discussion" />
                      </div>
                      {item[`option_${optIndex + 1}_vote`]}
                      {' '}
                      votes
                    </div>
                  </div>
                ))}
              {item.app_data.length > 0 && (
                <span
                  className={`platform-badge ${
                    item.app_data.length === 2 ? 'platform-badge-alt' : ''
                  }`}
                >
                  {item.app_data.map((data, i) => (
                    <div
                      key={i}
                      className={`badge-cover ${
                        item.app_data.length === 2 ? 'badge-cover-alt' : ''
                      }`}
                      style={{
                        backgroundImage: `url(${urlSystemIcon + data.icon})`,
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: '90%'
                      }}
                    />
                  ))}
                </span>
              )}
              <div className="image-buttons z-1">
                <label>
                  <button
                    type="button"
                    onClick={() => onClickEditPoll(item)}
                    className="d-none"
                  />
                </label>
                <button
                  type="button"
                  className="p-0"
                  onClick={() => {
                    setUpdatingIndex(index)
                    ShowConfirm(item.poll_id)
                  }}
                />
              </div>
              <ConfirmationModal
                isVisible={ShowConfirmModel && updatingIndex === index}
                onConfirm={deletePoll}
                onCancel={handleCancel}
                message="Do you want to delete this?"
                isLoading={loading}
              />
              {loading && updatingIndex === index && <Loader />}
            </div>
          )
        })
      )}
      {ShowPollModel && (
        <div className="add-item-model">
          <div className="add-item-content" ref={componentRef}>
            <div className="d-flex justify-content-between header">
              <h3>Enter Poll Details</h3>
              <button
                type="button"
                className="border-0 bg-none p-0"
                onClick={HandleCancel}
              >
                <span>
                  <img className="close-btns" src={CloseIco} alt="" />
                </span>
              </button>
            </div>
            <div className="item-details-input pt-0">
              <div className="scroll-bar pb-0">
                <div>
                  <label>Topic Statement</label>
                  <CommonInput
                    name="topic"
                    placeholder="Type here"
                    onChange={handleChange}
                    className="mb-3"
                    maxLength={200}
                    value={capitalizeFirstLetter(inputFields.topic)}
                  />
                </div>
                <div className="input-grid">
                  <div>
                    <label>Select Category</label>
                    <Dropdown
                      title={getCategoryName(inputFields.category_id)}
                      onChanged={(d) => {
                        SubCategory({
                          category_id: d.id
                        })
                        setInputFields({
                          ...inputFields,
                          category_id: d.id
                        })
                      }}
                      Data={category.map((d) => ({
                        title: d.category_name,
                        id: d.id
                      }))}
                      name="category_id"
                      // customClass={
                      //   inputFields.category_id
                      //     ? 'selected-class'
                      //     : 'giveaway-dropdown'
                      // }
                      customClass="store_add_dropdown"
                    />
                  </div>
                  <div>
                    <label>Select Sub-category</label>
                    <Dropdown
                      title={getSubCategoryNameById(
                        inputFields.sub_category_id
                      )}
                      onChanged={(d) => setInputFields({
                          ...inputFields,
                          sub_category_id: d.id
                        })}
                      Data={subCategory.map((d) => ({
                        title: d.sub_category_name,
                        id: d.sub_category_id
                      }))}
                      name="sub_category_id"
                      // customClass={
                      //   inputFields.sub_category_id
                      //     ? 'selected-class'
                      //     : 'giveaway-dropdown'
                      // }
                      customClass="store_add_dropdown"
                    />
                  </div>
                </div>
                {nonEmptySteps.map((enteredStep, index) => (
                  <div key={index} className="mt-4 d-flex recipies-step">
                    <label className="label-poll">
                      Option
                      {index + 1}
                      :
                    </label>
                    {editIndex === index ? (
                      <div className="d-flex align-items-center position-relative">
                        <CommonInput
                          onChange={(e) => setEditValue(e.target.value)}
                          maxLength={70}
                          value={editValue}
                        />
                        <div className="image-buttons z-1">
                          <label>
                            <button
                              type="button"
                              onClick={handleSaveEditStep}
                              className="d-none"
                            />
                          </label>
                        </div>
                      </div>
                    ) : (
                      <div className="text-cancel-btn">
                        <span onClick={() => handleEditStep(index)}>
                          {enteredStep}
                        </span>
                        <button
                          type="button"
                          className="remove-tag ml-5"
                          onClick={() => handleRemoveStep(index)}
                        >
                          <img src={Remove} alt="" />
                        </button>
                      </div>
                    )}
                  </div>
                ))}
                {nonEmptySteps.length < 4 && (
                  <div className="mt-4">
                    <label>
                      Option
                      {nonEmptySteps.length + 1}
                    </label>
                    <CommonInput
                      placeholder="Type here..."
                      className="mt-2"
                      onChange={handleInputChangeStep}
                      maxLength={70}
                      value={step}
                    />
                  </div>
                )}
                {nonEmptySteps.length < 4 && (
                  <button
                    type="button"
                    className="primary-btn-model add_btn"
                    onClick={handleAddStep}
                  >
                    <img src={plusBtn} className="me-2" alt="Add" />
                    Add Poll Option
                  </button>
                )}
              </div>
            </div>
            <div className="item-details-input pt-0">
              <div className="button-row video_sticky_btn">
                <CommonButton
                  label="Back"
                  type="button"
                  onClick={HandleCancel}
                  isPrimary={false}
                  context="Profile"
                />
                <CommonButton
                  label="Preview"
                  type="button"
                  isPrimary
                  context="Profile"
                  disabled={!isPublishEnabled}
                  onClick={HandlePollsPreview}
                />
              </div>
            </div>
          </div>
        </div>
      )}
      {ShowPollPreview && (
        <div className="add-item-model">
          <div
            className="add-item-content d-flex flex-column"
            ref={componentRef}
          >
            <div className="d-flex justify-content-between header">
              <h3>Enter Poll Details</h3>
              <button
                type="button"
                className="border-0 bg-none p-0"
                onClick={HandleCancel}
              >
                <span>
                  <img className="close-btns" src={CloseIco} alt="" />
                </span>
              </button>
            </div>
            <div className="scroll-bar pb-0">
              <div className="item-details-input pt-0">
                <div className="polls-publish-detail">
                  <span className="polls-label-publish">
                    <span>Web Design</span>
                  </span>
                  <p className="polls-text-publish">{inputFields.topic}</p>
                  {steps
                    .filter((poll) => poll !== '')
                    .map((poll) => (
                      <div className="polls-option w-100" key={poll.index}>
                        <CommonInput
                          type="checkbox"
                          className="m-0"
                        />
                        <ProgressBar
                          customLabel={poll}
                          className="profress-line"
                          baseBgColor="#fff"
                          height={43}
                          completed={poll.percantage}
                          borderRadius={15}
                          bgColor="linear-gradient(120deg, rgba(0, 203, 164, 0.1) 0%, rgba(0, 145, 126, 0.1) 90.19%)"
                          isLabelVisible={false}
                          labelClassName="polls-label"
                        />
                      </div>
                    ))}
                </div>
                <PlatformTag
                  setPlatformTags={setPlatformTags}
                  Platformtags={Platformtags}
                  onItemSelect={(value) => getSelectedAppId(value)}
                />
              </div>
            </div>
            <div className="item-details-input pt-0">
              <div className="button-row video_sticky_btn">
                <CommonButton
                  label="Cancel"
                  type="button"
                  onClick={HandleCancel}
                  isPrimary={false}
                  disabled={loading}
                  context="Profile"
                />
                <CommonButton
                  label={isAppsSelected ? 'Publish' : 'Upload'}
                  loading={loading}
                  type="button"
                  onClick={publishPoll}
                  className={`primary-btn-model ${loading ? 'loading' : ''}`}
                  isPrimary
                  context="Profile"
                  disabled={loading}
                />
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="link_view">
        <Link onClick={handleViewAll}>
          {showAll ? 'Show Less' : 'View All'}
        </Link>
      </div>
    </div>
  )
}

export default Polls
