import React from 'react'

const CommonInput = ({
  type = 'text',
  name,
  placeholder,
  value,
  onChange,
  className = '',
  style = {},
  isTextArea = false,
  maxLength,
  accept,
  pattern,
  ref,
  onKeyPress,
  allowTextOnly = false,
  autocomplete = 'off',
  onBlur,
  ...rest
}) => {
  const handleChange = (e) => {
    const { value } = e.target

    if (allowTextOnly) {
      if (/^[A-Za-z\s]*$/.test(value)) {
        onChange(e)
      }
    } else {
      onChange(e)
    }
  }
  if (isTextArea) {
    return (
      <textarea
        name={name}
        placeholder={placeholder}
        value={value}
        onChange={handleChange}
        className={`common-input ${className}`}
        style={style}
        maxLength={maxLength}
        {...rest}
      />
    )
  }

  return (
    <input
      type={type}
      name={name}
      ref={ref}
      accept={accept}
      placeholder={placeholder}
      value={value}
      onChange={handleChange}
      maxLength={maxLength}
      className={`common-input ${className}`}
      style={style}
      pattern={pattern}
      onBlur={onBlur}
      autoComplete={autocomplete}
      // onKeyPress={onKeyPress}
      onKeyPress={(e) => {
        if (allowTextOnly) {
          const charCode = e.which || e.keyCode
          const charStr = String.fromCharCode(charCode)
          if (!/^[A-Za-z\s]*$/.test(charStr)) {
            e.preventDefault()
          }
        }

        if (onKeyPress) {
          onKeyPress(e)
        }
      }}
      {...rest}
    />
  )
}

export default CommonInput
