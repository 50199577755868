/* eslint-disable react/no-unescaped-entities */
import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import SideBarPage from './SidebarPage'
import eyeoff from '../../images/eye-off.svg'
import eyeon from '../../images/eye-on.svg'
import { LoginService } from '../../services/Service'

import { dataDecrypt } from '../../services/http-services'

import '../../App.css'
import CommonButton from '../ButtonComponent/ButtonCommon'
import CommonInput from '../InputComponet/CommonInput'

const SignInPage = () => {
  const navigate = useNavigate()
  const [showPassword1, setShowPassword1] = useState(false)
  const [isButtonEnabled, setIsButtonEnabled] = useState(false)
  const [loading, setLoading] = useState(false)
  const [inputFields, setInputFields] = useState({
    email: '',
    password: '',
    app_id: 2,
    is_gbn: false
  })
  const [errors, setErrors] = useState({})
  const [submitting, setSubmitting] = useState(false)

  const togglePasswordVisibility1 = () => {
    setShowPassword1(!showPassword1)
  }

  const validateValues = (inputValues) => {
    const errors = {}
    if (inputValues.email.length === 0) {
      errors.email = 'Please enter username'
    }
    if (inputValues.password.length === 0) {
      errors.password = 'Please enter password'
    }
    return errors
  }

  const handleChange = (e) => {
    setInputFields({ ...inputFields, [e.target.name]: e.target.value })
    setIsButtonEnabled(
      inputFields.email.length > 0 && inputFields.password.length > 0
    )
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    setErrors(validateValues(inputFields))
    setSubmitting(true)
  }

  const finishSubmit = () => {
    setLoading(true)
    LoginService(inputFields).then((res) => {
      if (res?.data?.success) {
        if (res.data.token) {
          localStorage.setItem('token', res.data.token)
        }
        localStorage.setItem(
          'userData',
          JSON.stringify(dataDecrypt(res.data.data))
        )
        const decryptedData = dataDecrypt(res.data.data)
        setLoading(false)
        if (decryptedData.send_otp) {
          navigate('/otp-verification')
        } else if (decryptedData.business_profile === false) {
          navigate('/user-information')
        } else if (decryptedData.user_type_id === 0) {
          navigate('/create-profile')
        } else {
          navigate('/dashboard')
        }
        toast.success(res?.data?.message)
      } else {
        toast.error(res?.data?.message)
        setLoading(false)
      }
    })
  }

  useEffect(() => {
    if (Object.keys(errors).length === 0 && submitting) {
      finishSubmit()
    }
  }, [errors])

  useEffect(() => {
    setIsButtonEnabled(inputFields.email.length > 0 && inputFields.password.length > 0)
  }, [inputFields])

  return (
    <div className=" auth-register auth-login">
      <div className="bg-overlay row overlay-height">
        <div className="col-md-6 bg-primary-gredient">
          <SideBarPage />
        </div>
        <div className="col-md-6">
          <div className="d-flex align-items-center justify-content-center h-100">
            <div className="w-100 inline-padding">
              <h2>WELCOME BACK!</h2>
              <form onSubmit={handleSubmit}>
                <p>
                  <label className="clip-text">APP WORLD ONE ID *</label>
                  <div className="user-input">
                    <CommonInput
                      name="email"
                      placeholder="User name"
                      value={inputFields.email}
                      onChange={handleChange}
                      autocomplete="on"
                    />
                  </div>
                  {errors.email ? (
                    <p className="error-text">{errors.email}</p>
                  ) : null}
                </p>

                <p>
                  <label className="clip-text">Password *</label>
                  <div className="password-input">
                    <CommonInput
                      type={showPassword1 ? 'text' : 'password'}
                      name="password"
                      value={inputFields.password}
                      placeholder="*********************"
                      onChange={handleChange}
                      className="text_password"
                    />
                    <button onClick={togglePasswordVisibility1} type="button">
                      {showPassword1 ? (
                        <img alt="" src={eyeon} />
                      ) : (
                        <img alt="" src={eyeoff} />
                      )}
                    </button>
                  </div>
                  {errors.password ? (
                    <p className="error-text">{errors.password}</p>
                  ) : null}
                </p>
                <p className="text-end">
                  <Link to="/forget-password" className="clip-text">
                    Forgotten Password?
                  </Link>
                </p>
                <p>
                  <CommonButton
                    label="Log in"
                    loading={loading}
                    type="submit"
                    className="primary-button m-auto"
                    disabled={!isButtonEnabled || loading}
                    context="primary"
                    isPrimary
                  />
                </p>
              </form>
              <footer>
                <p>
                  Don't have a business account?
                  {' '}
                  <Link to="/register">Sign up</Link>
                </p>
              </footer>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default SignInPage
