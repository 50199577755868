/* eslint-disable no-unsafe-optional-chaining */
import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import SideBarPage from './SidebarPage'
import eyeoff from '../../images/eye-off.svg'
import eyeon from '../../images/eye-on.svg'
import StarIco from '../../images/StarIco.svg'
import CommonInput from '../InputComponet/CommonInput'
import CommonButton from '../ButtonComponent/ButtonCommon'
import { LoginService, s3ImagePath } from '../../services/Service'

const ReLogin = () => {
  const navigate = useNavigate()
  const userData = JSON.parse(localStorage.getItem('userData'))
  const [showPassword1, setShowPassword1] = useState(false)
  const [loading, setLoading] = useState(false)
  const [inputFields, setInputFields] = useState({
    email: '',
    password: '',
    app_id: 2,
    is_gbn: false
  })
  const [isButtonEnabled, setIsButtonEnabled] = useState(false)

  const divStyle = {
    background: `url(${s3ImagePath + userData?.profile_image || ''}) lightgray 50% / cover no-repeat`,
    backgroundSize: 'cover',
    backgroundPosition: 'center'
  }

  const togglePasswordVisibility1 = () => {
    setShowPassword1(!showPassword1)
  }

  const handleChange = (e) => {
    setInputFields({ ...inputFields, [e.target.name]: e.target.value })
    setIsButtonEnabled(
      inputFields.password.length > 0
    )
  }

  const loginApi = (e) => {
    e.preventDefault()
    setLoading(true)
    const data = {
      email: userData?.email,
      password: inputFields.password,
      app_id: 2,
      is_gbn: false
    }
    LoginService(data).then((res) => {
      if (res.data.success) {
        localStorage.setItem('token', res.data.token)
        navigate('/dashboard')
        setLoading(false)
        toast.success(res?.data?.message)
      } else {
        setLoading(false)
        toast.error(res?.data?.message)
      }
    })
  }

  const onClickSwitch = (e) => {
    e.preventDefault()
      localStorage.clear()
      navigate('/login')
      // toast.success('User logged out successfully')
  }

  useEffect(() => {
    setIsButtonEnabled(inputFields.password.length > 0)
  }, [inputFields])

  return (
    <div className=" auth-register">
      <div className=" bg-overlay row overlay-height">
        <div className="col-md-6 bg-primary-gredient">
          <SideBarPage />
        </div>
        <div className="col-md-6 h-100 overflow-y-auto">
          <div className="inline-padding mt-xl-5 pt-5">
            <h2 className="mb-5 mt-xl-5 text-center">
              HELLO,
              {' '}
              {userData?.full_name}
              !
            </h2>
            <div className="login-img" style={divStyle} />
            <div className="profile-full-name text-center">
              <p className="m-0 clip-text">
                {userData?.user_name}
                <span>
                  <img src={StarIco} alt="" />
                </span>
              </p>
              <Link className="clip-text" onClick={(e) => onClickSwitch(e)}>Switch Profile</Link>
            </div>
            <form action="/dashboard">
              <p>
                <label className="clip-text">Re-enter Password *</label>
                <div className="password-input">
                  <CommonInput
                    type={showPassword1 ? 'text' : 'password'}
                    name="password"
                    placeholder="*********************"
                    required
                    onChange={handleChange}
                  />
                  <button onClick={togglePasswordVisibility1} type="button">
                    {showPassword1 ? (
                      <img alt="" src={eyeon} />
                    ) : (
                      <img alt="" src={eyeoff} />
                    )}
                  </button>
                </div>
              </p>
              <p className="mt-5">
                {/* <button
                  id="sub_btn"
                  className="primary-button m-auto"
                  type="submit"
                >
                  <span>Log in</span>
                </button> */}
                <CommonButton
                  label="Log in"
                  type="button"
                  id="sub_btn"
                  loading={loading}
                  onClick={(e) => loginApi(e)}
                  disabled={!isButtonEnabled || loading}
                  className="primary-button m-auto"
                  context="primary"
                  isPrimary
                />
              </p>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}
export default ReLogin
