/* eslint-disable eqeqeq */
/* eslint-disable no-useless-escape */
import React, { useState, useEffect, useMemo } from 'react'
import './createprofile.css'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import Skeleton from 'react-loading-skeleton'
import info from '../../images/info.svg'
import cancel from '../../images/ic_baseline-cancel.svg'
import SelectImg from '../../images/selectimg.svg'
import 'cropperjs/dist/cropper.css'
import plusgredient from '../../images/plus-gredient.svg'
import {
  getUserTypes,
  getApplications,
  getAddOns,
  s3ImagePath,
  imageUpload,
  getOrganizationTypes,
  createProfile,
  urlSystemIcon
} from '../../services/Service'
import { dataDecrypt } from '../../services/http-services'
import Dropdown from '../Dropdown/Dropdown'
import plusrounded from '../../images/plus-white.svg'
import CommonImageUpload from '../ImageCroper/StoreImageCroper'
import CommonButton from '../ButtonComponent/ButtonCommon'
import CommonInput from '../InputComponet/CommonInput'
import Remove from '../../images/remove.svg'
import useInfo from '../../images/createprofile_dummy.svg'
import plusimg from '../../images/plusimg.svg'
import Loader from '../Loader/Loader'

const CreateProfile = () => {
  const navigate = useNavigate()
  const [appData, setAppData] = useState([])
  const [certificateUrl, setCertificateUrl] = useState([])
  const [addOnsData, setAddOnsData] = useState([])
  const [userTypesData, setUserTypesData] = useState([])
  const [icon, setIcon] = useState([])
  const urlRegex =
    /^(https?|ftp):\/\/(([a-z\d]([a-z\d-]*[a-z\d])?\.)+[a-z]{2,}|localhost)(\/[-a-z\d%_.~+]*)*(\?[;&a-z\d%_.~+=-]*)?(\#[-a-z\d_]*)?$/i
  const [userTypeId, setUserTypesId] = useState([])
  const [organizationData, setOrganizationData] = useState([])
  const [verificationData, setVerificationData] = useState({
    description: '',
    website_link: '',
    certificate: '',
    local_address: '',
    local_address_image: [],
    store_link: '',
    organization_type: '',
    title: ''
  })
  const [iconImg, setIconImg] = useState(null)
  const [iconObj, setIconObj] = useState(null)
  const [errors, setErrors] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [isAppLoading, setIsAppLoading] = useState(false)
  const [isAddonsLoading, setIsAddonsLoading] = useState(false)
  const [currentStep, setCurrentStep] = useState(1)
  const [selctedItem, setSelctedItem] = useState()
  const [socialMediaLinks, setSocialMediaLinks] = useState([])
  const [isImageLoading, setIsImageLoading] = useState({
    index: null,
    loading: false
  })
  const [draggedIndex, setDraggedIndex] = useState(null)

  const socialMediaOptions = [
    { img: './images/socialicon/youtube.png', name: 'youtube' },
    { img: './images/socialicon/twiter.png', name: 'twiter' },
    { img: './images/socialicon/facebook.png', name: 'facebook' },
    { img: './images/socialicon/instagram.png', name: 'instagram' },
    { img: './images/socialicon/twitch.png', name: 'twitch' },
    { img: './images/socialicon/tiktok.png', name: 'tiktok' },
    { img: './images/socialicon/linkedin.png', name: 'linkedin' }
  ]
  // const [selectedImages, setSelectedImages] = useState([])

  const getOrganizationData = async () => {
    getOrganizationTypes().then((res) => {
      if (res.data.success) {
        setOrganizationData(dataDecrypt(res.data.data))
      }
    })
  }

  const handleChange = (e) => {
    console.log(e.target.value)
    setVerificationData({
      ...verificationData,
      [e.target.name]: e.target.value
    })
  }

  const getUserTypeData = async () => {
    setIsLoading(true)
    getUserTypes().then((res) => {
      if (res.data.success) {
        console.log(dataDecrypt(res.data.data))
        if (
          JSON.parse(localStorage.getItem('userData')).user_type_id &&
          JSON.parse(localStorage.getItem('userData')).user_type_id !== 0
        ) {
          const UserTypeName = dataDecrypt(res.data.data).filter(
            (item) => item.id ==
              JSON.parse(localStorage.getItem('userData')).user_type_id
          )[0].name
          setSelctedItem(UserTypeName)
          getAddonsData(UserTypeName)
        }
        setUserTypesData(dataDecrypt(res.data.data))
        setIsLoading(false)
      }
    })
  }

  const getAddonsData = async (name) => {
    setIsAddonsLoading(true)
    getAddOns().then((res) => {
      if (res.data.success) {
        const data = dataDecrypt(res.data.data)
        if (name == 'Creator') {
          data.find((o) => o.name == 'Entertainment').checked = true
          data.find((o) => o.name == 'Entertainment').disabled = true
        } else if (name == 'Store') {
          data.find((o) => o.name == 'Store').checked = true
          data.find((o) => o.name == 'Store').disabled = true
        } else if (name == 'Local') {
          data.find((o) => o.name == 'Local').checked = true
          data.find((o) => o.name == 'Local').disabled = true
        } else if (name == 'Expert') {
          data.find((o) => o.name == 'Guide').checked = true
          data.find((o) => o.name == 'Guide').disabled = true
        }
        console.log(data)
        setAddOnsData(data)
        setIsAddonsLoading(false)
      }
    })
  }

  const getApplicationsData = async () => {
    setIsAppLoading(true)
    getApplications().then((res) => {
      if (res.data.success) {
        setAppData(dataDecrypt(res.data.data))
        setIsAppLoading(false)
      }
    })
  }

  const clearValue = () => {
    setVerificationData({
      ...verificationData,
      description: '',
      website_link: '',
      certificate: '',
      local_address: '',
      local_address_image: [],
      store_link: '',
      organization_type: '',
      title: ''
    })
    setIconObj(null)
    setIconImg(null)
  }

  const handleSelection = (data) => {
    let select = []
    if (!select.includes(data.name)) {
      select.push(data.name)
    } else {
      select = select.filter((item) => item !== data.name)
    }
    setSelctedItem(select)
    setUserTypesId(data.id)
    getAddonsData(select)
    clearValue()
  }

  const nextStep = () => {
    console.log(verificationData)
    setErrors('')
    if (verificationData.store_link !== '') {
      if (!urlRegex.test(verificationData.store_link)) {
        setErrors('URL format is not valid')
        return false
      }
    } else if (socialMediaLinks.length > 0) {
      const allTrue = socialMediaLinks.every((obj) => urlRegex.test(obj?.link))
      if (!allTrue) {
        setErrors('URL format is not valid')
        return false
      }
    }
    setCurrentStep(currentStep + 1)
  }

  const prevStep = () => {
    setCurrentStep(currentStep - 1)
  }

  const handleInputChange = (index, value, img) => {
    const updatedLinks = [...socialMediaLinks]
    updatedLinks[index] = {
      ...updatedLinks[index],
      link: value,
      img
    }
    setSocialMediaLinks(updatedLinks)
  }

  const addInputField = () => {
    if (socialMediaLinks.length < 7) {
      console.log({
        img: socialMediaOptions[socialMediaLinks.length]?.img,
        link: ''
      })
      setSocialMediaLinks([
        ...socialMediaLinks,
        { img: socialMediaOptions[socialMediaLinks.length]?.img, link: '' }
      ])
    }
  }

  const removeInputField = (index) => {
    const updatedLinks = socialMediaLinks.filter((_, i) => i !== index)
    setSocialMediaLinks(updatedLinks)
  }

  const maxFileSizeMB = 10
  const maxFileSizeBytes = maxFileSizeMB * 1024 * 1024

  const handleImageUpload = async (file) => {
    if (!file) return

    if (file.size > maxFileSizeBytes) {
      toast.error(`File size exceeds ${maxFileSizeMB} MB`)
      return
    }

    try {
      const imgUrl = URL.createObjectURL(file)
      setIconImg(imgUrl)
      setIconObj(file)
      const res = await imageUpload(file)
      if (res.data.success) {
        const decryptedUrl = dataDecrypt(res.data.data)
        setIcon(decryptedUrl)
      } else {
        toast.error('Image upload failed.')
      }
    } catch (error) {
      toast.error('Error occurred during image upload.')
    }
  }

  const handleCertificateUpload = (e) => {
    const file = e.target.files[0]

    if (file) {
      imageUpload(file).then((res) => {
        if (res.data.success) {
          setCertificateUrl(s3ImagePath + dataDecrypt(res.data.data))
        }
      })
    }
  }

  const handleImageChange = async (file) => {
    if (file) {
      const reader = new FileReader()
      reader.onload = async () => {
        try {
          const uploadResponse = await imageUpload(file)
          if (!uploadResponse.data.success) {
            throw new Error(
              uploadResponse.data.message || 'Image upload failed'
            )
          }
          const decryptedImageData = dataDecrypt(uploadResponse.data.data)
          setVerificationData((prev) => ({
            ...prev,
            local_address_image: [
              ...prev.local_address_image,
              decryptedImageData
            ]
          }))
        } catch (error) {
          console.error('Error uploading image:', error)
        }
      }
      reader.readAsDataURL(file)
    }
  }

  const createProfileClick = () => {
    const app_id = appData.filter(({ checked }) => checked).map((e) => e.id)
    const add_ons = addOnsData
      .filter(({ checked }) => checked)
      .map((e) => e.id)
    const data = {
      user_type_id: userTypeId,
      verification_data: {
        ...verificationData,
        social_media: socialMediaLinks.map((d) => d.link)
      },
      app_id,
      icon,
      add_ons
    }
    createProfile(data).then((res) => {
      if (res.data.success) {
        const userData = JSON.parse(localStorage.getItem('userData'))
        if (!userData.user_type_id || userData.user_type_id == 0) {
          userData.user_type_id = userTypeId
          localStorage.setItem('userData', JSON.stringify(userData))
        }
        toast.success(res?.data?.message)
        navigate('/dashboard')
      }
    })
  }

  const handleCheckboxChange = (index) => (e) => {
    const newCheckboxes = [...addOnsData]
    newCheckboxes[index].checked = e.target.checked
    setAddOnsData(newCheckboxes)
  }

  const handleCancelClick = (id) => {
    const newCheckboxes = addOnsData.map((addOn) => {
      if (addOn.id == id) {
        return { ...addOn, checked: false }
      }
      return addOn
    })
    setAddOnsData(newCheckboxes)
  }

  const getSelectedAddOns = () => {
    return addOnsData.filter((addOn) => addOn.checked)
  }

  useEffect(() => {
    if (
      JSON.parse(localStorage.getItem('userData')).user_type_id &&
      JSON.parse(localStorage.getItem('userData')).user_type_id !== 0
    ) {
      nextStep()
      setUserTypesId(JSON.parse(localStorage.getItem('userData')).user_type_id)
    }
    getUserTypeData()
    getApplicationsData()
    getOrganizationData()
  }, [])

  const removeImage = (index) => {
    const updatedImages = [...verificationData.local_address_image]
    updatedImages.splice(index, 1)
    setVerificationData((prev) => ({
      ...prev,
      local_address_image: updatedImages
    }))
  }

  const handleDragStart = (index) => {
    setDraggedIndex(index)
  }

  const handleDrop = (index) => {
    if (index !== draggedIndex) {
      setVerificationData((prev) => {
        const copy = [...prev.local_address_image]
        const b = copy[draggedIndex]
        copy[draggedIndex] = copy[index]
        copy[index] = b
        return {
          ...prev,
          local_address_image: copy
        }
      })

      setDraggedIndex(null)
    }
  }

  const handleLoad = (index) => {
    setIsImageLoading({
      index,
      loading: true
    })
    setTimeout(() => {
      setIsImageLoading({
        index: null,
        loading: false
      })
    }, 500)
  }

  const renderImg = useMemo(() => {
    return (
      <>
        {verificationData.local_address_image.map((image, index) => {
          return (
            <div
              className={`image position-relative ${
                index === 0 ? 'first-image' : ''
              }`}
              key={index}
              alt={`image-${index}`}
              draggable
              onDragStart={() => {
                handleDragStart(index)
              }}
              onDragOver={(event) => event.preventDefault()}
              onDragEnter={(event) => event.preventDefault()}
              onDrop={(event) => {
                event.preventDefault()
                handleDrop(index)
              }}
            >
              {isImageLoading.index === index && isImageLoading.loading && (
                <Loader />
              )}
              <img
                className={`image position-relative ${
                  index === 0 ? 'first-image' : ''
                }`}
                src={
                  typeof image === 'string'
                    ? s3ImagePath + image
                    : URL.createObjectURL(image)
                }
                alt={`image-${index}`}
                style={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover'
                }}
                onLoad={() => handleLoad(index)}
              />
              <button type="button" onClick={() => removeImage(index)}>
                <img src={Remove} alt="" />
              </button>
              <button type="button" className="plus-img">
                <CommonImageUpload
                  handleImageChange={(file) => {
                    handleImageChange(file)
                  }}
                  selectedImg=""
                  defaultImg={plusimg}
                  className="plsimg"
                />
              </button>
            </div>
          )
        })}
      </>
    )
  }, [verificationData.local_address_image, handleDragStart])

  const renderContent = () => {
    switch (currentStep) {
      case 1:
        return (
          <div>
            <h1>Create Profile</h1>
            <p className="sub-text-1">
              What kind of a profile you want to create:
            </p>
            <div className="step-1">
              {isLoading ? (
                <div className="d-flex justify-content-between align-items-center">
                  <div>
                    <h3>
                      <Skeleton width={100} />
                    </h3>
                    <p>
                      <Skeleton className="mb-2" width={150} height={20} />
                    </p>
                    <p>
                      <Skeleton width={175} height={20} />
                    </p>
                  </div>
                  <Skeleton width={80} height={80} />
                </div>
              ) : (
                userTypesData.map((i) => (
                  <div
                    key={i.name}
                    className={`d-flex justify-content-between align-items-center hover_create ${
                      selctedItem?.includes(i.name) ? 'selected' : 'selectable'
                    }`}
                    onClick={() => handleSelection(i)}
                  >
                    <div>
                      <h3>{i.name}</h3>
                      <p>{i.description}</p>
                    </div>
                    <img src={urlSystemIcon + i.icon} alt="" />
                  </div>
                ))
              )}
            </div>
            <div className="steps-button">
              <CommonButton
                label="Next"
                type="button"
                onClick={nextStep}
                className="primary-button btn-next"
                disabled={!selctedItem}
                context="primary"
                isPrimary
              />
            </div>
          </div>
        )
      case 2:
        return (
          <div>
            <h1>Create Profile</h1>
            <p className="sub-text-2">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nisi
              ornare turpis duis mi. At vel auctor vivamus morbi elit. Sit risus
              consequat duis sit ultricies at eu volutpat. Sed at lobortis orci
              sem accumsan, lacus tellus feugiat. Odio tempor adipiscing eu sed
              cursus ultricies netus orci.
            </p>
            <div className="step-2">
              {isAppLoading ? (
                <div className="profile-checkbox">
                  <Skeleton width={65} height={65} />
                  <p>
                    <Skeleton width={150} height={20} />
                  </p>
                  <div className="custom-check-box">
                    <Skeleton type="checkbox" width={20} height={20} />
                  </div>
                  <div className="info-button">
                    <Skeleton width={20} height={20} />
                  </div>
                </div>
              ) : (
                appData.map((i, index) => (
                  <div className="profile-checkbox" key={index}>
                    <img src={urlSystemIcon + i.icon} alt="" />
                    <p>{i.description}</p>
                    <div className="custom-check-box">
                      <CommonInput
                        type="checkbox"
                        value={i.id}
                        checked={i.checked}
                        onChange={(e) => {
                          const newCheckboxes = [...appData]
                          newCheckboxes[index].checked = e.target.checked
                          setAppData(newCheckboxes)
                        }}
                      />
                    </div>
                    <div className="info-button">
                      <img src={info} alt="" />
                    </div>
                  </div>
                ))
              )}
            </div>
            <div className="steps-button">
              {!JSON.parse(localStorage.getItem('userData')).user_type_id &&
                JSON.parse(localStorage.getItem('userData')).user_type_id ==
                  0 && (
                  <CommonButton
                    label="Back"
                    type="button"
                    onClick={prevStep}
                    className="btn-prev secondary-btn w-auto"
                  />
                )}
              <CommonButton
                label="Next"
                type="button"
                onClick={nextStep}
                className="primary-button btn-next"
                disabled={appData.filter(({ checked }) => checked).length == 0}
                context="primary"
                isPrimary
              />
            </div>
          </div>
        )
      case 3:
        return (
          <div>
            <div className="">
              <div className="sticky-header">
                <h1>
                  How I use my profile?
                  {' '}
                  <img src={info} alt="" />
                </h1>
                <p className="sub-text-3">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nisi
                  ornare turpis duis mi. At vel auctor vivamus morbi elit. Sit
                  risus consequat duis sit ultricies at eu volutpat. Sed at
                  lobortis orci sem accumsan, lacus tellus feugiat. Odio tempor
                  adipiscing eu sed cursus ultricies netus orci. Erat arcu
                  viverra rhoncus.
                </p>
              </div>
              <div className={`scroll_bars_create_3 ${selctedItem === 'Creator' ? 'scroll_bars_create_3 additional-class' : 'scroll_bars_create_3'}`}>
                <div className="profile-pics mt-4">
                  <div className="profile-pic-images">
                    <CommonImageUpload
                      handleImageChange={handleImageUpload}
                      index={0}
                      forImage="create-profile"
                      className="profile-upload"
                      selectedImg={iconImg}
                      defaultImg={useInfo}
                      defaultImgCamera={SelectImg}
                    />
                  </div>
                </div>

                <div className="item-details-input px-2">
                  <div>
                    <label>User Type</label>
                    <CommonInput
                      value={selctedItem}
                      className="user-type"
                      readOnly
                    />
                  </div>
                  <div>
                    <label>Decription</label>
                    <CommonInput
                      onChange={handleChange}
                      name="description"
                      placeholder="Type here"
                      value={verificationData.description}
                      isTextArea
                    />
                  </div>
                </div>

                {selctedItem == 'Creator' && (
                  <div className="social-media-link-input px-2">
                    <label className="create_profile_add_link">
                      Add Social Media Link
                    </label>
                    {socialMediaLinks.map((link, index) => (
                      <div
                        key={index}
                        className="item-details-input p-0 socialmedia-select"
                      >
                        <Dropdown
                          title=""
                          img={link.img}
                          Data={socialMediaOptions}
                          onChanged={() => {}}
                          customClass="create_profile_link_dropdown"
                        />
                        <div className="input-and-remove">
                          <CommonInput
                            className="mb-2"
                            placeholder="Paste here"
                            value={link.link}
                            onChange={(e) => handleInputChange(
                                index,
                                e.target.value,
                                socialMediaOptions[index].img
                              )}
                          />
                          <button
                            type="button"
                            className="remove-button"
                            onClick={() => removeInputField(index)}
                          >
                            <img src={Remove} alt="" />
                          </button>
                        </div>
                      </div>
                    ))}
                    {errors !== '' && <p className="error-message">{errors}</p>}

                    <div
                      className="add-video-rows"
                      onClick={addInputField}
                      disabled={socialMediaLinks.length >= 7}
                    >
                      <div className="add_new_content">
                        <img src={plusrounded} alt="" />
                        Add another
                      </div>
                    </div>
                  </div>
                )}

                {selctedItem == 'Expert' && (
                  <div className="item-details-input expert-verification px-2">
                    <label>Add Website Link</label>
                    <CommonInput
                      placeholder="Paste here"
                      name="website_link"
                      value={verificationData.website_link}
                      onChange={handleChange}
                      className="mb-2"
                    />
                    <label>Add Certificate</label>
                    <div className="input-with-button mb-2 d-flex">
                      <CommonInput
                        value={certificateUrl}
                        placeholder="Enter image URL or click upload..."
                      />
                      <label
                        htmlFor="upload"
                        className="upload-btn primary-btn"
                      >
                        <span>Upload</span>
                        <CommonInput
                          type="file"
                          id="upload"
                          onChange={handleCertificateUpload}
                          className="primary-button d-none"
                        />
                      </label>
                    </div>
                  </div>
                )}

                {selctedItem == 'Local' && (
                  <div className="item-details-input expert-verification local-verification px-2">
                    <label>Add Website Link</label>
                    <CommonInput
                      placeholder="Paste here"
                      name="website_link"
                      value={verificationData.website_link}
                      onChange={handleChange}
                      className="mb-2"
                    />
                    <label>Add Local Address</label>
                    <CommonInput
                      placeholder="Enter here"
                      name="local_address"
                      value={verificationData.local_address}
                      onChange={handleChange}
                      className="mb-2"
                    />
                    <div>
                      <p className="create_profile_local_add_link">
                        Add images for verification
                      </p>
                      <div className="input-image-show justify-content-start">
                        {renderImg}
                        {verificationData.local_address_image.length < 4 && (
                          <div className="input-img-label">
                            <CommonImageUpload
                              handleImageChange={(file) => handleImageChange(file)}
                              buttonText="Add Image"
                              selectedImg=""
                              defaultImg={plusgredient}
                              className="plsimg"
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )}

                {selctedItem == 'Store' && (
                  <div className="item-details-input social-media-link-input store-verification px-2">
                    <label>Add your store link</label>
                    <CommonInput
                      className="mb-2"
                      placeholder="Paste here"
                      name="store_link"
                      value={verificationData.store_link}
                      onChange={handleChange}
                    />
                    {errors !== '' && <p className="error-message">{errors}</p>}
                  </div>
                )}

                {selctedItem == 'Organization' && (
                  <div className="item-details-input expert-verification organization-verification px-2">
                    <label>Organization Type</label>
                    <div className="custome-select">
                      <Dropdown
                        title={
                          verificationData.organization_type == ''
                            ? organizationData[0].name
                            : verificationData.organization_type
                        }
                        onChanged={(d) => {
                          setVerificationData({
                            ...verificationData,
                            organization_type: d.title
                          })
                        }}
                        name="organization_type"
                        Data={organizationData.map((d) => ({
                          title: d.name,
                          id: d.id
                        }))}
                      />
                    </div>
                    <label>Add Website Link</label>
                    <CommonInput
                      placeholder="Paste here"
                      name="website_link"
                      value={verificationData.website_link}
                      onChange={handleChange}
                      className="mb-2"
                    />
                  </div>
                )}

                {selctedItem == 'Custom' && (
                  <div className="item-details-input expert-verification custome-verification px-2">
                    <label>Add Title</label>
                    <CommonInput placeholder="Type here" className="mb-2" />
                  </div>
                )}
              </div>
              <div className="steps-button sticky-footer mt-0 px-3">
                <CommonButton
                  label="Back"
                  type="button"
                  onClick={prevStep}
                  className="secondary-btn btn-prev-case3 w-auto"
                />
                <CommonButton
                  label="Join"
                  type="button"
                  onClick={nextStep}
                  className="primary-button btn-next-case3"
                  disabled={!iconObj || !verificationData.description}
                  context="primary"
                  isPrimary
                />
              </div>
            </div>
          </div>
        )
      case 4:
        return (
          <div className="pt-0">
            <div className="sticky-header">
              <h1 className="">Create Profile</h1>
              <div className="d-flex align-items-center justify-content-between">
                <p className="sub-text">Choose Add ons:</p>
                <div className="add-ons-label">
                  {getSelectedAddOns().map((addOn) => (
                    <p key={addOn.id}>
                      {addOn.name}
                      <button
                        type="button"
                        onClick={() => handleCancelClick(addOn.id)}
                        disabled={addOn.disabled}
                      >
                        <img src={cancel} alt="" />
                      </button>
                    </p>
                  ))}
                </div>
              </div>
            </div>
            <div className="step-4 scroll-bars_create">
              {isAddonsLoading ? (
                <div className="add-ons">
                  <div className="add-ons-main-ing">
                    <Skeleton width={100} height={100} />
                  </div>
                  <div className="footer-row">
                    <div className="images-block">
                      <Skeleton width={100} height={100} />
                    </div>
                    <div className="add-ons-detail">
                      <h4>
                        <Skeleton max-width={175} />
                      </h4>
                      <p>
                        <Skeleton max-width={175} height={50} />
                      </p>
                    </div>
                  </div>
                  <div className="custome-checkbox">
                    <Skeleton type="checkbox" width={20} height={20} />
                  </div>
                </div>
              ) : (
                addOnsData.map((i, index) => (
                  <div key={index} className="add-ons">
                    <div className="add-ons-main-ing">
                      <img
                        className="add_ons_first"
                        alt=""
                        src={s3ImagePath + i.image}
                      />
                    </div>
                    <div className="footer-row">
                      <div className="images-block">
                        <img
                          className="icon_img"
                          alt=""
                          src={urlSystemIcon + i.icon}
                        />
                      </div>
                      <div className="add-ons-detail">
                        <h4>{i.name}</h4>
                        <p>{i.description}</p>
                      </div>
                    </div>
                    <div className="custome-checkbox">
                      <CommonInput
                        type="checkbox"
                        value={i.id}
                        checked={i.checked}
                        disabled={i.disabled}
                        onChange={handleCheckboxChange(index)}
                      />
                    </div>
                  </div>
                ))
              )}
            </div>
            <div className="steps-button sticky-footer mt-0 px-3">
              <CommonButton
                label="Back"
                type="button"
                onClick={prevStep}
                className="secondary-btn btn-prev-case4 w-auto"
              />
              <CommonButton
                label="Create"
                type="button"
                onClick={createProfileClick}
                className="primary-button btn-next-case4"
                disabled={
                  addOnsData.filter(({ checked }) => checked).length == 0
                }
                context="primary"
                isPrimary
              />
            </div>
          </div>
        )

      default:
        return null
    }
  }

  return (
    <div className="profile-left-wrapper">
      <div
        className={`create-profile ${currentStep === 3 || currentStep === 4 ? 'case-4-active' : ''}`}
      >
        {renderContent()}
      </div>
    </div>
  )
}
export default CreateProfile
