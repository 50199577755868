/* eslint-disable max-len */
/* eslint-disable no-restricted-globals */
import React, { useEffect, useMemo, useRef, useState } from 'react'
import '../HotelComponent/Discount.css'
import './Event.css'
import { Button, Image, Input, Table, TimePicker } from 'antd'
import dayjs from 'dayjs'
import DatePicker from 'react-datepicker'
import moment from 'moment'
import { toast } from 'react-toastify'
import _ from 'lodash'
import PlusIco from '../../../images/PlusIco.svg'
import Dropdown from '../../Dropdown/Dropdown'
import Remove from '../../../images/close-black.svg'
import Event_Logo from '../../../images/local_event_logo.svg'
import Edit from '../../../images/edit_icon.svg'
import Delete from '../../../images/delete_icon.svg'
import CommonImageUpload from '../../ImageCroper/StoreImageCroper'
import CommonInput from '../../InputComponet/CommonInput'
import CommonButton from '../../ButtonComponent/ButtonCommon'
import {
  capitalizeFirstLetter,
  lockScroll,
  unlockScroll
} from '../../../helpers/Utility'
import {
  eventAdd,
  eventDelete,
  eventEdit,
  eventGet,
  eventPerformerAdd,
  eventPerformerDelete,
  eventPerformerEdit,
  imageUploadLocal,
  industryTypeList,
  ParticularEventDetails,
  performerTypeList,
  s3ImagePathLocal
} from '../../../services/Service'
import { compressImage, dataDecrypt } from '../../../services/http-services'
import plusimg from '../../../images/plusimg.svg'
import Loader from '../../Loader/Loader'
import Plus from '../../../images/plus-rounded.svg'
import local_add_img from '../../../images/add_a_photo.svg'
import Pagination from '../../Pagination/Pagination'
import ConfirmationModal from '../../ConfirmationModal/ConfirmationModal'

const Event = ({ isOrganizerId }) => {
  const [isAddDiscount, setIsAddDiscount] = useState(false)
  const [isRoomFeature, setIsRoomFeature] = useState(false)
  const [isAddRoom, setIsAddRoom] = useState(false)
  const [searchText, setSearchText] = useState('')
  const [inputFields, setInputFields] = useState({
    name: '',
    industry_type_id: '',
    performer_type_id: '',
    start_time: '',
    end_time: '',
    min_price: '',
    max_price: '',
    tickets_qty: '',
    date: '',
    description: '',
    images: [],
    website: '',
    latitude: 51.1173926,
    longitude: 9.6874674,
    event_performer: [],
    contact_name1: '',
    contact_profile_pic: [],
    organizer_id: isOrganizerId,
    event_id: '',
    performer_id: ''
  })
  const [isIndustryType, setIsIndustryType] = useState([])
  const [isPerformerType, setIsPerformerType] = useState([])
  const [isImageLoading, setIsImageLoading] = useState({
    index: null,
    loading: false
  })
  const [draggedIndex, setDraggedIndex] = useState(null)
  const [personDetails, setPersonDetails] = useState(false)
  const [eventListData, setEventListData] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const [delEventId, setDelEventId] = useState()
  const [ShowConfirmModel, setShowConfirmModel] = useState(false)
  const [loading, setLoading] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [initialValues, setInitialValues] = useState({})
  const [particularEvent, setParticularEvent] = useState([])
  const [isFormComplete, setIsFormComplete] = useState(false)
  const [isEditPerformer, setIsEditPerformer] = useState(false)

  const clearValue = () => {
    setInputFields({
      ...inputFields,
      name: '',
      industry_type_id: '',
      performer_type_id: '',
      performer_id: '',
      start_time: '',
      end_time: '',
      min_price: '',
      max_price: '',
      tickets_qty: '',
      date: '',
      description: '',
      images: [],
      website: '',
      latitude: '',
      longitude: '',
      event_performer: [],
      contact_name1: '',
      contact_profile_pic: [],
      event_id: ''
    })
  }

  const clearPerformer = () => {
    setInputFields({
      ...inputFields,
      performer_type_id: '',
      performer_id: '',
      contact_name1: '',
      contact_profile_pic: []
    })
  }

  const addDiscount = () => {
    setIsAddDiscount(true)
  }

  const clickPerFormerAdd = () => {
    setPersonDetails(true)
    setIsRoomFeature(false)
  }

  const cancelContact = () => {
    setPersonDetails(false)
    setIsRoomFeature(true)
    clearPerformer()
  }

  const performerAdd = () => {
    const updatedPerformer = {
      performer_type_id: inputFields.performer_type_id,
      event_performers_name: inputFields.contact_name1,
      performer_profile_pic: inputFields.contact_profile_pic,
      performer_id: inputFields.performer_id
    }

    if (isEditPerformer && currentEditIndex !== null) {
      const updatedPerformers = [...inputFields.event_performer]
      updatedPerformers[currentEditIndex] = updatedPerformer

      setInputFields({
        ...inputFields,
        event_performer: updatedPerformers,
        performer_type_id: '',
        contact_name1: '',
        contact_profile_pic: []
      })
    } else {
      setInputFields({
        ...inputFields,
        event_performer: [...inputFields.event_performer, updatedPerformer],
        performer_type_id: '',
        contact_name1: '',
        contact_profile_pic: []
      })
    }
    setIsEditPerformer(false)
    setCurrentEditIndex(null)
    setPersonDetails(false)
    setIsRoomFeature(true)
  }

  const handleCancels = () => {
    clearValue()
    setIsAddDiscount(false)
    setIsRoomFeature(false)
    setIsAddRoom(false)
    setIsEdit(false)
  }
  const nextClick = () => {
    setIsAddDiscount(false)
    setIsRoomFeature(true)
  }

  const showEvent = (record) => {
    setIsAddRoom(true)
    particularEventData(record.event_id)
  }

  const EditEventData = async (record) => {
    const data = await particularEventData(record.event_id)
    console.log(data, 'data')
    if (Object.keys(record).length > 0) {
      setIsEdit(true)
      const mappedInputFields = data.map((d) => ({
        name: d.name,
        industry_type_id: d.industry_type_id,
        start_time: d.start_time,
        event_id: d.event_id,
        end_time: d.end_time,
        min_price: d.min_price,
        max_price: d.max_price,
        tickets_qty: d.tickets_qty,
        date: d.date,
        description: d.description,
        images: d.images,
        website: d.website,
        latitude: 51.1173926,
        longitude: 9.6874674,
        event_performer: d?.performer_details?.map((performer) => ({
          performer_id: performer.performer_id,
          event_performers_name: performer.performer_name,
          performer_type_id: performer.performer_type_id,
          performer_profile_pic: performer.performer_profile_pic
        })),
        organizer_id: isOrganizerId,
        contact_profile_pic: [],
        contact_name1: ''
      }))

      const mappedInitialValues = data.map((d) => ({
        name: d.name,
        industry_type_id: d.industry_type_id,
        start_time: d.start_time,
        event_id: d.event_id,
        end_time: d.end_time,
        min_price: d.min_price,
        max_price: d.max_price,
        tickets_qty: d.tickets_qty,
        date: d.date,
        description: d.description,
        images: d.images,
        website: d.website,
        latitude: 51.1173926,
        longitude: 9.6874674,
        event_performer: d?.performer_details?.map((performer) => ({
          performer_id: performer.performer_id,
          event_performers_name: performer.performer_name,
          performer_type_id: performer.performer_type_id,
          performer_profile_pic: performer.performer_profile_pic
        })),
        organizer_id: isOrganizerId
      }))

      setInputFields(mappedInputFields[0])
      setInitialValues(mappedInitialValues[0])
    }

    setIsAddDiscount(true)
  }

  const handleSearch = (e) => {
    setSearchText(e.target.value.toLowerCase())
  }

  const ShowConfirm = (record) => {
    setDelEventId(record.event_id)
    setShowConfirmModel(true)
  }
  const handleDeleteCancel = () => {
    setShowConfirmModel(false)
  }
  const handleCancelSpeaker = () => {
    setIsRoomFeature(false)
    setIsAddDiscount(true)
  }

  const formatViews = (views) => {
    if (views >= 1_000_000_000) {
      return `${(views / 1_000_000_000).toFixed(1)}B`
    }
    if (views >= 1_000_000) {
      return `${(views / 1_000_000).toFixed(1)}M`
    }
    if (views >= 1_000) {
      return `${(views / 1_000).toFixed(2)}k`
    }
    return views.toString()
  }

  const columns = [
    {
      title: 'EVENT NAME & IMAGE',
      dataIndex: 'name',
      key: 'name',
      render: (text, record) => (
        <div className="image_name">
          <Image
            width={100}
            src={s3ImagePathLocal + record.images[0]}
            alt="img"
            style={{ marginRight: 10 }}
          />
          <span>
            {record.name.length > 15
              ? `${record.name.substring(0, 15)}...`
              : record.name}
          </span>
        </div>
      ),
      sorter: (a, b) => a.name.localeCompare(b.name),
      onFilter: (value, record) => record.name.toLowerCase().includes(value.toLowerCase()),
      className: 'custom-column'
    },
    {
      title: 'DATE',
      dataIndex: 'date',
      key: 'date',
      sorter: (a, b) => a.date.localeCompare(b.date)
    },
    {
      title: 'TIMING',
      dataIndex: 'timing',
      key: 'timing',
      sorter: (a, b) => a.start_time.localeCompare(b.start_time),
      render: (text, record) => {
        const formatTime = (time) => time.slice(0, 5)
        return `${formatTime(record.start_time)} - ${formatTime(record.end_time)}`
      }
    },
    {
      title: 'INDUSTRY',
      dataIndex: 'industry_type',
      key: 'industry_type',
      sorter: (a, b) => a.industry_type.localeCompare(b.industry_type)
    },
    {
      title: 'TICKET QTY',
      dataIndex: 'tickets_qty',
      key: 'tickets_qty',
      sorter: (a, b) => a.tickets_qty - b.tickets_qty,
      render: (text) => formatViews(text)
    },
    {
      title: 'ACTION',
      key: 'action',
      render: (text, record) => (
        <div className="image-buttons-discounts">
          <Button
            icon={<img src={Edit} alt="Edit" />}
            onClick={(e) => {
              e.stopPropagation()
              EditEventData(record)
            }}
          />
          <Button
            icon={<img src={Delete} alt="Delete" />}
            onClick={(e) => {
              e.stopPropagation()
              ShowConfirm(record)
            }}
          />
        </div>
      )
    }
  ]

  const filteredData = eventListData?.filter((item) => item.name.toLowerCase().includes(searchText))

  const handleChange = (e) => {
    setInputFields({ ...inputFields, [e.target.name]: e.target.value })
  }

  const onUploadImage = async (file, fieldName, index) => {
    if (file) {
      const reader = new FileReader()
      reader.onload = async () => {
        const imageFile = await compressImage(file)
        const coverFormData = new FormData()
        coverFormData.append('file', imageFile)
        coverFormData.append('type', 'event')

        const uploadResponse = await imageUploadLocal(coverFormData)

        if (!uploadResponse.data.success) {
          throw new Error(
            uploadResponse.data.message || `${fieldName} upload failed`
          )
        }

        const decryptedImageData = dataDecrypt(uploadResponse.data.data)

        setInputFields((prev) => {
          const prevData = prev[fieldName]
          if (
            (index !== undefined || index !== null) &&
            Array.isArray(prevData)
          ) {
            prevData[index] = decryptedImageData
          }
          return {
            ...prev,
            [fieldName]: prevData
          }
        })
      }
      reader.readAsDataURL(file)
    }
  }

  const onChangeOpenTime = (time, timeString) => {
    setInputFields((prevState) => ({
      ...prevState,
      start_time: timeString
    }))
  }

  const onChangeCloseTime = (time, timeString) => {
    setInputFields((prevState) => ({
      ...prevState,
      end_time: timeString
    }))
  }

  const typeofIndustryListData = () => {
    industryTypeList({}).then((res) => {
      if (res.data.success) {
        setIsIndustryType(dataDecrypt(res.data.data))
      }
    })
  }

  const typeofPerformerListData = () => {
    performerTypeList({}).then((res) => {
      if (res.data.success) {
        setIsPerformerType(dataDecrypt(res.data.data))
      }
    })
  }

  const getIndustryName = (id) => {
    const categoryObj = isIndustryType.find((cat) => cat.id === id)
    return categoryObj ? categoryObj.industry_type : 'Select industry'
  }

  const getPerformerName = (id) => {
    const categoryObj = isPerformerType.find((cat) => cat.id === id)
    return categoryObj ? categoryObj.performer_type : 'Select performer'
  }

  const datePickerRef = useRef(null)

  const handleDateChange = (date) => {
    const today = new Date()
    const minDate = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate()
    )

    if (date >= minDate) {
      setInputFields({
        ...inputFields,
        date: moment(date).format('YYYY-MM-DD')
      })
    } else {
      setInputFields({
        ...inputFields,
        date: null
      })

      if (datePickerRef.current) {
        datePickerRef.current.setOpen(true)
      }
    }
  }

  const today = new Date()
  const minYear = today.getFullYear()
  const maxYear = today.getFullYear() + 10

  const years = []
  for (let year = minYear; year <= maxYear; year++) {
    years.push(year)
  }

  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ]

  const removeImage = (index) => {
    const updatedImages = [...inputFields.images]
    updatedImages.splice(index, 1)
    setInputFields((prev) => ({
      ...prev,
      images: updatedImages
    }))
  }

  const handleDragStart = (index) => {
    setDraggedIndex(index)
  }

  const handleDrop = (index) => {
    if (index !== draggedIndex) {
      setInputFields((prev) => {
        const copy = [...prev.images]
        const b = copy[draggedIndex]
        copy[draggedIndex] = copy[index]
        copy[index] = b
        return {
          ...prev,
          images: copy
        }
      })

      setDraggedIndex(null)
    }
  }

  const handleLoad = (index) => {
    setIsImageLoading({
      index,
      loading: true
    })
    setTimeout(() => {
      setIsImageLoading({
        index: null,
        loading: false
      })
    }, 500)
  }

  const renderImg = useMemo(() => {
    return (
      <>
        {inputFields.images.map((image, index) => {
          return (
            <div
              className={`image position-relative ${
                index === 0 ? 'first-image' : ''
              }`}
              key={index}
              alt={`image-${index}`}
              draggable
              onDragStart={() => {
                handleDragStart(index)
              }}
              onDragOver={(event) => event.preventDefault()}
              onDragEnter={(event) => event.preventDefault()}
              onDrop={(event) => {
                event.preventDefault()
                handleDrop(index)
              }}
            >
              {isImageLoading.index === index && isImageLoading.loading && (
                <Loader />
              )}
              <img
                className={`image position-relative ${
                  index === 0 ? 'first-image' : ''
                }`}
                src={
                  typeof image === 'string'
                    ? s3ImagePathLocal + image
                    : URL.createObjectURL(image)
                }
                alt={`image-${index}`}
                style={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover'
                }}
                onLoad={() => handleLoad(index)}
              />
              <button
                className="image_remove_icon"
                type="button"
                onClick={() => removeImage(index)}
              >
                <img src={Remove} alt="" />
              </button>
              <button type="button" className="plus-img">
                <CommonImageUpload
                  handleImageChange={(file) => {
                    onUploadImage(file, 'images', index)
                  }}
                  selectedImg=""
                  defaultImg={plusimg}
                  className="plsimg"
                />
              </button>
            </div>
          )
        })}
      </>
    )
  }, [inputFields.images, handleDragStart])

  const allEventData = (currentPage) => {
    const payload = {
      organizer_id: isOrganizerId
    }
    eventGet(payload, currentPage).then((res) => {
      if (res.data.success) {
        setEventListData(dataDecrypt(res.data.data).data)
        setTotalPages(dataDecrypt(res.data.data).last_page)
        setCurrentPage(dataDecrypt(res.data.data).current_page)
      }
    })
  }

  const roomAddApiCall = () => {
    const payload = {
      name: inputFields.name,
      event_id: inputFields.event_id,
      industry_type_id: inputFields.industry_type_id,
      start_time: inputFields.start_time.slice(0, 5),
      end_time: inputFields.end_time.slice(0, 5),
      min_price: inputFields.min_price,
      max_price: inputFields.max_price,
      tickets_qty: inputFields.tickets_qty,
      date: inputFields.date,
      description: inputFields.description,
      images: inputFields.images,
      event_performers_name: inputFields.event_performer.map(
        (d) => d.event_performers_name
      ),
      performer_type_id: inputFields.event_performer.map(
        (d) => d.performer_type_id
      ),
      performer_profile_pic: inputFields.event_performer.map((d) => d.performer_profile_pic.toString()),
      organizer_id: isOrganizerId,
      latitude: 51.1173926,
      longitude: 9.6874674,
      website: inputFields.website
    }
    if (isEdit) {
      eventEdit(payload).then((res) => {
        if (res.data.success) {
          setIsAddDiscount(false)
          setIsRoomFeature(false)
          setPersonDetails(false)
          toast.success(res?.data?.message)
          setIsEdit(false)
          allEventData(currentPage)
          clearValue()
        } else {
          toast.error(res?.data?.message)
          setIsEdit(false)
        }
      })
    } else {
      eventAdd(payload).then((res) => {
        if (res.data.success) {
          toast.success(res?.data?.message)
          setIsAddDiscount(false)
          setIsRoomFeature(false)
          setPersonDetails(false)
          clearValue()
          allEventData(currentPage)
        } else {
          toast.error(res?.data?.message)
        }
      })
    }
  }

  const deleteEvent = () => {
    setLoading(true)
    const data = {
      event_id: delEventId
    }
    eventDelete(data).then((res) => {
      if (res.data.success) {
        setShowConfirmModel(false)
        setLoading(false)
        allEventData(currentPage)
        toast.success(res?.data?.message)
      } else {
        setLoading(false)
        toast.error(res?.data?.message)
      }
    })
  }

  const particularEventData = async (event_id) => {
    const payload = {
      organizer_id: isOrganizerId,
      event_id
    }
    try {
      const res = await ParticularEventDetails(payload)
      if (res.data.success) {
        const decryptedData = dataDecrypt(res.data.data)
        setParticularEvent(decryptedData)
        return decryptedData
      }
    } catch (error) {
      console.error('Error fetching event data:', error)
    }
  }

  const handlePageChange = (pageNumber) => {
    allEventData(pageNumber)
    setCurrentPage(pageNumber)
  }
  const [currentEditIndex, setCurrentEditIndex] = useState(null)

  const onClickPerformerEdit = (data, index) => {
    setIsEditPerformer(true)
    setPersonDetails(true)
    setIsRoomFeature(false)
    setInputFields({
      ...inputFields,
      performer_type_id: data?.performer_type_id,
      contact_name1: data?.event_performers_name,
      contact_profile_pic: [data?.performer_profile_pic],
      performer_id: data?.performer_id
    })
    setCurrentEditIndex(index)
  }

  const AddEditPerformer = () => {
    if (isEdit) {
      const payload = {
        event_id: inputFields.event_id,
        performer_type_id: inputFields.performer_type_id,
        performer_name: inputFields.contact_name1,
        performer_profile_pic: inputFields.contact_profile_pic.toString(),
        performer_id: inputFields.performer_id
      }
      if (isEditPerformer) {
        eventPerformerEdit(payload).then((res) => {
          if (res.data.success) {
            setIsRoomFeature(true)
            setPersonDetails(false)
            toast.success(res?.data?.message)
            clearPerformer()
          } else {
            toast.error(res?.data?.message)
          }
        })
      } else {
        eventPerformerAdd(payload).then((res) => {
          if (res.data.success) {
            toast.success(res?.data?.message)
            setIsRoomFeature(true)
            setPersonDetails(false)
            clearPerformer()
          } else {
            toast.error(res?.data?.message)
          }
        })
      }
    }
    performerAdd()
  }

  const handleRemovePerformer = (data, index) => {
    if (isEdit) {
      deletePerformer(data.performer_id, index)
    } else {
      const updatedPerformers = inputFields.event_performer.filter(
        (performer, i) => i !== index
      )
      setInputFields({
        ...inputFields,
        event_performer: updatedPerformers
      })
    }
  }

  const deletePerformer = (id, index) => {
    const data = {
      performer_id: id
    }
    eventPerformerDelete(data).then((res) => {
      if (res.data.success) {
        const updatedPerformers = inputFields.event_performer?.filter(
          (performer, i) => i !== index
        )
        setInputFields({
          ...inputFields,
          event_performer: updatedPerformers
        })
        toast.success(res?.data?.message)
      }
    })
  }

  const validateForm = () => {
    const {
      name,
      industry_type_id,
      start_time,
      end_time,
      min_price,
      max_price,
      tickets_qty,
      date,
      description,
      images,
      website
    } = inputFields

    if (
      name &&
      industry_type_id &&
      start_time &&
      end_time &&
      min_price &&
      max_price &&
      tickets_qty &&
      date &&
      description &&
      images.length > 0 &&
      website
    ) {
      setIsFormComplete(true)
    } else {
      setIsFormComplete(false)
    }
  }

  useEffect(() => {
    if (!isEdit) {
      validateForm()
    }
  }, [inputFields])

  useEffect(() => {
    if (isEdit) {
      setIsFormComplete(!_.isEqual(inputFields, initialValues))
    }
  }, [inputFields, initialValues, isEdit])

  useEffect(() => {
    typeofIndustryListData()
    typeofPerformerListData()
    allEventData(currentPage)
  }, [])

  useEffect(() => {
    if (isAddDiscount || isRoomFeature || ShowConfirmModel || isAddRoom) {
      lockScroll()
    } else {
      unlockScroll()
    }
    return () => {
      unlockScroll()
    }
  }, [isAddDiscount, isRoomFeature, ShowConfirmModel, isAddRoom])

  return (
    <div className="">
      <div className="position-relative">
        {eventListData?.length === 0 ? (
          <div className="add_room_text">
            <img className="d-block" src={Event_Logo} alt="bedroom" />
            <h3>CREATE YOUR FIRST DEAL</h3>
            <p>You have not created any deal yet</p>
            <div className="add-video-rows" onClick={addDiscount}>
              <div className="add_new_content">
                <img src={PlusIco} alt="" />
                Create Deal
              </div>
            </div>
          </div>
        ) : (
          <div className="local-room-main">
            <h3 className="d-flex align-items-center justify-content-between">
              Events
              <button
                type="button"
                className="primary-btn"
                onClick={addDiscount}
              >
                <img src={PlusIco} alt="add" />
                Create Event
              </button>
            </h3>
            <div className="discount_list">
              <Input
                placeholder="Search by Item Name"
                onChange={handleSearch}
                style={{ width: 200, marginBottom: '1rem' }}
              />
              <div className="discount_list">
                <Table
                  columns={columns}
                  dataSource={filteredData}
                  pagination={false}
                  onRow={(record) => {
                    return {
                      onClick: () => {
                        showEvent(record)
                      }
                    }
                  }}
                />
              </div>
              <ConfirmationModal
                isVisible={ShowConfirmModel}
                onConfirm={deleteEvent}
                onCancel={handleDeleteCancel}
                message="Do you want to delete this?"
                isLoading={loading}
              />
              {loading && <Loader />}
              {totalPages > 1 && (
                <Pagination
                  currentPage={currentPage}
                  totalPages={totalPages}
                  onPageChange={handlePageChange}
                />
              )}
            </div>
          </div>
        )}

        {isAddDiscount && (
          <div className="add-item-model">
            <div className="add-item-content local_type_modal contact_padding">
              <div className="type_close mb-2 local_sticky_header">
                <div className="d-flex align-items-center col-gap-10">
                  ADD EVENT
                </div>
                <button
                  className="cancel_icon"
                  type="button"
                  onClick={handleCancels}
                >
                  <img src={Remove} alt="" />
                </button>
              </div>
              <div className="item-details-input pt-0 local_contact_scroll_bars">
                <div className="input-grid">
                  <div>
                    <label>Event Name</label>
                    <CommonInput
                      placeholder="Enter event name"
                      name="name"
                      onChange={handleChange}
                      value={capitalizeFirstLetter(inputFields.name)}
                    />
                  </div>
                  <div>
                    <label>Industry</label>
                    <Dropdown
                      title={getIndustryName(inputFields.industry_type_id)}
                      name="industry_type_id"
                      onChanged={(d) => {
                        setInputFields({
                          ...inputFields,
                          industry_type_id: d.id
                        })
                      }}
                      Data={isIndustryType.map((d) => ({
                        title: d.industry_type,
                        id: d.id
                      }))}
                      customClass={
                        inputFields.industry_type_id
                          ? 'selected_class_local'
                          : 'local-dropdown'
                      }
                    />
                  </div>
                </div>
                <div className="input-grid">
                  <div>
                    <label>Date</label>
                    <div className="calendar">
                      <DatePicker
                        ref={datePickerRef}
                        selected={
                          inputFields.date ? new Date(inputFields.date) : null
                        }
                        required
                        showMonthDropdown
                        autoComplete="off"
                        showYearDropdown
                        onChange={handleDateChange}
                        name="date"
                        dateFormat="yyyy-MM-dd"
                        placeholderText="Please Choose Date"
                        minDate={new Date()}
                        onKeyDown={(e) => {
                          e.preventDefault()
                        }}
                        className="custom-datepicker"
                        renderCustomHeader={({
                          date,
                          changeYear,
                          changeMonth,
                          decreaseMonth,
                          increaseMonth,
                          prevMonthButtonDisabled,
                          nextMonthButtonDisabled
                        }) => (
                          <div className="datepicker-header">
                            <button
                              type="button"
                              className="datepicker-button"
                              onClick={(e) => {
                                e.preventDefault()
                                decreaseMonth()
                              }}
                              disabled={prevMonthButtonDisabled}
                            >
                              {'<'}
                            </button>
                            <select
                              className="datepicker-select"
                              value={date.getFullYear()}
                              onChange={({ target: { value } }) => changeYear(Number(value))}
                            >
                              {years.map((option) => (
                                <option key={option} value={option}>
                                  {option}
                                </option>
                              ))}
                            </select>
                            <select
                              className="datepicker-select"
                              value={months[date.getMonth()]}
                              onChange={({ target: { value } }) => {
                                changeMonth(months.indexOf(value))
                              }}
                            >
                              {months.map((option) => (
                                <option key={option} value={option}>
                                  {option}
                                </option>
                              ))}
                            </select>
                            <button
                              type="button"
                              className="datepicker-button"
                              onClick={(e) => {
                                e.preventDefault()
                                increaseMonth()
                              }}
                              disabled={nextMonthButtonDisabled}
                            >
                              {'>'}
                            </button>
                          </div>
                        )}
                      />
                    </div>
                  </div>
                  <div>
                    <label>Ticket Qty.</label>
                    <CommonInput
                      placeholder="Tickets qty"
                      name="tickets_qty"
                      pattern="/^-?\d+\.?\d*$/"
                      maxLength={4}
                      onKeyPress={(e) => {
                        if (e.target.value.length === 4) {
                          e.preventDefault()
                        }
                      }}
                      type="number"
                      onChange={handleChange}
                      value={inputFields.tickets_qty}
                    />
                  </div>
                </div>
                <div className="input-grid mb-2">
                  <div>
                    <label>Opening Time</label>
                    <TimePicker
                      name="start_time"
                      onChange={onChangeOpenTime}
                      showNow={false}
                      showOk={false}
                      suffixIcon={null}
                      placeholder="Select Start Time"
                      value={
                        inputFields?.start_time
                          ? dayjs(inputFields?.start_time, 'HH:mm')
                          : null
                      }
                      format="HH:mm"
                      className="ant-design event_time"
                      style={{ 'margin-top': '0px' }}
                    />
                  </div>
                  <div>
                    <label>Closing Time</label>
                    <TimePicker
                      name="end_time"
                      onChange={onChangeCloseTime}
                      showNow={false}
                      showOk={false}
                      suffixIcon={null}
                      placeholder="Select End Time"
                      value={
                        inputFields?.end_time
                          ? dayjs(inputFields?.end_time, 'HH:mm')
                          : null
                      }
                      format="HH:mm"
                      className="ant-design event_time"
                      style={{ 'margin-top': '0px' }}
                    />
                  </div>
                </div>
                <div className="input-grid">
                  <div>
                    <label>Minimum Price</label>
                    <CommonInput
                      placeholder="Min. price"
                      name="min_price"
                      pattern="/^-?\d+\.?\d*$/"
                      maxLength={4}
                      onKeyPress={(e) => {
                        if (e.target.value.length === 4) {
                          e.preventDefault()
                        }
                      }}
                      type="number"
                      onChange={handleChange}
                      value={inputFields.min_price}
                    />
                  </div>
                  <div>
                    <label>Maximum Price</label>
                    <CommonInput
                      placeholder="Max. price"
                      name="max_price"
                      type="number"
                      pattern="/^-?\d+\.?\d*$/"
                      maxLength={4}
                      onKeyPress={(e) => {
                        if (e.target.value.length === 4) {
                          e.preventDefault()
                        }
                      }}
                      onChange={handleChange}
                      value={inputFields.max_price}
                    />
                  </div>
                </div>
                <div className="">
                  <div>
                    <label>Booking/Guestlist Link</label>
                    <CommonInput
                      placeholder="Booking link"
                      name="website"
                      onChange={handleChange}
                      value={inputFields.website}
                    />
                  </div>
                </div>
                <div className="">
                  <div>
                    <label>Description</label>
                    <textarea
                      placeholder="Briefly describe about your event"
                      name="description"
                      onChange={handleChange}
                      value={capitalizeFirstLetter(inputFields.description)}
                    />
                  </div>
                </div>
                <div className="hotel_image_upload_section">
                  <div className="hotel_upload_div mt-0">
                    <div className="input-image-show justify-content-start gap-3">
                      {renderImg}
                      {inputFields.images.length < 4 && (
                        <div className="input-img-label">
                          <CommonImageUpload
                            handleImageChange={(file) => onUploadImage(
                                file,
                                'images',
                                inputFields.images.length
                              )}
                            buttonText="Add Image"
                            selectedImg=""
                            defaultImg={Plus}
                            className="room_add_img"
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="item-details-input pt-0">
                <div className="local_button_row local_sticky_btn">
                  <CommonButton
                    label="Cancel"
                    onClick={handleCancels}
                    isPrimary={false}
                    context="Profile"
                  />
                  <CommonButton
                    label="Next"
                    type="button"
                    isPrimary
                    onClick={nextClick}
                    disabled={!isFormComplete}
                    context="Profile"
                  />
                </div>
              </div>
            </div>
          </div>
        )}

        {isRoomFeature && (
          <div className="add-item-model">
            <div className="add-item-content local_type_modal pt-0">
              <div className="type_close mb-2 local_sticky_header pt-4 pb-2 bg-light">
                <div className="d-flex align-items-center col-gap-10">
                  ADD EVENT
                </div>
                <button
                  className="cancel_icon"
                  type="button"
                  onClick={handleCancels}
                >
                  <img src={Remove} alt="" />
                </button>
              </div>
              <div
                className={`item-details-input pt-0 ${
                  inputFields?.event_performer?.length > 2
                    ? 'local_scroll_bars'
                    : ''
                }`}
              >
                <div className="event_speaker_dropdown mb-4">
                  <div className="event_speaker_drop">
                    <label>Speaker</label>
                    <Dropdown
                      title={getPerformerName(inputFields.performer_type_id)}
                      name="performer_type_id"
                      onChanged={(d) => {
                        setInputFields({
                          ...inputFields,
                          performer_type_id: d.id
                        })
                      }}
                      Data={isPerformerType.map((d) => ({
                        title: d.performer_type,
                        id: d.id
                      }))}
                      customClass={
                        inputFields.performer_type_id
                          ? 'selected_class_local'
                          : 'local-dropdown'
                      }
                    />
                  </div>
                </div>
                <div className="speaker_info position-relative">
                  {inputFields?.event_performer
                    ?.filter(
                      (data) => data &&
                        data.performer_profile_pic &&
                        data.event_performers_name
                    )
                    ?.map((data, index) => (
                      <div
                        className="event_speaker position-relative"
                        key={index}
                      >
                        <div className="speaker_img">
                          <img
                            src={s3ImagePathLocal + data.performer_profile_pic}
                            alt={data.performer_profile_pic}
                          />
                        </div>
                        <div className="event_speaker_name">
                          <h1>
                            {data.event_performers_name.slice(0, 17)}
                            {data.event_performers_name.length > 17 && '...'}
                          </h1>
                          <p>{getPerformerName(data.performer_type_id)}</p>
                        </div>
                        <div className="event_speaker_remove">
                          <CommonButton
                            label="Remove"
                            type="button"
                            isPrimary={false}
                            context="Profile"
                            className="event_speaker_btn"
                            onClick={() => handleRemovePerformer(data, index)}
                          />
                        </div>
                        <div className="local_contact_buttons_event z-1">
                          <label>
                            <button
                              type="button"
                              onClick={(e) => {
                                e.stopPropagation()
                                onClickPerformerEdit(data, index)
                              }}
                              className="d-none"
                            />
                          </label>
                        </div>
                      </div>
                    ))}
                  {inputFields.event_performer?.length < 5 ? (
                    <div className="event_speaker">
                      <div className="add-profile-video-cover event_performer_box">
                        <button type="button" onClick={clickPerFormerAdd}>
                          <img src={Plus} alt="add library" />
                        </button>
                      </div>
                      <div className="add-profile-video-detail">
                        <p />
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="local_button_row sticky-footer mt-0 bg-light">
                <CommonButton
                  label="CANCEL"
                  onClick={handleCancelSpeaker}
                  isPrimary={false}
                  context="Profile"
                  className="common-button"
                />
                <CommonButton
                  label="Save"
                  type="button"
                  isPrimary
                  disabled={inputFields.event_performer?.length === 0}
                  onClick={roomAddApiCall}
                  context="Profile"
                />
              </div>
            </div>
          </div>
        )}

        {isAddRoom && (
          <div className="add-item-model">
            {particularEvent.map((data, index) => (
              <div className="add-item-content add_room_modal" key={index}>
                <div className="type_close mb-2 local_sticky_header">
                  <div className="d-flex align-items-center col-gap-10">
                    {data.name}
                  </div>
                  <button
                    className="cancel_icon"
                    type="button"
                    onClick={handleCancels}
                  >
                    <img src={Remove} alt="" />
                  </button>
                </div>
                <div className="item-details-input pt-0 local_contact_scroll_bars px-3">
                  <div className="room_image menu_image_show">
                    {data.images.map((d, i) => {
                      return (
                        <img
                          className="menu_image"
                          src={s3ImagePathLocal + d}
                          alt="roomImage"
                          key={i}
                        />
                      )
                    })}
                  </div>
                  <div className="edit_description ">
                    <p>{data.description}</p>
                  </div>
                  <div className="mb-3">
                    <div>
                      <label>Performer</label>
                      <div className="mb-3">
                        <div className="d-flex flex-wrap row">
                          {data.performer_details?.map((item, index) => {
                            const totalItems = data.performer_details.length
                            const columnClass =
                              totalItems === 2 ? 'col-6' : 'col-4'

                            return (
                              <div
                                key={index}
                                className={columnClass}
                                style={{ marginBottom: '10px' }}
                              >
                                <div
                                  className="podcast-profile-img"
                                  style={{ marginRight: '10px' }}
                                >
                                  <img
                                    src={
                                      s3ImagePathLocal +
                                      item.performer_profile_pic
                                    }
                                    alt=""
                                  />
                                  <div className="podcast-profile-detail">
                                    <span>{item.performer_name}</span>
                                    <p className="designation">
                                      {item.performer_type_name}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            )
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="input-grid detail-grid">
                    <div>
                      <label>Dates</label>
                      <p>{data.date}</p>
                    </div>
                    <div>
                      <label>Price</label>
                      <p>
                        €
                        {data.min_price}
                      </p>
                    </div>
                  </div>
                  <div className="input-grid detail-grid">
                    <div>
                      <label>Timings</label>
                      <p>
                        {data.start_time.slice(0, 5)}
                        -
                        {data.end_time.slice(0, 5)}
                        {' '}
                        (expected)
                      </p>
                    </div>
                    <div>
                      <label>Industry</label>
                      <p>{data.industry_type}</p>
                    </div>
                  </div>
                </div>
                <div className="item-details-input pt-0">
                  <div className="local_button_row local_sticky_btn">
                    <CommonButton
                      label="CANCEL"
                      onClick={handleCancels}
                      isPrimary={false}
                      context="Profile"
                    />
                    {/* <CommonButton
                      label="EDIT"
                      type="button"
                      isPrimary
                      onClick={eventEditSave}
                      context="Profile"
                    /> */}
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}

        {personDetails && (
          <div className="add-item-model">
            <div className="add-item-content local_type_modal">
              <div className="type_close mb-2">
                <div className="d-flex align-items-center col-gap-10">
                  Performer Person
                </div>
                <button
                  className="cancel_icon"
                  type="button"
                  onClick={cancelContact}
                >
                  <img src={Remove} alt="" />
                </button>
              </div>
              <div className="item-details-input pt-0">
                <p />
                <div className="logo_btn">
                  <div className="image_upload_section">
                    <div className="add_photos mb-4 mt-2">
                      <div className="add_logo_text text-center align-self-center">
                        <CommonImageUpload
                          name="local_upload_logo"
                          handleImageChange={(file) => onUploadImage(file, 'contact_profile_pic', 0)}
                          index={0}
                          forImage="local_photo"
                          className="profile-upload"
                          selectedImg={
                            inputFields.contact_profile_pic.length > 0
                              ? `${s3ImagePathLocal}${inputFields.contact_profile_pic}`
                              : ''
                          }
                          defaultImg={local_add_img}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="person_detail_section">
                    <div className="mb-3">
                      <label>Performer Type</label>
                      <Dropdown
                        title={getPerformerName(inputFields.performer_type_id)}
                        name="performer_type_id"
                        onChanged={(d) => {
                          setInputFields({
                            ...inputFields,
                            performer_type_id: d.id
                          })
                        }}
                        Data={isPerformerType.map((d) => ({
                          title: d.performer_type,
                          id: d.id
                        }))}
                        customClass={
                          inputFields.performer_type_id
                            ? 'selected_class_local'
                            : 'local-dropdown'
                        }
                      />
                    </div>
                    <div>
                      <label>Name</label>
                      <CommonInput
                        placeholder="Name"
                        onChange={handleChange}
                        name="contact_name1"
                        value={capitalizeFirstLetter(inputFields.contact_name1)}
                      />
                    </div>
                  </div>
                </div>
                <p />
                <p />
                <div className="local_button_row">
                  <CommonButton
                    label="Cancel"
                    onClick={cancelContact}
                    isPrimary={false}
                    context="Profile"
                  />
                  <CommonButton
                    label="Next"
                    type="button"
                    isPrimary
                    onClick={() => {
                      AddEditPerformer()
                    }}
                    disabled={
                      !(
                        inputFields.performer_type_id &&
                        inputFields.contact_name1 &&
                        inputFields.contact_profile_pic
                      )
                    }
                    context="Profile"
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
export default Event
