/* eslint-disable no-nested-ternary */
import React, { useCallback, useEffect, useRef, useState } from 'react'
import './AddPolls.css'
import ProgressBar from '@ramonak/react-progress-bar'
import { toast } from 'react-toastify'
import { useNavigate, useParams } from 'react-router-dom'
import Skeleton from 'react-loading-skeleton'
import Dropdown from '../Dropdown/Dropdown'
import plusBtn from '../../images/plus.svg'
import Comment from '../../images/ls_comments.svg'
import IconsMore from '../../images/akar-icons_more-horizontal-fill.svg'
import GroupDiscussion from '../../images/Group-discussion.svg'
import Remove from '../../images/remove.svg'
import UnLikes from '../../images/like-empty.svg'
import Likes from '../../images/like-fill.svg'
import {
  addPollData,
  addVoteData,
  deletePollData,
  editPollData,
  getCategoryData,
  getPollsData,
  getSubCategoryData,
  pollLike,
  s3ImagePath,
  voterListData
} from '../../services/Service'
import { dataDecrypt } from '../../services/http-services'
import Loader from '../Loader/Loader'
import CloseIco from '../../images/CloseIco.svg'
import PlatformTag from '../ContentManager/PlatformTag'
import AddIco from '../../images/AddIco.svg'
import Pagination from '../Pagination/Pagination'
import { capitalizeFirstLetter, debounce, lockScroll, unlockScroll } from '../../helpers/Utility'
import ConfirmationModal from '../ConfirmationModal/ConfirmationModal'
import Plus from '../../images/plus-rounded.svg'
import CommonButton from '../ButtonComponent/ButtonCommon'
import CommonInput from '../InputComponet/CommonInput'

const PollsComponent = ({ showCount }) => {
  const { id } = useParams()

  const navigate = useNavigate()
  const componentRef = useRef()

  const [updatingIndex, setUpdatingIndex] = useState(null)
  const [loading, setLoading] = useState(false)
  const [ShowProfileModel, setShowProfileModel] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [votedPolls, setVotedPolls] = useState({})
  const [currentPageList, setCurrentPageList] = useState(1)
  const [voteList, setVoteList] = useState([])
  const [step, setStep] = useState('')
  const [steps, setSteps] = useState([])
  const [polls, setPolls] = useState([])
  const [ShowPollPreview, setShowPollPreview] = useState(false)
  const [ShowPollModel, setShowPollModel] = useState(false)
  const [isEditing, setIsEditing] = useState(false)
  const [editIndex, setEditIndex] = useState(-1)
  const [editValue, setEditValue] = useState('')
  const [Platformtags, setPlatformTags] = useState([])
  const [inputFields, setInputFields] = useState({
    app_id: [],
    topic: '',
    category_id: 0,
    sub_category_id: 0,
    option_1: '',
    option_2: '',
    option_3: '',
    option_4: ''
  })
  const [delPollId, setDelPollId] = useState()
  const [ShowConfirmModel, setShowConfirmModel] = useState(false)
  const [category, setCategory] = useState([])
  const [subCategory, setSubCategory] = useState([])
  const [isPublishEnabled, setIsPublishEnabled] = useState(false)
  const [initialValues, setInitialValues] = useState({})
  const [isLoading, setIsLoading] = useState(false)

  const ShowConfirm = (poll_id) => {
    setDelPollId(poll_id)
    setShowConfirmModel(true)
  }

  const HandlePollDetail = () => {
    setShowPollModel(true)
    // navigate('/add-component', { state: { defaultIndex: 5 } })
  }
  const HandleCancel = () => {
    clearValue()
    setShowPollModel(false)
    setShowPollPreview(false)
    setIsEditing(false)
  }
  const handleCancel = () => {
    setShowConfirmModel(false)
  }

  const handleInputChangeStep = (e) => {
    setStep(e.target.value)
  }
  const handleAddStep = () => {
    if (step.trim() !== '' && steps.filter((s) => s.trim() !== '').length < 4) {
      const updatedSteps = [...steps, step]
      setSteps(updatedSteps)
      setInputFields({
        ...inputFields,
        [`option_${updatedSteps.filter((s) => s.trim() !== '').length}`]: step
      })
      setStep('')
      checkIfPublishEnabled(updatedSteps)
    }
  }

  const handleEditStep = (index) => {
    if (steps[index].trim() !== '') {
      setEditIndex(index)
      setEditValue(steps[index])
    }
  }

  const handleRemoveStep = (indexToRemove) => {
    const updatedSteps = steps.filter((_, index) => index !== indexToRemove)
    setSteps(updatedSteps)
    const updatedFields = {
      ...inputFields,
      [`option_${indexToRemove + 1}`]: ''
    }
    for (let i = indexToRemove; i < 3; i++) {
      updatedFields[`option_${i + 1}`] = updatedFields[`option_${i + 2}`] || ''
    }
    setInputFields(updatedFields)
    checkIfPublishEnabled(updatedSteps)
  }

  const handleSaveEditStep = () => {
    const updatedSteps = [...steps]
    updatedSteps[editIndex] = editValue
    setSteps(updatedSteps)
    setInputFields({
      ...inputFields,
      [`option_${editIndex + 1}`]: editValue
    })
    setEditIndex(-1)
    setEditValue('')
    checkIfPublishEnabled(updatedSteps)
  }

  const checkIfPublishEnabled = (updatedSteps) => {
    const nonEmptySteps = updatedSteps.filter((step) => step.trim() !== '')
    const hasChanges =
      JSON.stringify(initialValues) !== JSON.stringify(inputFields)
    setIsPublishEnabled(nonEmptySteps.length > 0 && hasChanges)
  }

  const nonEmptySteps = steps.filter((step) => step.trim() !== '')

  const HandlePollsPreview = () => {
    setShowPollPreview(true)
  }
  // const HandlePreCancel = () => {
  //   setShowPollModel(true)
  //   setShowPollPreview(false)
  // }
  const publishPoll = () => {
    if (isEditing) {
      editPollDataApi()
    } else {
      addPollDataSubmit()
    }
    setShowPollPreview(false)
    setShowPollModel(false)
    setIsEditing(false)
    clearValue()
  }

  const clearValue = () => {
    setPlatformTags([])
    setSteps([])
    setCategory([])
    setSubCategory([])
    setStep('')
    setInputFields({
      ...inputFields,
      app_id: [],
      topic: '',
      category_id: '',
      sub_category_id: '',
      option_1: '',
      option_2: '',
      option_3: '',
      option_4: ''
    })
  }

  const handleChange = (e) => {
    setInputFields({ ...inputFields, [e.target.name]: e.target.value })
  }

  const getSelectedAppId = (app) => {
    setInputFields({ ...inputFields, app_id: app.map((a) => a.id) })
  }

  const addPollDataSubmit = () => {
    setLoading(true)
    addPollData(inputFields).then((res) => {
      if (res.data.success) {
        setShowPollPreview(false)
        getPolls()
        setIsEditing(false)
        setLoading(false)
        clearValue()
        toast.success(res?.data?.message)
      } else {
        toast.error(res?.data?.message)
      }
    })
  }

  const deletePoll = () => {
    const data = {
      poll_id: delPollId
    }
    setLoading(true)
    deletePollData(data).then((res) => {
      if (res.data.success) {
        setShowConfirmModel(false)
        getPolls()
        setLoading(false)
        toast.success(res?.data?.message)
      }
    })
  }

  const MainCategory = () => {
    getCategoryData({}).then((res) => {
      if (res.data.success) {
        setCategory(dataDecrypt(res.data.data))
      }
    })
  }
  const SubCategory = (data) => {
    getSubCategoryData(data).then((res) => {
      if (res.data.success) {
        setSubCategory(dataDecrypt(res.data.data)[0]?.sub_category)
      }
    })
  }

  const getCategoryName = (categoryId) => {
    const categoryObj = category.find((cat) => cat.id === categoryId)
    return categoryObj ? categoryObj.category_name : 'Select category'
  }

  const getSubCategoryNameById = (subCategoryId) => {
    const subCategoryItem = subCategory.find(
      (sc) => sc.sub_category_id === subCategoryId
    )
    return subCategoryItem
      ? subCategoryItem.sub_category_name
      : 'Select subcategory'
  }

  const editPollDataApi = () => {
    const data = {
      topic: inputFields.topic,
      category_id: inputFields.category_id,
      sub_category_id: inputFields.sub_category_id,
      option_1: inputFields.option_1,
      option_2: inputFields.option_2,
      option_3: inputFields.option_3,
      option_4: inputFields.option_4,
      app_id: Platformtags.map((d) => d.id),
      poll_id: inputFields.poll_id
    }
    editPollData(data).then((res) => {
      if (res.data.success) {
        setIsEditing(false)
        clearValue()
        setLoading(false)
        toast.success(res?.data?.message)
        getPolls()
      }
    })
  }

  const getPolls = (search) => {
    setIsLoading(true)
    const dataId = localStorage.getItem('app_id')
    const parsedData = JSON.parse(dataId)
    const platFormData = {
      app_id: parsedData.id,
      search
    }
    getPollsData(platFormData).then((res) => {
      if (res?.data?.success) {
        setPolls(dataDecrypt(res.data.data).data)
        const { data } = dataDecrypt(res.data.data)
        if (data.length !== 0) {
          // voterList(data[0].poll_id)
          const pollIds = data.map((poll) => poll.poll_id)
          // Fetch voter data for all polls
          voterList(pollIds)
        }
        setIsLoading(false)
      }
    })
  }

  const handleSearch = useCallback(
    debounce((inputVal) => getPolls(inputVal), 500),
    []
  )

  const voterList = (poll_id) => {
    const dataId = localStorage.getItem('app_id')
    const parsedData = JSON.parse(dataId)
    const platFormData = {
      app_id: parsedData.id,
      poll_id
    }
    voterListData(platFormData).then((res) => {
      if (res?.data?.success) {
        setVoteList(dataDecrypt(res.data.data).data)
      }
    })
  }

  const voteAdd = (poll_id, optionIndex) => {
    const platFormData = {
      poll_id,
      option: optionIndex
    }
    addVoteData(platFormData).then((res) => {
      if (res.data.success) {
        setVotedPolls(dataDecrypt(res.data.data).data)
        getPolls()
      }
    })
  }

  const pollLikeData = (poll_id) => {
    const dataId = localStorage.getItem('app_id')
    const parsedData = JSON.parse(dataId)
    const platFormData = {
      app_id: parsedData.id,
      poll_id
    }
    pollLike(platFormData).then((res) => {
      if (res.data.success) {
        setPolls(
          polls.map((item) => (item.poll_id === poll_id
              ? { ...item, is_like: !item.is_like }
              : item))
        )
        toast.success(res?.data?.message)
      }
    })
  }

  const showList = (poll_id) => {
    voterList(poll_id)
    setShowProfileModel(true)
  }
  const close = () => {
    setShowProfileModel(false)
  }

  // Poll pagination

  const videosPerPage = 5
  const indexOfLastVideo = currentPage * videosPerPage
  const indexOfFirstVideo = indexOfLastVideo - videosPerPage
  const pollsData = polls.slice(indexOfFirstVideo, indexOfLastVideo)
  const totalVideos = polls.length
  const totalPages = Math.ceil(totalVideos / videosPerPage)

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber)
  }

  console.log(votedPolls, 'votedPolls')

  // voter list pagination

  const GiveawayList = 5
  const indexOfLastVideoList = currentPageList * GiveawayList
  const indexOfFirstVideoList = indexOfLastVideoList - GiveawayList
  const voteListData = voteList.slice(
    indexOfFirstVideoList,
    indexOfLastVideoList
  )
  const totalGiveaway = voteList.length
  const totalPagesList = Math.ceil(totalGiveaway / GiveawayList)

  const paginateList = (pageNumber) => setCurrentPageList(pageNumber)

  const renderPaginationButtonsList = () => {
    const numButtonsToShow = 3

    let startPage = currentPageList - Math.floor(numButtonsToShow / 2)
    startPage = Math.max(startPage, 1)

    let endPage = startPage + numButtonsToShow - 1
    if (endPage > totalPagesList) {
      endPage = totalPagesList
      startPage = Math.max(endPage - numButtonsToShow + 1, 1)
    }

    return [...Array(endPage - startPage + 1)].map((_, index) => {
      const pageNumber = startPage + index
      return (
        <button
          type="button"
          key={pageNumber}
          className={`pagination-buttons ${
            currentPageList === pageNumber ? 'active' : ''
          }`}
          onClick={() => paginateList(pageNumber)}
        >
          {pageNumber}
        </button>
      )
    })
  }

  const handlePrevPageList = () => {
    setCurrentPageList((prevPage) => Math.max(prevPage - 1, 1))
  }

  const handleNextPageList = () => {
    setCurrentPageList((prevPage) => Math.min(prevPage + 1, totalPagesList))
  }

  const onClickEditPoll = (videoData) => {
    setShowPollModel(true)
    setIsEditing(true)
    setInputFields({
      ...inputFields,
      topic: videoData.topic,
      category_id: videoData.category_id,
      sub_category_id: videoData.sub_category_id,
      option_1: videoData.option_1,
      option_2: videoData.option_2,
      option_3: videoData.option_3,
      option_4: videoData.option_4,
      app_id: videoData.app_data,
      poll_id: videoData.poll_id
    })
    setInitialValues({
      topic: videoData.topic,
      category_id: videoData.category_id,
      sub_category_id: videoData.sub_category_id,
      option_1: videoData.option_1,
      option_2: videoData.option_2,
      option_3: videoData.option_3,
      option_4: videoData.option_4,
      app_id: videoData.app_data,
      poll_id: videoData.poll_id
    })
    setPlatformTags(videoData.app_data)
    setSteps([
      videoData.option_1,
      videoData.option_2,
      videoData.option_3,
      videoData.option_4
    ])
    MainCategory({ category_id: videoData.category_id })
    SubCategory({ category_id: videoData.category_id })
  }

  const isAppsSelected = inputFields.app_id?.length > 0

  useEffect(() => {
    window.scroll(0, 0)
    MainCategory()
    getPolls()
    const handleClickOutside = (event) => {
      if (
        componentRef.current &&
        !componentRef.current.contains(event.target)
      ) {
        setShowPollModel(false)
        setShowPollPreview(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  useEffect(() => {
    if (currentPage > totalPages) {
      setCurrentPage(totalPages > 0 ? totalPages : 1)
    }
  }, [totalPages, currentPage])

  useEffect(() => {
    checkIfPublishEnabled(steps)
  }, [inputFields, steps])

  useEffect(() => {
    if (
      ShowPollModel ||
      ShowPollPreview ||
      ShowProfileModel
    ) {
      lockScroll()
    } else {
      unlockScroll()
    }

    return () => {
      unlockScroll()
    }
  }, [
    ShowPollModel,
    ShowPollPreview,
    ShowProfileModel
  ])

  return (
    <div className="">
      <div className="discussions-wrapper-header common-contnt-wrapper">
        {showCount && (
          <div className="tag_link">
            {/* <div className="video_button"> */}
            <h2>Polls</h2>

            <div className="add-video-rows">
              <CommonButton
                label="Add New Poll"
                imgSrc={AddIco}
                onClick={HandlePollDetail}
                isPrimary
                className="add_new_content"
              />
            </div>
            {/* </div> */}
          </div>
        )}
      </div>
      <div
        className={
          showCount !== 1 &&
          'recent-video-component recent-shots-component common-card-body add-shots-component'
        }
      >
        {showCount !== 1 && (
          <div className="discussions-wrapper-header common-contnt-wrapper mb-40">
            {showCount !== 1 && <h2>Polls</h2>}
            {showCount !== 1 && (
              <div className="items-filter">
                <div className="position-relative input-position">
                  <CommonInput
                    placeholder="Search Polls...."
                    onChange={(e) => {
                      handleSearch(e.target.value)
                    }}
                  />
                </div>
              </div>
            )}
          </div>
        )}
        {!showCount && (
          <div className="add-video-rows main_btn mt-2">
            <CommonButton
              label="Add New Poll"
              imgSrc={AddIco}
              onClick={HandlePollDetail}
              isPrimary
              className="add_new_content"
            />
          </div>
        )}
        {pollsData.length === 0 && !isLoading ? (
          <div className="add-polls-container">
            <div className="add-polls-item common-card-body">
              <div className="position-relative polls-item-head">
                <h6 className="add-value font-hidden">Heading 2</h6>
                <button
                  type="button"
                  className="add-item-btn"
                  onClick={HandlePollDetail}
                >
                  <img src={Plus} alt="add poll" />
                </button>
              </div>
              {/* <div className="polls-header">
                <p className="add-value font-hidden">Placeholder Text 1</p>
                <div className="add-value font-hidden">Placeholder Text 2</div>
                <div className="d-flex column-gap-4 w-auto ms-5">
                  <h6 className="add-value font-hidden">Heading 1</h6>
                  <h6 className="add-value font-hidden">Heading 2</h6>
                </div>
              </div> */}
              <div className="polls-item d-flex align-items-center justify-content-between">
                <div className="polls-item-txt add-value">
                  <p className="font-hidden">Poll Item Text 1</p>
                </div>
                <h5 className="add-value font-hidden">Poll Item 1</h5>
              </div>
              <div className="polls-item d-flex align-items-center justify-content-between">
                <div className="polls-item-txt add-value">
                  <p className="font-hidden">Poll Item Text 2</p>
                </div>
                <h5 className="add-value font-hidden">Poll Item 2</h5>
              </div>
              <div className="polls-item d-flex align-items-center justify-content-between">
                <div className="polls-item-txt add-value">
                  <p className="font-hidden">Poll Item Text 3</p>
                </div>
                <h5 className="add-value font-hidden">Poll Item 3</h5>
              </div>
              <div className="polls-item d-flex align-items-center justify-content-between">
                <div className="polls-item-txt add-value">
                  <p className="font-hidden">Poll Item Text 4</p>
                </div>
                <h5 className="add-value font-hidden">Poll Item 4</h5>
              </div>
            </div>
          </div>
        ) : isLoading ? (
          <div className="polls-item common-card-body position-relative">
            <div className="">
              <Skeleton width={50} height={25} />
            </div>
            <div className="poll-header">
              <p className="poll_topic">
                <Skeleton width={600} height={30} />
              </p>
              <div className="discussion-group">
                <Skeleton width={25} height={25} />
              </div>
              <div className="discussion-comment">
                <Skeleton width={25} height={25} />
                <Skeleton width={25} />
              </div>
              <div className="discussion-button">
                <button type="button">
                  <Skeleton width={10} height={35} />
                </button>
              </div>
            </div>

            <div className="polls-option mb-2">
              <p className="profress-line border border-0 shadow-none rounded-0">
                <Skeleton width={850} height={32} />
              </p>
              <div className="discussion-comment count-vote-poll-skeleton">
                <Skeleton circle width={25} height={25} />
                <Skeleton width={50} />
              </div>
            </div>
            <div className="polls-option mb-2">
              <p className="profress-line border border-0 shadow-none rounded-0">
                <Skeleton width={850} height={32} />
              </p>
              <div className="discussion-comment count-vote-poll-skeleton">
                <Skeleton circle width={25} height={25} />
                <Skeleton width={50} />
              </div>
            </div>
            <div className="polls-option mb-2">
              <p className="profress-line border border-0 shadow-none rounded-0">
                <Skeleton width={850} height={32} />
              </p>
              <div className="discussion-comment count-vote-poll-skeleton">
                <Skeleton circle width={25} height={25} />
                <Skeleton width={50} />
              </div>
            </div>
            <div className="polls-option mb-2">
              <p className="profress-line border border-0 shadow-none rounded-0">
                <Skeleton width={850} height={32} />
              </p>
              <div className="discussion-comment count-vote-poll-skeleton">
                <Skeleton circle width={25} height={25} />
                <Skeleton width={50} />
              </div>
            </div>
          </div>
        ) : (
          <div className="content-manager-Polls-wrapper mt-20">
            {pollsData.map((item, index) => {
              if (index < (showCount || totalVideos)) {
                const totalVotes =
                  item.option_1_vote +
                  item.option_2_vote +
                  item.option_3_vote +
                  item.option_4_vote

                const optionPercentages = [
                  ((item.option_1_vote / totalVotes) * 100).toFixed(2),
                  ((item.option_2_vote / totalVotes) * 100).toFixed(2),
                  ((item.option_3_vote / totalVotes) * 100).toFixed(2),
                  ((item.option_4_vote / totalVotes) * 100).toFixed(2)
                ]

                return (
                  <div
                    className="polls-item common-card-body position-relative"
                    key={item.tag}
                  >
                    <div className="image-buttons z-1">
                      <label>
                        <button
                          type="button"
                          onClick={() => onClickEditPoll(item)}
                          className="d-none"
                        />
                      </label>
                      <button
                        type="button"
                        className="p-0"
                        onClick={() => {
                          setUpdatingIndex(index)
                          ShowConfirm(item.poll_id)
                        }}
                      />
                    </div>
                    <ConfirmationModal
                      isVisible={ShowConfirmModel && updatingIndex === index}
                      onConfirm={deletePoll}
                      onCancel={handleCancel}
                      message="Do you want to delete this?"
                      isLoading={loading}
                    />
                    {loading && updatingIndex === index && <Loader />}
                    <div className="like-tag">
                      <div className="polls-tag">
                        <span>
                          {/* {item.tag} */}
                          Web Design
                        </span>
                      </div>
                      <div className="poll_like_bg">
                        <button
                          type="button"
                          className="video_like"
                          onClick={() => pollLikeData(item.poll_id)}
                        >
                          {item.is_like ? (
                            <img src={Likes} alt="Unlike" />
                          ) : (
                            <img src={UnLikes} alt="Like" />
                          )}
                        </button>
                      </div>
                    </div>
                    <div className="poll-header">
                      <p className="poll_topic">{item.topic}</p>
                      <div className="comment_people">
                        <div className="discussion-group">
                          {item?.total_votes > 0 &&
                          voteList
                            .slice(0, 3)
                            .map((vote, i) => (
                              <img
                                key={i}
                                src={s3ImagePath + vote.profile_icon}
                                alt={vote.user_name}
                              />
                            ))}
                          <img
                            src={GroupDiscussion}
                            alt="Group Discussion"
                            onClick={() => {
                            if (item.total_votes > 0) {
                              showList(item?.poll_id)
                            }
                          }}
                          />
                          {ShowProfileModel && (
                          <div className="add-item-model edit-profile">
                            <div
                              className="add-item-content"
                              ref={componentRef}
                            >
                              <div className="d-flex justify-content-between header">
                                <h3>Voting List</h3>
                                <button
                                  type="button"
                                  className="border-0 bg-none p-0"
                                  onClick={close}
                                >
                                  <span>
                                    <img
                                      className="close-btns"
                                      src={CloseIco}
                                      alt="clock icon"
                                    />
                                  </span>
                                </button>
                              </div>

                              <div className="scroll-bars">
                                <div className="item-details-input pt-0">
                                  <div className="list_data">
                                    {voteListData.map((list, index) => {
                                      const calculatedIndex =
                                        (currentPageList - 1) * GiveawayList +
                                        index +
                                        1
                                      return (
                                        <div
                                          className="d-flex justify-content-start list_table"
                                          key={index}
                                        >
                                          <span className="number_index">
                                            {calculatedIndex}
                                          </span>
                                          <img
                                            src={
                                              s3ImagePath + list.profile_icon
                                            }
                                            className="profile-pic"
                                            alt="profile"
                                          />
                                          <span className="user-name">
                                            {list.user_name}
                                          </span>
                                        </div>
                                      )
                                    })}
                                  </div>
                                </div>
                              </div>
                              {totalPagesList > 1 && (
                                <div className="pagination-containers-list">
                                  <button
                                    type="button"
                                    className={`pagination-button ${
                                      currentPageList === 1 ? 'disabled' : ''
                                    }`}
                                    onClick={handlePrevPageList}
                                    disabled={currentPageList === 1}
                                  >
                                    Prev
                                  </button>
                                  {renderPaginationButtonsList()}
                                  <button
                                    type="button"
                                    className={`pagination-button ${
                                      currentPageList === totalPagesList
                                        ? 'disabled'
                                        : ''
                                    }`}
                                    onClick={handleNextPageList}
                                    disabled={
                                      currentPageList === totalPagesList
                                    }
                                  >
                                    Next
                                  </button>
                                </div>
                              )}
                            </div>
                          </div>
                        )}
                        </div>
                        <div className="discussion-comment">
                          <img className="poll_comment_icon" src={Comment} alt="comment icon" />
                          {item.total_votes}
                        </div>
                        <div className="discussion-button">
                          <button type="button">
                            <img src={IconsMore} alt="see more" />
                          </button>
                        </div>
                      </div>
                    </div>
                    {[
                      item.option_1,
                      item.option_2,
                      item.option_3,
                      item.option_4
                    ]
                      .filter((option) => option !== '')
                      .map((option, optIndex) => (
                        <div
                          className="polls-option"
                          key={optIndex}
                          onClick={() => {
                            if (item.is_vote === 0) {
                              voteAdd(item.poll_id, optIndex + 1)
                            }
                          }}
                        >
                          <ProgressBar
                            customLabel={`${option}`}
                            className="profress-line"
                            baseBgColor="linear-gradient(134deg, rgba(0, 21, 151, 0.1) 3.03%, rgba(1, 16, 77, 0.1) 47.1%, rgba(2, 12, 28, 0.1) 80.14%, rgba(2, 11, 9, 0.1) 96.45%)"
                            height={43}
                            completed={optionPercentages[optIndex]}
                            borderRadius={15}
                            bgColor={
                              item.total_votes > 0
                                ? 'linear-gradient(134deg, rgba(0, 21, 151, 1) 3.03%, rgba(1, 16, 77, 1) 47.1%, rgba(2, 12, 28, 1) 80.14%, rgba(2, 11, 9, 1) 96.45%)'
                                : 'linear-gradient(134deg, rgba(0, 21, 151, 0.1) 3.03%, rgba(1, 16, 77, 0.1) 47.1%, rgba(2, 12, 28, 0.1) 80.14%, rgba(2, 11, 9, 0.1) 96.45%)'
                            }
                            isLabelVisible={false}
                            labelClassName={
                              item[`option_${optIndex + 1}_vote`] > 0
                                ? 'active_label'
                                : 'polls-label'
                            }
                          />

                          <div className="polls-group">
                            <div className="discussion-group">
                              {item?.total_votes > 0 &&
                                voteList
                                  .filter(
                                    (vote) => vote.option === optIndex + 1
                                  )
                                  .slice(0, 3)
                                  .map((vote, i) => (
                                    <img
                                      key={i}
                                      src={s3ImagePath + vote.profile_icon}
                                      alt={vote.user_name}
                                    />
                                  ))}
                              <img
                                src={GroupDiscussion}
                                alt="Group Discussion"
                              />
                            </div>
                            {item[`option_${optIndex + 1}_vote`]}
                            {' '}
                            votes
                          </div>
                        </div>
                      ))}
                  </div>
                )
              }
              return null
            })}
          </div>
        )}

        {totalPages > 1 && (
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
          />
        )}
      </div>
      {ShowPollModel && (
        <div className="add-item-model">
          <div className="add-item-content" ref={componentRef}>
            <div className="d-flex justify-content-between header">
              <h3>Enter Poll Details</h3>
              <button
                type="button"
                className="border-0 bg-none p-0"
                onClick={HandleCancel}
              >
                <span>
                  <img className="close-btns" src={CloseIco} alt="" />
                </span>
              </button>
            </div>
            <div className="item-details-input pt-0">
              <div className="scroll-bar pb-0">
                <div>
                  <label>Topic Statement</label>
                  <CommonInput
                    name="topic"
                    placeholder="Type here"
                    onChange={handleChange}
                    className="mb-3"
                    maxLength={200}
                    value={capitalizeFirstLetter(inputFields.topic)}
                  />
                </div>
                <div className="input-grid">
                  <div>
                    <label>Select Category</label>
                    <Dropdown
                      title={getCategoryName(inputFields.category_id)}
                      onChanged={(d) => {
                        SubCategory({
                          category_id: d.id
                        })
                        setInputFields({
                          ...inputFields,
                          category_id: d.id
                        })
                      }}
                      Data={category.map((d) => ({
                        title: d.category_name,
                        id: d.id
                      }))}
                      name="category_id"
                      // customClass={
                      //   inputFields.category_id
                      //     ? 'selected-class'
                      //     : 'giveaway-dropdown'
                      // }
                      customClass="store_add_dropdown"
                    />
                  </div>
                  <div>
                    <label>Select Sub-category</label>
                    <Dropdown
                      title={getSubCategoryNameById(
                        inputFields.sub_category_id
                      )}
                      onChanged={(d) => setInputFields({
                          ...inputFields,
                          sub_category_id: d.id
                        })}
                      Data={subCategory.map((d) => ({
                        title: d.sub_category_name,
                        id: d.sub_category_id
                      }))}
                      name="sub_category_id"
                      // customClass={inputFields.sub_category_id ?
                      // 'selected-class' : 'giveaway-dropdown'}
                      customClass="store_add_dropdown"
                    />
                  </div>
                </div>
                {nonEmptySteps.map((enteredStep, index) => (
                  <div key={index} className="mt-4 d-flex recipies-step">
                    <label className="label-poll">
                      Option
                      {index + 1}
                      :
                    </label>
                    {editIndex === index ? (
                      <div className="d-flex align-items-center position-relative">
                        <CommonInput
                          onChange={(e) => setEditValue(e.target.value)}
                          maxLength={70}
                          value={editValue}
                        />
                        <div className="image-buttons z-1">
                          <label>
                            <button
                              type="button"
                              onClick={handleSaveEditStep}
                              className="d-none"
                            />
                          </label>
                        </div>
                      </div>
                    ) : (
                      <div className="text-cancel-btn">
                        <span onClick={() => handleEditStep(index)}>
                          {enteredStep}
                        </span>
                        <button
                          type="button"
                          className="remove-tag ml-5"
                          onClick={() => handleRemoveStep(index)}
                        >
                          <img src={Remove} alt="" />
                        </button>
                      </div>
                    )}
                  </div>
                ))}
                {nonEmptySteps.length < 4 && (
                  <div className="mt-4">
                    <label>
                      Option
                      {nonEmptySteps.length + 1}
                    </label>
                    <CommonInput
                      placeholder="Type here..."
                      className="mt-2"
                      onChange={handleInputChangeStep}
                      maxLength={70}
                      value={step}
                    />
                  </div>
                )}
                {nonEmptySteps.length < 4 && (
                  <button
                    type="button"
                    className="primary-btn-model-store add_btn"
                    onClick={handleAddStep}
                  >
                    <img src={plusBtn} className="me-2" alt="Add" />
                    Add Poll Option
                  </button>
                )}
              </div>
            </div>
            <div className="item-details-input pt-0">
              <div className="button-row video_sticky_btn">
                <CommonButton
                  label="Back"
                  onClick={HandleCancel}
                  isPrimary={false}
                  context="Profile"
                />
                <CommonButton
                  label="Preview"
                  type="button"
                  isPrimary
                  disabled={!isPublishEnabled}
                  onClick={HandlePollsPreview}
                  context="Profile"
                />
              </div>
            </div>
          </div>
        </div>
      )}
      {ShowPollPreview && (
        <div className="add-item-model">
          <div
            className="add-item-content d-flex flex-column"
            ref={componentRef}
          >
            <div className="d-flex justify-content-between header">
              <h3>Enter Poll Details</h3>
              <button
                type="button"
                className="border-0 bg-none p-0"
                onClick={HandleCancel}
              >
                <span>
                  <img className="close-btns" src={CloseIco} alt="" />
                </span>
              </button>
            </div>
            <div className="scroll-bar pb-0">
              <div className="item-details-input pt-0">
                <div className="polls-publish-detail">
                  <span className="polls-label-publish">
                    <span>Web Design</span>
                  </span>
                  <p className="polls-text-publish">{inputFields.topic}</p>
                  {steps
                    .filter((poll) => poll !== '')
                    .map((poll) => (
                      <div className="polls-option w-100" key={poll.index}>
                        <CommonInput type="checkbox" className="m-0" />
                        <ProgressBar
                          customLabel={poll}
                          className="profress-line"
                          baseBgColor="#fff"
                          height={43}
                          completed={poll.percantage}
                          borderRadius={15}
                          bgColor="linear-gradient(120deg, rgba(0, 203, 164, 0.1) 0%, rgba(0, 145, 126, 0.1) 90.19%)"
                          isLabelVisible={false}
                          labelClassName="polls-label"
                        />
                      </div>
                    ))}
                </div>
                <PlatformTag
                  setPlatformTags={setPlatformTags}
                  Platformtags={Platformtags}
                  onItemSelect={(value) => getSelectedAppId(value)}
                />
              </div>

            </div>
            <div className="item-details-input">
              <div className="button-row video_sticky_btn">
                <CommonButton
                  label="Cancel"
                  type="button"
                  onClick={HandleCancel}
                  isPrimary={false}
                  disabled={loading}
                  context="Profile"
                />
                <CommonButton
                  label={isAppsSelected ? 'Publish' : 'Upload'}
                  loading={loading}
                  type="button"
                  onClick={publishPoll}
                  className={`primary-btn-model-store ${loading ? 'loading' : ''}`}
                  isPrimary
                  disabled={loading}
                  context="Profile"
                />
              </div>
            </div>
          </div>
        </div>
      )}
      {pollsData.length >= 1 && showCount && (
        <div
          className="view_all text-end mt-4"
          onClick={() => navigate(`/community-builder/${id}`, { state: { defaultIndex: 3 } })}
        >
          See All
        </div>
      )}
    </div>
  )
}
export default PollsComponent
