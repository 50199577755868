/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
/* eslint-disable react/jsx-key */
import React, { useEffect, useRef, useState } from 'react'
// import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import Skeleton from 'react-loading-skeleton'
// import Cropper from 'react-cropper'
// import 'cropperjs/dist/cropper.css'
import Building from '../../images/building.svg'
import ProfileLogo from '../../images/gbn-one.svg'
import Redirect from '../../images/redirect.svg'
// import Desk from '../../images/desk.svg'
// import Tab from '../../images/tab.svg'
// import Mob from '../../images/mob.svg'
// import View from '../../images/view.svg'

import MenuIcon from '../../images/MenuIcon.svg'
import LeftIco from '../../images/LeftIco.svg'
import plusrounded from '../../images/plus-rounded.svg'
import CloseIco from '../../images/CloseIco.svg'
import './style.css'
import {
  editCreatorProfile,
  imageUpload,
  creatorProfileDetails,
  s3ImagePath,
  followerListData,
  followCreator
} from '../../services/Service'
import { dataDecrypt } from '../../services/http-services'
import { formatViews, lockScroll, unlockScroll } from '../../helpers/Utility'
import CommonImageUpload from '../ImageCroper/StoreImageCroper'
import SelectImg from '../../images/selectimg.svg'
import CommonButton from '../ButtonComponent/ButtonCommon'
import CommonInput from '../InputComponet/CommonInput'

const ProfileHeadCreator = () => {
  const [profile, setProfile] = useState([])
  const [profileImg, setProfileImg] = useState(null)
  const [profileImgObj, setProfileImgObj] = useState(null)
  const [ShowProfileModel, setShowProfileModel] = useState(false)
  const [isEdited, setIsEdited] = useState(false)
  const [profileImgUrl, setProfileImgUrl] = useState(null)
  const [inputFields, setInputFields] = useState({
    icon: '',
    description: '',
    social_media: []
  })
  const [socialMediaLinks, setSocialMediaLinks] = useState([])
  const [allFollowerListData, setAllFollowerListData] = useState([])
  const [showFollowerList, setShowFollowList] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isFollowing, setIsFollowing] = useState(false)
  const [isFollowerListLoading, setIsFollowerListLoading] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)

  const componentRef = useRef()

  const profileGet = async () => {
    setIsLoading(true)
    const dataId = localStorage.getItem('app_id')
    const parsedData = JSON.parse(dataId)
    const platFormData = {
      app_id: parsedData.id
    }
    await creatorProfileDetails(platFormData).then((res) => {
      if (res?.data?.success) {
        const data = dataDecrypt(res.data.data)
        localStorage.setItem('profileData', JSON.stringify(data[0]))
        allFollowerList(data[0]?.user_id)
        if (data[0]) {
          const socialMediaLinks = Array.isArray(data[0]?.social_media_links)
            ? data[0].social_media_links
            : []

          setProfile(data)
          setInputFields({
            icon: data[0]?.profile_icon,
            description: data[0]?.description,
            social_media: socialMediaLinks
          })
          setSocialMediaLinks(socialMediaLinks)
          setProfileImg(data[0]?.profile_icon)
        }
        setIsLoading(false)
      }
    })
  }

  const clearValue = () => {
    getStoreLink('')
    setProfileImgUrl()
  }

  const handleInputChange = (index, value) => {
    const updatedLinks = [...socialMediaLinks]
    updatedLinks[index] = value
    setSocialMediaLinks(updatedLinks)
    setInputFields({ ...inputFields, social_media: updatedLinks })
  }

  const EditProfile = (profileData) => {
    setShowProfileModel(true)
    setInputFields({
      ...inputFields,
      description: profileData.description,
      store_link: profileData.store_link,
      icon: profileData.profile_icon
    })
    setProfileImg(profileData.profile_icon)
  }

  const maxFileSizeMB = 8
  const maxFileSizeBytes = maxFileSizeMB * 1024 * 1024

  const handleFileChange = (file) => {
    setIsEdited(true)
    if (file.size > maxFileSizeBytes) {
      toast.error(`File size exceeds ${maxFileSizeMB} MB`)
      return
    }
    const reader = new FileReader()
    reader.onload = (e) => {
      setProfileImg(file)
      setProfileImgUrl(e.target.result)
      setProfileImgObj(file)
    }
    reader.readAsDataURL(file)
  }

  const handleChange = (e) => {
    setInputFields({ ...inputFields, [e.target.name]: e.target.value })
    setIsEdited(true)
  }

  const HandleCancel = () => {
    setShowProfileModel(false)
    setIsEdited(false)
    clearValue()
  }

  const addInputField = () => {
    const updatedLinks = [...socialMediaLinks, '']
    setSocialMediaLinks(updatedLinks)
    setInputFields({ ...inputFields, social_media: updatedLinks })
    setIsEdited(true)
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    finishSubmit()
    setShowProfileModel(false)
    clearValue()
  }

  const finishSubmit = () => {
    if (profileImgObj) {
      imageUpload(profileImgObj).then((res) => {
        if (res.data.success) {
          inputFields.icon = dataDecrypt(res.data.data)
          creatorProfile()
        } else {
          toast.error(res?.data?.message)
        }
      })
    } else {
      creatorProfile()
    }
  }

  const creatorProfile = () => {
    const dataId = localStorage.getItem('app_id')
    const parsedData = JSON.parse(dataId)
    const platFormData = {
      app_id: parsedData.id,
      icon: inputFields.icon,
      description: inputFields?.description,
      social_media: inputFields?.social_media
    }
    editCreatorProfile(platFormData, inputFields).then((res) => {
      if (res.data.success) {
        profileGet()
        toast.success(res?.data?.message)
        setIsEdited(false)
      } else {
        toast.error(res?.data?.message)
      }
    })
  }

  const handleDeleteLink = (index) => {
    const updatedLinks = socialMediaLinks.filter((_, i) => i !== index)
    setSocialMediaLinks(updatedLinks)
    setInputFields({ ...inputFields, social_media: updatedLinks })
  }

  const allFollowerList = (user_id) => {
    setIsFollowerListLoading(true)
    const dataId = localStorage.getItem('app_id')
    const parsedData = JSON.parse(dataId)
    const platform = {
      app_id: parsedData.id,
      creator_user_id: user_id
    }
    followerListData(platform).then((res) => {
      if (res.data.success) {
        setAllFollowerListData(dataDecrypt(res.data.data))
        setIsFollowerListLoading(false)
      }
    })
  }
  const followCreatorData = (user_id) => {
    const dataId = localStorage.getItem('app_id')
    const parsedData = JSON.parse(dataId)
    const platform = {
      app_id: parsedData.id,
      creator_user_id: user_id
    }
    followCreator(platform).then((res) => {
      if (res.data.success) {
        setIsFollowing(!isFollowing)
        toast.success(res?.data?.message)
      }
    })
  }

  const followOpen = () => {
    setShowFollowList(true)
  }

  const handleCancel = () => {
    setShowFollowList(false)
  }

  const getStoreLink = (storeLink) => {
    if (!storeLink) return undefined
    return storeLink.startsWith('http') ? storeLink : `http://${storeLink}`
  }

  const videosPerPage = 6
  const indexOfLastVideo = currentPage * videosPerPage
  const indexOfFirstVideo = indexOfLastVideo - videosPerPage
  const followerList = allFollowerListData.slice(
    indexOfFirstVideo,
    indexOfLastVideo
  )
  const totalVideos = allFollowerListData.length
  const totalPages = Math.ceil(totalVideos / videosPerPage)

  const paginate = (pageNumber) => setCurrentPage(pageNumber)

  const renderPaginationButtons = () => {
    const numButtonsToShow = 3

    let startPage = currentPage - Math.floor(numButtonsToShow / 2)
    startPage = Math.max(startPage, 1)

    let endPage = startPage + numButtonsToShow - 1
    if (endPage > totalPages) {
      endPage = totalPages
      startPage = Math.max(endPage - numButtonsToShow + 1, 1)
    }

    return [...Array(endPage - startPage + 1)].map((_, index) => {
      const pageNumber = startPage + index
      return (
        <button
          type="button"
          key={pageNumber}
          className={`pagination-buttons ${
            currentPage === pageNumber ? 'active' : ''
          }`}
          onClick={() => paginate(pageNumber)}
        >
          {pageNumber}
        </button>
      )
    })
  }

  const handlePrevPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1))
  }

  const handleNextPage = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages))
  }

  const displayedFollowers = allFollowerListData.slice(0, 4)
  const displayedNames = allFollowerListData.slice(0, 2)

  useEffect(() => {
    profileGet()
  }, [])

  useEffect(() => {
    if (ShowProfileModel || showFollowerList) {
      lockScroll()
    } else {
      unlockScroll()
    }

    return () => {
      unlockScroll()
    }
  }, [ShowProfileModel, showFollowerList])

  return (
    <div>
      {isLoading ? (
        <div className="profile-head-builder">
          <div className="profile-builder-header mb-20">
            <button type="button">
              <Skeleton width={25} height={25} />
            </button>
            <div className="profile-heading">
              <Skeleton width={35} height={35} />
              <span>
                <Skeleton width={150} height={25} />
              </span>
            </div>
            <button type="button">
              <Skeleton width={25} height={25} />
            </button>
          </div>

          <div className="profile-builder-main">
            <div className="profile-personal-detail">
              <Skeleton className="profile_ske_icon" />
              <div className="about-personal-profile">
                <h2>
                  <Skeleton width={150} height={20} />
                </h2>
                <h3>
                  <Skeleton width={180} height={20} />
                </h3>
                <p>
                  <Skeleton width={150} height={20} />
                </p>
                <span>
                  <Skeleton width={130} height={20} />
                </span>
              </div>
            </div>
            <div className="profile-account-details">
              <ul className="about-account-insignt">
                <li>
                  <h4>
                    <Skeleton width={20} />
                  </h4>
                  <p>
                    <Skeleton width={50} height={20} />
                  </p>
                </li>
                <li>
                  <h4>
                    <Skeleton width={20} />
                  </h4>
                  <p className="Follower_text">
                    <Skeleton width={50} height={20} />
                  </p>
                </li>
                <li>
                  <h4>
                    <Skeleton width={20} />
                  </h4>
                  <p>
                    <Skeleton width={50} height={20} />
                  </p>
                </li>
              </ul>
              <div className="follow-connenct">
                <div className="buttons-group">
                  <Skeleton
                    className="rounded-5 profile-skeleton-btn"
                    height={40}
                  />
                  <Skeleton
                    className="rounded-5 profile-skeleton-btn"
                    height={40}
                  />
                </div>
                <div>
                  <Skeleton className="rounded-5" width={60} height={60} />
                </div>
              </div>
              <div className="follwer-shows">
                <div className="follower-img-group d-flex">
                  <Skeleton
                    className="rounded-5 skeleton-img-group"
                    width={30}
                    height={30}
                  />
                  <Skeleton
                    className="rounded-5 skeleton-img-group"
                    width={30}
                    height={30}
                  />
                  <Skeleton
                    className="rounded-5 skeleton-img-group"
                    width={30}
                    height={30}
                  />
                  <Skeleton
                    className="rounded-5 skeleton-img-group"
                    width={30}
                    height={30}
                  />
                </div>
                <p>
                  <span className="gredient-txt">
                    <Skeleton className="follow-text" height={20} />
                  </span>
                </p>
              </div>
              <div className="profile-redirect">
                <Skeleton width={25} height={25} />
              </div>
            </div>
          </div>
        </div>
      ) : (
        profile.map((data, i) => (
          <div key={i} className="profile-head-builder">
            <div className="profile-builder-header mb-20">
              <button type="button">
                <img alt="" src={LeftIco} />
              </button>
              <div className="profile-heading">
                <img alt="" src={Building} />
                <span>{data.user_name}</span>
              </div>
              <button type="button" onClick={() => EditProfile(data)}>
                <img alt="" src={MenuIcon} />
              </button>
            </div>

            <div className="profile-builder-main">
              <div className="profile-personal-detail">
                <div className="profile-build-image">
                  <img alt="" src={s3ImagePath + data.profile_icon} />
                </div>
                <div className="about-personal-profile">
                  <h2>{data.user_name}</h2>
                  <h3>FITNESS & GESUNDHEIT</h3>
                  <p>
                    {' '}
                    {data.description.slice(0, 65)}
                    {data.description.length > 65 && '...'}
                  </p>
                  <span>Your Personal Trainer</span>
                </div>
              </div>
              <div className="profile-account-details">
                <ul className="about-account-insignt">
                  <li>
                    <h4>{formatViews(data.total_post)}</h4>
                    <p>Posts</p>
                  </li>
                  <li>
                    <h4>{formatViews(data.total_followers)}</h4>
                    {/* <p className="Follower_text" onClick={() => allFollowerList(data.user_id)}>Followers</p> */}
                    <p
                      className="Follower_text"
                      onClick={() => followOpen(data.user_id)}
                    >
                      Followers
                    </p>
                  </li>
                  <li>
                    <h4>{formatViews(data.total_likes)}</h4>
                    <p>Likes</p>
                  </li>
                </ul>
                {showFollowerList && (
                  <div className="add-item-model edit-profile">
                    <div className="add-item-content">
                      <div className="d-flex justify-content-between header">
                        <h3>Follower List</h3>
                        {/* <div className="items-filter">
                        <div className="position-relative input-position">
                          <input
                            placeholder="Search Follower"
                              onChange={(e) => {
                              handleSearch(e.target.value)
                            }}
                          />
                        </div>
                      </div> */}
                        <button
                          type="button"
                          className="border-0 bg-none p-0"
                          onClick={handleCancel}
                        >
                          <span>
                            <img className="close-btns" src={CloseIco} alt="" />
                          </span>
                        </button>
                      </div>

                      <div className="scroll-bars feed-scroll">
                        <div className="item-details-input pt-0">
                          <div className="list_data">
                            {isFollowerListLoading ? (
                              Array(6)
                                .fill(0)
                                .map((_, index) => (
                                  <div
                                    className="d-flex justify-content-start list_table"
                                    key={index}
                                  >
                                    <span className="number_index">
                                      <Skeleton
                                        className="rounded-3"
                                        width={20}
                                        height={20}
                                      />
                                    </span>
                                    <Skeleton circle width={45} height={45} />
                                    <span className="user-name">
                                      <Skeleton
                                        className="rounded-3"
                                        width={200}
                                        height={25}
                                      />
                                    </span>
                                  </div>
                                ))
                            ) : followerList.length > 0 ? (
                              followerList.map((item, index) => {
                                const calculatedIndex =
                                  (currentPage - 1) * videosPerPage + index + 1
                                return (
                                  <div
                                    className="d-flex justify-content-start list_table"
                                    key={index}
                                  >
                                    <span className="number_index">
                                      {calculatedIndex}
                                    </span>
                                    <img
                                      src={s3ImagePath + item.user_profile_icon}
                                      className="profile-pic"
                                      alt="profile"
                                    />
                                    <span className="user-name">
                                      {item.user_name}
                                    </span>
                                  </div>
                                )
                              })
                            ) : (
                              <div className="no-data-message">
                                No one follows you
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      {totalPages > 1 && (
                        <div className="pagination-containers-list">
                          <button
                            type="button"
                            className={`pagination-button ${
                              currentPage === 1 ? 'disabled' : ''
                            }`}
                            onClick={handlePrevPage}
                            disabled={currentPage === 1}
                          >
                            Prev
                          </button>
                          {renderPaginationButtons()}
                          <button
                            type="button"
                            className={`pagination-button ${
                              currentPage === totalPages ? 'disabled' : ''
                            }`}
                            onClick={handleNextPage}
                            disabled={currentPage === totalPages}
                          >
                            Next
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                )}
                <div className="follow-connenct">
                  <div className="buttons-group">
                    <button
                      type="button"
                      className="success-btn"
                      onClick={() => followCreatorData(data.user_id)}
                    >
                      {isFollowing ? 'Follow +' : 'Unfollow'}
                    </button>
                    <button type="button" className="primary-btn p-10">
                      Contact
                    </button>
                  </div>
                  <div>
                    <img alt="" src={ProfileLogo} />
                  </div>
                </div>
                <div className="follwer-shows">
                  <div className="follower-img-group">
                    {displayedFollowers.map((follower, index) => (
                      <img
                        key={index}
                        alt={`Follower ${index + 1}`}
                        src={s3ImagePath + follower.user_profile_icon}
                      />
                    ))}
                  </div>
                  <p>
                    <span className="gredient-txt">
                      {displayedNames.map((follower, index) => (
                        <React.Fragment key={index}>
                          <b>{follower.user_name}</b>
                          {index < displayedNames.length - 1 && ', '}
                        </React.Fragment>
                      ))}
                      {' und '}
                      <b>
                        {allFollowerListData.length - 2}
                        {' '}
                        weitere Personen
                      </b>
                      {' '}
                      sind Follower
                    </span>
                  </p>
                </div>
                <div className="profile-redirect">
                  <a
                    className="gredient-txt"
                    target="_blank"
                    href={getStoreLink(data?.social_media_links?.[0])}
                    rel="noreferrer"
                  >
                    <img alt="" src={Redirect} />
                    {data?.social_media_links?.[0]
                      ? data.social_media_links?.[0]
                      : 'No link'}
                  </a>
                </div>
              </div>
            </div>
          </div>
        ))
      )}
      {ShowProfileModel && (
        <div className="add-item-model edit-profile">
          <div
            className="add-item-content edit-creator px-0 pb-0"
            ref={componentRef}
          >
            <h3 className="text-center">Edit Creator Profile</h3>
            <form onSubmit={handleSubmit}>
              <div className="scroll-bar position-relative pb-2 profile_create_scroll">
                {/* <div
                  className="profile-image"
                  style={{
                    backgroundImage: `url(${profileImgUrl || s3ImagePath + profileImg})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center'
                  }}
                > */}

                {/* </div> */}
                <div className="profile-pics w-100 h-100 mt-4">
                  <div className="profile-pic-image">
                    <CommonImageUpload
                      handleImageChange={handleFileChange}
                      index={0}
                      forImage="create-profile"
                      className="profile-image-store"
                      selectedImg={profileImgUrl || s3ImagePath + profileImg}
                      defaultImgCamera={SelectImg}
                    />
                  </div>
                </div>
                <div className="item-details-input pt-0">
                  <div>
                    <label>Decription</label>
                    <CommonInput
                      name="description"
                      placeholder="Type here"
                      onChange={handleChange}
                      className="description-input"
                      value={inputFields.description}
                      maxLength={250}
                      isTextArea
                    />
                  </div>
                  <div className="input-grid">
                    <div>
                      <label>Social Media Link</label>
                      {socialMediaLinks && socialMediaLinks.length > 0 ? (
                        socialMediaLinks.map((link, index) => (
                          <div
                            key={index}
                            className="item-details-input p-0 d-flex gap-3"
                          >
                            <CommonInput
                              placeholder="Paste here"
                              className="mb-2"
                              onChange={(e) => handleInputChange(index, e.target.value)}
                              value={link}
                            />
                            <button
                              className="delete_icon"
                              type="button"
                              onClick={() => handleDeleteLink(index)}
                            />
                          </div>
                        ))
                      ) : (
                        <p>No Link</p>
                      )}
                      {socialMediaLinks.length < 3 ? (
                        <CommonButton
                          label="Add another"
                          imgSrc={plusrounded}
                          onClick={addInputField}
                          className="secondary-button mt-2 w-100"
                        />
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
              <div className="item-details-input pt-0">
                <div className="button-row edit-model video_sticky_btn">
                  <CommonButton
                    label="Cancel"
                    onClick={HandleCancel}
                    isPrimary={false}
                    context="Profile"
                  />
                  <CommonButton
                    label="Save"
                    type="submit"
                    isPrimary
                    disabled={!isEdited}
                    context="Profile"
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  )
}
export default ProfileHeadCreator
