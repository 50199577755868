/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { toast } from 'react-toastify'
import _ from 'lodash'
import Skeleton from 'react-loading-skeleton'
import { Progress } from 'antd'
import Dropdown from '../Dropdown/Dropdown'
import Upload from '../../images/shots-upload.svg'
import PlusTag from '../../images/Plus-tag.svg'
import Remove from '../../images/remove.svg'
import AddIco from '../../images/AddIco.svg'
import Likes from '../../images/like-fill.svg'
import UnLikes from '../../images/like-empty.svg'
import {
  addShotData,
  deleteShotData,
  editShotData,
  getShotLibrary,
  s3ImagePath,
  s3ImagePathCreator,
  shotCommentAddData,
  shotCommentData,
  shotCommentDeleteData,
  shotCommentEditData,
  shotCommentLikeData,
  urlSystemIcon
} from '../../services/Service'
import {
  compressVideo,
  dataDecrypt
} from '../../services/http-services'
import PlatformTag from './PlatformTag'
import Loader from '../Loader/Loader'
import CloseIco from '../../images/CloseIco.svg'
import Delete from '../../images/delete.svg'
import { capitalizeFirstLetter, debounce, lockScroll, timeDifference, unlockScroll } from '../../helpers/Utility'
import Pagination from '../Pagination/Pagination'
import ConfirmationModal from '../ConfirmationModal/ConfirmationModal'
import CommentPagination from '../Pagination/CommentPagination'
import Plus from '../../images/plus-rounded.svg'
import CommonButton from '../ButtonComponent/ButtonCommon'
import CommonInput from '../InputComponet/CommonInput'
import { useVideoProgress } from '../../providers/VideoProgressProvider'

const ShotsDetail = () => {
  const userData = JSON.parse(localStorage.getItem('userData'))
  const componentRef = useRef()
  const videoRef = useRef(null)

  // const [visibleItems, setVisibleItems] = useState(4);
  // const showMoreItems = () => {
  //   setVisibleItems((prevVisibleItems) => prevVisibleItems + 4);
  // };

  const [ShowShotsModel, setShowShotsModel] = useState(false)
  const [ShowShotsPreviewModel, setShowShotsPreviewModel] = useState(false)
  const [showShotCoverModel, setShowShotCoverModel] = useState(false)
  const [shotData, setShotData] = useState([])
  const [inputFields, setInputFields] = useState({
    app_id: [],
    attachment: '',
    cover_attachment: '',
    title: '',
    tags: [],
    search: ''
  })
  const [selectedVideo, setSelectedVideo] = useState('')
  const [selectedVideoObj, setSelectedVideoObj] = useState('')
  const [selectedFilePath, setSelectedFilePath] = useState('')
  const [Platformtags, setPlatformTags] = useState([])
  const [loading, setLoading] = useState(false)
  const [delLoading, setDelLoading] = useState(false)
  const [delShotId, setDelShotId] = useState()
  const [updatingIndex, setUpdatingIndex] = useState(null)
  const [ShowConfirmModel, setShowConfirmModel] = useState(false)
  const [isFormComplete, setIsFormComplete] = useState(false)
  const [isEditing, setIsEditing] = useState(false)
  const [previewModel, setPreviewModel] = useState(false)
  const [pause, setPause] = useState(false)
  const [isMuted, setIsMuted] = useState(false)
  const [editingIndex, setEditingIndex] = useState(null)
  const [initialValues, setInitialValues] = useState({})
  const [tags, setTags] = useState([])
  const [initialPlatformTags, setInitialPlatformTags] = useState([])
  const [isLoadingComments, setIsLoadingComments] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [comments, setComments] = useState([])
  const [selectedId, setSelectedId] = useState('')
  const [commentSection, setCommentSection] = useState(false)
  const [newComment, setNewComment] = useState('')
  const [showReplies, setShowReplies] = useState({})
  const [Replied, setShowReplied] = useState('')
  const [editCommentId, setEditCommentId] = useState(null)
  const [editReplyId, setEditReplyId] = useState(null)
  const [editedCommentText, setEditedCommentText] = useState('')
  const [editedReplyText, setEditedReplyText] = useState('')
  const [currentPageComment, setCurrentPageComment] = useState(1)
  const [isDeleting, setIsDeleting] = useState(false)
  const [commentIndex, setCommentIndex] = useState(null)
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)

  const { shotProgress, updateShotProgress } = useVideoProgress()

  const pauseHandel = () => {
    if (videoRef.current.paused) {
      videoRef.current.play()
    } else {
      videoRef.current.pause()
    }
    setPause(!pause)
  }

  const VolumeHandel = () => {
    videoRef.current.muted = !videoRef.current.muted
    setIsMuted(videoRef.current.muted)
  }

  const playPauseIcon = pause ? (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="10"
      height="11"
      viewBox="0 0 10 11"
      fill="none"
    >
      <path
        d="M8.89402 6.53751C8.84168 6.58958 8.64356 6.81642 8.46036 7.00607C6.892 8.51701 5.08607 9.76303 3.11392 10.695C2.76929 10.8448 2.40584 10.9474 2.03346 11C1.74485 11.0019 1.46007 10.9343 1.20346 10.8029C0.869688 10.617 0.606863 10.3268 0.455712 9.97732C0.361719 9.6542 0.284307 9.32648 0.223866 8.99553C0.0568802 7.84053 -0.0169171 6.67413 0.00324612 5.50742C-0.0087408 4.37758 0.0562202 3.2482 0.197669 2.12709C0.26836 1.71381 0.373439 1.30707 0.511757 0.911065C0.646164 0.640598 0.853148 0.412361 1.10981 0.251575C1.36648 0.0907899 1.66279 0.00373468 1.96609 0H2.01849C2.44039 0.066061 2.85075 0.191263 3.23733 0.371871C5.16678 1.2973 6.93408 2.52528 8.47159 4.00878C8.63059 4.16774 8.77917 4.33662 8.91648 4.51447C9.12318 4.79749 9.23328 5.139 9.23057 5.48881C9.23435 5.86465 9.11485 6.23151 8.89028 6.53379"
        fill="white"
      />
    </svg>
  ) : (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="9"
      height="11"
      viewBox="0 0 9 11"
      fill="none"
    >
      <path
        d="M8.00011 0C8.55233 0 9 0.447668 9 0.999895V9.99977C9 10.552 8.55233 10.9997 8.00011 10.9997V10.9997C7.44788 10.9997 7.00021 10.552 7.00021 9.99977V0.999895C7.00021 0.447668 7.44788 0 8.00011 0V0Z"
        fill="white"
      />
      <path
        d="M0.999895 0.000337601C1.55212 0.000337601 1.99979 0.352103 1.99979 0.786028V10.2143C1.99979 10.6482 1.55212 11 0.999895 11C0.447668 11 0 10.6482 0 10.2143V0.786028C0 0.352103 0.447668 0.000337601 0.999895 0.000337601Z"
        fill="white"
      />
    </svg>
  )

  const playVolumeIcon = isMuted ? (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="11"
      viewBox="0 0 15 11"
      fill="none"
    >
      <path
        d="M0.966462 5.50142C0.966462 6.3205 0.930479 7.43966 1.39751 7.85979C1.86453 8.27992 2.14213 8.14929 2.93902 8.21284C3.7359 8.27639 5.42769 10.8184 6.7308 10.0134C7.40684 9.4415 7.45909 8.24817 7.45909 5.50142C7.45909 2.75467 7.40685 1.55781 6.73733 0.989398C5.43096 0.184438 3.74571 2.72636 2.94555 2.78991C2.1454 2.85346 1.83841 2.75461 1.39751 3.14297C0.956607 3.53132 0.966462 4.67881 0.966462 5.50142Z"
        stroke="white"
        strokeWidth="1.11746"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.5 3.5C13.0598 4.08693 13.0359 4.28454 12.4995 4.99965C11.9632 5.71476 11.4397 6.41272 10.9995 6.99965"
        stroke="white"
        strokeWidth="1.11746"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.5 3.5C11.1087 4.10874 11.299 4.29904 12.0008 5.00078C12.7025 5.70253 13.3913 6.39126 14 7"
        stroke="white"
        strokeWidth="1.11746"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ) : (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="11"
      viewBox="0 0 14 11"
      fill="none"
    >
      <path
        d="M0.966462 5.50142C0.966462 6.3205 0.930479 7.43966 1.39751 7.85979C1.86453 8.27992 2.14213 8.14929 2.93902 8.21284C3.7359 8.27639 5.42769 10.8184 6.7308 10.0134C7.40684 9.4415 7.45909 8.24817 7.45909 5.50142C7.45909 2.75467 7.40685 1.55781 6.73733 0.989398C5.43096 0.184438 3.74571 2.72636 2.94555 2.78991C2.1454 2.85346 1.83841 2.75461 1.39751 3.14297C0.956607 3.53132 0.966462 4.67881 0.966462 5.50142Z"
        stroke="white"
        strokeWidth="1.11746"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.5059 1.43457C12.273 2.62607 12.6843 4.04296 12.6843 5.49468C12.6843 6.94639 12.273 8.36329 11.5059 9.55478"
        stroke="white"
        strokeWidth="1.11746"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.96289 3.04004C10.36 3.78848 10.569 4.6362 10.569 5.499C10.569 6.36179 10.36 7.20959 9.96289 7.95804"
        stroke="white"
        strokeWidth="1.11746"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )

  const ShowShots = () => {
    setShowShotsModel(true)
    clearValue()
  }
  const HandleShotCover = () => {
    setShowShotsModel(false)
    setShowShotsPreviewModel(true)
  }

  const HandleClose = () => {
    clearValue()
    setShowShotsModel(false)
    setShowShotCoverModel(false)
    setShowShotsPreviewModel(false)
    setPreviewModel(false)
    setIsEditing(false)
  }

  const HandleCancelPreview = () => {
    setShowShotsModel(false)
    setShowShotsPreviewModel(false)
    clearValue()
  }

  const ShowConfirm = (videoId) => {
    setDelShotId(videoId)
    setShowConfirmModel(true)
  }

  const handleCancel = () => {
    clearValue()
    setShowConfirmModel(false)
  }

  const updateInputFieldsTags = (tagsArray) => {
    setInputFields((prevInputFields) => ({
      ...prevInputFields,
      tags: tagsArray
    }))
  }

  const handleAddTag = () => {
    if (tags.length < 4) {
      const newTag = `tag ${tags.length + 1}`
      setTags([...tags, newTag])
      updateInputFieldsTags([...tags, newTag])
    } else {
      alert('Maximum 4 tags allowed.')
    }
  }

  const handleRemoveTag = (tagIndex) => {
    const updatedTags = [...tags]
    updatedTags.splice(tagIndex, 1)
    setTags(updatedTags)
    updateInputFieldsTags(updatedTags)
  }

  const handleEditTag = (tagIndex) => {
    setEditingIndex(tagIndex)
  }

  const handleTagChange = (e, tagIndex) => {
    const updatedTags = [...tags]
    updatedTags[tagIndex] = e.target.value
    setTags(updatedTags)
    updateInputFieldsTags(updatedTags)
  }

  const handleTagBlur = () => {
    setEditingIndex(null)
  }

  const handleChange = (e) => {
    setInputFields({ ...inputFields, [e.target.name]: e.target.value })
  }

  const getSelectedAppId = (app) => {
    setInputFields({ ...inputFields, app_id: app.map((a) => a.id) })
  }

  const isAppsSelected = inputFields.app_id?.length > 0
  const isAppsSelectedPreview = !_.isEqual(Platformtags, initialPlatformTags)

  const clearValue = () => {
    setSelectedFilePath('')
    setSelectedVideo('')
    setSelectedVideoObj('')
    setPlatformTags([])
    setInputFields({
      ...inputFields,
      title: '',
      attachment: '',
      cover_attachment: '',
      app_id: [],
      tags: []
    })
    setTags([])
  }

  const commentClear = () => {
    setComments([])
    setSelectedId([])
    setNewComment('')
    setShowReplied('')
    setEditedCommentText('')
    setEditedReplyText('')
  }

  // const handleVideoChange = (event) => {
  //   const fileInput = event.target
  //   const selectedFile = fileInput.files[0]

  //   if (selectedFile) {
  //     setSelectedVideoObj(selectedFile)
  //     const videoURL = URL.createObjectURL(selectedFile)
  //     setSelectedVideo(videoURL)
  //     console.log('===============', selectedFile)
  //   }
  // }

  const handleVideoChange = (event) => {
    const fileInput = event.target
    const selectedFile = fileInput.files[0]

    if (selectedFile) {
      const videoURL = URL.createObjectURL(selectedFile)
      const video = document.createElement('video')

      video.src = videoURL
      video.onloadedmetadata = () => {
        const videoDuration = video.duration

        if (videoDuration > 60) {
          toast.error('please shot upload under 1 min !')
          fileInput.value = ''
          return
        }

        setSelectedVideoObj(selectedFile)
        setSelectedVideo(videoURL)
        console.log('Selected video:', selectedFile)
      }
    }
  }

  const handlePublishClick = async () => {
    const videoId = Date.now()

    updateShotProgress(videoId, 0)
    setShowShotsPreviewModel(false)

    setLoading(true)

    try {
      if (!isEditing || selectedVideoObj) {
        const videoFile = await compressVideo(
          selectedVideoObj,
          (compressionProgress) => {
            updateShotProgress(videoId, compressionProgress / 2)
          }
        )
        inputFields.attachment = videoFile
      }

      if (isEditing) {
        await editShotApiCall()
      } else {
        await addShotApiCall()
      }

      setLoading(false)
      updateShotProgress(videoId, 100)
    } catch (error) {
      setLoading(false)
      toast.error(error.message || 'An error occurred')
    }
  }

  const getShotLibraryData = (search, currentPage) => {
    setIsLoading(true)
    const data = {
      search
    }
    getShotLibrary(data, currentPage).then((res) => {
      if (res.data.success) {
        setShotData(dataDecrypt(res.data.data).data)
        setTotalPages(dataDecrypt(res.data.data).last_page)
        setCurrentPage(dataDecrypt(res.data.data).current_page)
        setIsLoading(false)
      }
    })
  }

  const handleSearch = useCallback(
    debounce((inputVal) => getShotLibraryData(inputVal, currentPage), 500),
    []
  )

  const addShotApiCall = () => {
    setLoading(true)
    const data = {
      title: inputFields.title,
      // attachment: inputFields.attachment,
      // cover_attachment: inputFields.cover_attachment,
      app_id: Platformtags.map((d) => d.id),
      shot_id: inputFields.shot_id,
      tags: inputFields.tags
    }
    addShotData(data, inputFields.attachment).then((res) => {
      if (res.data.success) {
        setShowShotsModel(false)
        setShowShotCoverModel(false)
        setShowShotsPreviewModel(false)
        clearValue()
        setIsEditing(false)
        toast.success(res?.data?.message)
        getShotLibraryData(null, currentPage)
      } else {
        toast.error(res?.data?.message)
      }
    })
  }

  const deleteShot = () => {
    const data = {
      shot_id: delShotId
    }
    setDelLoading(true)
    deleteShotData(data).then((res) => {
      if (res.data.success) {
        setShowConfirmModel(false)
        getShotLibraryData(null, currentPage)
        setDelLoading(false)
        toast.success(res?.data?.message)
      }
    })
  }

  const editShotApiCall = () => {
    const data = {
      title: inputFields.title,
      attachment: inputFields.attachment,
      cover_attachment: inputFields.cover_attachment,
      app_id: Platformtags.map((d) => d.id),
      shot_id: inputFields.shot_id,
      tags: inputFields.tags
    }
    editShotData(data).then((res) => {
      if (res.data.success) {
        setShowShotsModel(false)
        setShowShotCoverModel(false)
        setShowShotsPreviewModel(false)
        setPreviewModel(false)
        clearValue()
        setIsEditing(false)
        getShotLibraryData(null, currentPage)
        toast.success(res?.data?.message)
      }
    })
  }

  const onClickEditPodcast = (videoData) => {
    setShowShotsModel(true)
    setIsEditing(true)
    setSelectedVideo(s3ImagePathCreator + videoData.attachment)
    setSelectedFilePath(s3ImagePathCreator + videoData.cover_attachment)
    setInputFields({
      ...inputFields,
      title: videoData.title,
      attachment: videoData.attachment,
      cover_attachment: videoData.cover_attachment,
      app_id: videoData.app_data,
      shot_id: videoData.shot_id,
      tags: JSON.parse(videoData.tags)
    })
    setInitialValues({
      title: videoData.title,
      attachment: videoData.attachment,
      cover_attachment: videoData.cover_attachment,
      app_id: videoData.app_data,
      shot_id: videoData.shot_id,
      tags: JSON.parse(videoData.tags),
      search: ''
    })
    setPlatformTags(videoData.app_data)
    setTags(JSON.parse(videoData.tags))
  }

  const handleItemClick = (videoData) => {
    setPreviewModel(true)
    setIsEditing(true)
    setSelectedVideo(s3ImagePathCreator + videoData.attachment)
    setSelectedFilePath(s3ImagePathCreator + videoData.cover_attachment)
    setInputFields({
      ...inputFields,
      title: videoData.title,
      attachment: videoData.attachment,
      cover_attachment: videoData.cover_attachment,
      app_id: videoData.app_data,
      shot_id: videoData.shot_id,
      tags: JSON.parse(videoData.tags)
    })
    setPlatformTags(videoData.app_data)
    setInitialPlatformTags(videoData.app_data)
    setTags(JSON.parse(videoData.tags))
  }

  const validateForm = () => {
    const { title, tags } = inputFields
    if (title && tags && selectedVideo) {
      setIsFormComplete(true)
    } else {
      setIsFormComplete(false)
    }
  }

  // shots comment

  const onClickComment = (shot_id) => {
    setSelectedId(shot_id)
    if (!isLoadingComments) {
      setIsLoadingComments(true)
      shotComment(shot_id)
      setCommentSection(true)
    }
  }

  const shotComment = (shot_id) => {
    const dataId = localStorage.getItem('app_id')
    const parsedData = JSON.parse(dataId)
    const platFormData = {
      app_id: [parsedData.id],
      shot_id
    }
    shotCommentData(platFormData).then((res) => {
      if (res.data.success) {
        const dataComment = dataDecrypt(res.data.data).data.map((d, i) => ({
          id: i + 1,
          text: d.comment,
          shot_id: d.shot_id,
          is_like: d.is_like,
          reply_id: d.comment_id,
          total_like: d.total_like,
          replies: d.reply_data.map((subData, subIndex) => ({
            id: subIndex + 1,
            text: subData.comment,
            reply_id: subData.comment_id,
            total_like: subData.total_like,
            is_like: subData.is_like,
            user: {
              name: subData.user_name,
              profilePic: s3ImagePath + subData.profile_icon
            }
          })),
          user: {
            name: d.user_name,
            profilePic: s3ImagePath + d.profile_icon
          }
        }))
        setComments(dataComment)
        setIsLoadingComments(false)
      }
    })
  }

  const shotAddComment = (comment) => {
    const platFormData = {
      shot_id: selectedId,
      comment
    }
    shotCommentAddData(platFormData).then((res) => {
      if (res.data.success) {
        shotComment(selectedId)
      }
    })
  }

  const shotAddCommentReply = (comment_id, replyText) => {
    const platFormData = {
      shot_id: selectedId,
      comment_id,
      comment: replyText
    }
    shotCommentAddData(platFormData).then((res) => {
      if (res.data.success) {
        shotComment(selectedId)
      }
    })
  }

  const shotCommentEdit = (reply_id, comment) => {
    const platFormData = {
      comment_id: reply_id,
      comment
    }
    shotCommentEditData(platFormData).then((res) => {
      if (res.data.success) {
        shotComment(selectedId)
      }
    })
  }

  const shotCommentEditReply = (comment_id, replyText) => {
    const platFormData = {
      shot_id: selectedId,
      comment_id,
      comment: replyText
    }
    shotCommentEditData(platFormData).then((res) => {
      if (res.data.success) {
        shotComment(selectedId)
      }
    })
  }

  const shotCommentDelete = (reply_id) => {
    setIsDeleting(true)
    const data = {
      comment_id: reply_id
    }
    shotCommentDeleteData(data).then((res) => {
      if (res.data.success) {
        shotComment(selectedId)
        setIsDeleting(false)
        toast.success(res?.data?.message)
      }
    })
  }

  const shotCommentDeleteReply = (commentId, replyId) => {
    setIsDeleting(true)
    const data = {
      comment_id: commentId,
      reply_id: replyId
    }
    shotCommentDeleteData(data).then((res) => {
      if (res.data.success) {
        shotComment(selectedId)
        setIsDeleting(false)
        toast.success(res?.data?.message)
      }
    })
  }

  const shotCommentLike = (reply_id) => {
    const dataId = localStorage.getItem('app_id')
    const parsedData = JSON.parse(dataId)
    const platFormData = {
      app_id: parsedData.id,
      comment_id: reply_id
    }
    shotCommentLikeData(platFormData).then((res) => {
      if (res.data.success) {
        shotComment(selectedId)
        toast.success(res?.data?.message)
      }
    })
  }

  const shotCommentReplyLike = (reply_id) => {
    const dataId = localStorage.getItem('app_id')
    const parsedData = JSON.parse(dataId)
    const platFormData = {
      app_id: parsedData.id,
      comment_id: reply_id
    }
    shotCommentLikeData(platFormData).then((res) => {
      if (res.data.success) {
        shotComment(selectedId)
        toast.success(res?.data?.message)
      }
    })
  }

  const handleAddComment = (comment, profileImage, name) => {
    if (newComment.trim() !== '') {
      const newCommentObj = {
        id: comments.length + 1,
        is_like: false,
        text: newComment,
        shot_id: selectedId,
        total_like: 0,
        replies: [],
        user: {
          name,
          profilePic: profileImage
        }
      }
      setComments([...comments, newCommentObj])
      setNewComment('')
    }
    shotAddComment(comment)
  }

  const handleLikeComment = (commentId) => {
    const updatedComments = comments.map((comment) => {
      if (comment.id === commentId) {
        return { ...comment, total_like: comment.total_like + 1 }
      }
      return comment
    })
    setComments(updatedComments)
    shotCommentLike(commentId)
  }

  const handleLikeReply = (commentId) => {
    const updatedComments = comments.map((comment) => {
      if (comment.id === commentId) {
        return { ...comment, total_like: comment.total_like + 1 }
      }
      return comment
    })
    setComments(updatedComments)
    shotCommentReplyLike(commentId)
  }

  const handleAddReply = (commentId, replyText) => {
    const updatedComments = comments.map((comment) => {
      if (comment.id === commentId) {
        return {
          ...comment,
          replies: [
            ...comment.replies,
            {
              id: comment.replies.length + 1,
              text: replyText,
              total_like: 0,
              user: {
                name: userData.user_name,
                profilePic: s3ImagePath + userData.profile_image
              }
            }
          ]
        }
      }
      return comment
    })
    setComments(updatedComments)
    setShowReplied('')
    shotAddCommentReply(commentId, replyText)
  }

  const handleDeleteReply = (commentId, replyId) => {
    const updatedComments = comments.map((comment) => {
      if (comment.id === commentId) {
        return {
          ...comment,
          replies: comment.replies.filter((reply) => reply.id !== replyId)
        }
      }
      return comment
    })
    setComments(updatedComments)
    shotCommentDeleteReply(replyId, commentId)
  }

  const toggleReplies = (commentId, forceCloseAll = false) => {
    if (forceCloseAll) {
      setShowReplies({})
    } else {
      setShowReplies((prevState) => ({
        // ...prevState,
        [commentId]: !prevState[commentId]
      }))
    }
    setShowReplied('')
  }

  const handleCommentCancel = () => {
    setCommentSection(false)
    commentClear()
    toggleReplies(null, true)
  }

  const handleEditComment = (commentId, text) => {
    setEditCommentId(commentId)
    setEditedCommentText(text)
  }

  const handleSaveComment = (commentId, reply) => {
    const updatedComments = comments.map((comment) => (comment.id === commentId
        ? { ...comment, text: editedCommentText }
        : comment))
    shotCommentEdit(commentId, reply)
    setComments(updatedComments)
    setEditCommentId(null)
    setEditedCommentText('')
  }

  const handleEditReply = (replyId, text) => {
    setEditReplyId(replyId)
    setEditedReplyText(text)
  }

  const handleSaveReply = (commentId, replyId) => {
    const updatedComments = comments.map((comment) => (comment.id === commentId
        ? {
            ...comment,
            replies: comment.replies.map((reply) => (reply.id === replyId ? { ...reply, text: editedReplyText } : reply))
          }
        : comment))
    shotCommentEditReply(commentId, replyId)
    setComments(updatedComments)
    setEditReplyId(null)
    setEditedReplyText('')
  }

  // shots pagination

  const handlePageChange = (pageNumber) => {
    getShotLibraryData(null, currentPage)
    setCurrentPage(pageNumber)
  }

  // comment pagination

  const commentsPerPage = 5
  const indexOfLastComment = currentPageComment * commentsPerPage
  const indexOfFirstComment = indexOfLastComment - commentsPerPage
  const commentsList = comments.slice(indexOfFirstComment, indexOfLastComment)
  const totalCommentPages = Math.ceil(comments.length / commentsPerPage)

  const handlePageChanges = (pageNumber) => {
    setCurrentPageComment(pageNumber)
  }

  useEffect(() => {
    getShotLibraryData(null, currentPage)
    const handleClickOutside = (event) => {
      if (!loading) {
        if (
          componentRef.current &&
          !componentRef.current.contains(event.target)
        ) {
          setShowShotsModel(false)
          setShowShotCoverModel(false)
          setShowShotsPreviewModel(false)
          setPreviewModel(false)
          clearValue()
          setIsEditing(false)
        }
      }
    }

    if (
      !loading &&
      (ShowShotsModel ||
        showShotCoverModel ||
        ShowShotsPreviewModel ||
        previewModel ||
        ShowConfirmModel)
    ) {
      document.addEventListener('mousedown', handleClickOutside)
    } else {
      document.removeEventListener('mousedown', handleClickOutside)
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  useEffect(() => {
    if (currentPage > totalPages) {
      setCurrentPage(totalPages > 0 ? totalPages : 1)
    }
  }, [totalPages, currentPage])

  useEffect(() => {
    if (currentPageComment > totalCommentPages) {
      setCurrentPageComment(totalCommentPages || 1)
    }
  }, [comments, currentPageComment, totalCommentPages])

  useEffect(() => {
    if (!isEditing) {
      validateForm()
    }
  }, [inputFields, selectedVideo])

  useEffect(() => {
    if (isEditing) {
      setIsFormComplete(!_.isEqual(inputFields, initialValues))
    }
  }, [inputFields, initialValues, isEditing, selectedVideo])

  useEffect(() => {
    if (
      previewModel ||
      commentSection ||
      ShowShotsModel ||
      ShowShotsPreviewModel
    ) {
      lockScroll()
    } else {
      unlockScroll()
    }

    return () => {
      unlockScroll()
    }
  }, [
    previewModel,
    commentSection,
    ShowShotsModel,
    ShowShotsPreviewModel
  ])

  return (
    <div>
      {_.map(shotProgress, (pro, videoId) => {
        if (pro === 100) {
          return null
        }
        return (
          <div className="custom-progress" key={videoId}>
            <Progress
              percent={pro}
              status="active"
              strokeColor={{
                '3.03%': '#001597',
                '47.1%': '#01104d',
                '80.14%': '#020c1c',
                '96.45%': '#020b09'
              }}
              trailColor="#d9d9d9"
              strokeWidth={10}
            />
          </div>
        )
      })}

      <div className="add-video-row">
        <CommonButton
          label="Add New Shot"
          imgSrc={AddIco}
          onClick={ShowShots}
          isPrimary
          className="add_new_content"
        />
        <div className="items-filter">
          <div className="position-relative input-position">
            <CommonInput
              placeholder="Search Shots"
              onChange={(e) => {
                handleSearch(e.target.value)
              }}
            />
          </div>
          <Dropdown
            title="Filter"
            Data={[
              { title: 'Scaloo' },
              { title: 'Spacure' },
              { title: 'PETZINO' },
              { title: 'A-Z FIT' },
              { title: 'TESTIEE' }
            ]}
            customClass="content-filter disabledDropdown"
          />
        </div>
      </div>
      <div className="community-detail-manager">
        <div className="shots-overview">
          {/* {shotData.slice(0, visibleItems).map((item) => ( */}
          {shotData.length === 0 && !isLoading ? (
            <div className="awards-row1">
              <div className="awards-items">
                <div className="add-profile-shots-cover1">
                  <button type="button" onClick={ShowShots}>
                    <img src={Plus} alt="add library" />
                  </button>
                  <div className="view-block">
                    <p />
                  </div>
                </div>
              </div>
            </div>
          ) : isLoading ? (
            <div
              className="shots-item"
            >
              <div
                className="shots-basic-detail text-center"
              >
                <Skeleton className="shots-profile m-auto" width={85} height={85} />
                <h6><Skeleton width={100} /></h6>
                <span><Skeleton width={50} /></span>
              </div>
            </div>
        ) : (
          shotData.map((item, index) => (
            <div
              className="shots-item"
              key={item.profileName}
              style={{
                  backgroundImage: `url(${
                    s3ImagePathCreator + item.cover_attachment
                  })`,
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'cover',
                  backgroundPosition: 'center'
                }}
            >
              <div
                className="shots-basic-detail text-center"
                onClick={() => handleItemClick(item)}
              >
                <div
                  className="shots-profile m-auto"
                  style={{
                      background: `url(${s3ImagePath + userData.profile_image})`,
                      backgroundRepeat: 'no-repeat',
                      backgroundSize: 'cover',
                      backgroundPosition: 'center'
                    }}
                />
                <h6>{userData.user_name}</h6>
                <span>{timeDifference(item.created_at)}</span>
              </div>
              <div className="image-buttons z-1">
                <label>
                  <button
                    type="button"
                    onClick={() => onClickEditPodcast(item)}
                    className="d-none"
                  />
                </label>
                <button
                  type="button"
                  className="p-0"
                  onClick={() => {
                      setUpdatingIndex(index)
                      ShowConfirm(item.shot_id)
                    }}
                />
                <div
                  type="button"
                  className="button1"
                  onClick={() => onClickComment(item.shot_id)}
                />
              </div>
              {item.app_data.length > 0 && (
              <span
                className={`shots-badge ${
                      item.app_data.length === 2 ? 'shots-badge-alt' : ''
                    }`}
              >
                {item.app_data.map((data, i) => (
                  <div
                    key={i}
                    className={`badge-picture ${
                          item.app_data.length === 2 ? 'badge-picture-alt' : ''
                        }`}
                    style={{
                          backgroundImage: `url(${urlSystemIcon + data.icon})`,
                          backgroundRepeat: 'no-repeat',
                          backgroundSize: '100%'
                        }}
                  />
                    ))}
              </span>
                )}
              <ConfirmationModal
                isVisible={ShowConfirmModel && updatingIndex === index}
                onConfirm={deleteShot}
                onCancel={handleCancel}
                message="Do you want to delete this?"
                isLoading={delLoading}
              />
              {delLoading && updatingIndex === index && <Loader />}
            </div>
            ))
          )}
          {commentSection && (
            <div className="add-item-modal mh-100">
              <div className="add-item-contents">
                <div className="d-flex align-items-center justify-content-between">
                  <h4 className="mb-0">Shot Comment Section</h4>
                  <button
                    type="button"
                    className="secondary-btn-modal"
                    onClick={handleCommentCancel}
                  >
                    <img src={CloseIco} alt="clock icon" />
                  </button>
                </div>
                <div
                  className="comment-section"
                >
                  {isLoadingComments ? (
                    <div>
                      <div className="comments-container">
                        <ul className="comments-list">
                          <li className="comment-item">
                            <button
                              type="button"
                              className="comment-delete"
                            >
                              <Skeleton
                                className="rounded-3"
                                width={20}
                                height={20}
                              />
                            </button>
                            <div className="comment-header mb-0 gap-2">
                              <Skeleton
                                circle
                                width={45}
                                height={45}
                              />
                              <span className="user-name">
                                <Skeleton
                                  width={150}
                                  height={20}
                                />
                              </span>
                            </div>
                            <div className="comment-btns">
                              <button
                                type="button"
                              >
                                <Skeleton
                                  width={20}
                                  height={20}
                                />
                              </button>
                              <Skeleton
                                width={20}
                                height={20}
                              />
                              <button
                                type="button"
                              >
                                <Skeleton
                                  width={45}
                                  height={20}
                                />
                              </button>
                              <button
                                type="button"
                              >
                                <Skeleton
                                  width={45}
                                  height={20}
                                />
                              </button>
                            </div>
                          </li>
                        </ul>
                      </div>
                      <div className="add-comment">
                        <input
                          type="text"
                          placeholder="Add a comment..."
                          value={newComment}
                          onChange={(e) => setNewComment(e.target.value)}
                          name="reply"
                        />
                        <button
                          type="button"
                          onClick={() => handleAddComment(newComment)}
                        >
                          Add
                        </button>
                      </div>
                    </div>
                  ) : (
                    <div>
                      <div className="comments-container">
                        {commentsList.length === 0 ? (
                          <div className="no-comments-message">
                            No Comments Yet
                          </div>
                        ) : (
                          <ul className="comments-list">
                            {commentsList.map((comment, index) => (
                              <li key={comment.id} className="comment-item">
                                {isDeleting && (
                                <div className="loader-overlay">
                                  {commentIndex === index && <Loader />}
                                </div>
                                )}
                                {comment.user.name === userData.user_name && (
                                <button
                                  type="button"
                                  className="comment-delete"
                                  onClick={() => {
                                    setCommentIndex(index)
                                    shotCommentDelete(comment.reply_id)
                                  }}
                                >
                                  <img src={Delete} alt="delete icon" />
                                </button>
                              )}
                                <div className="comment-header mb-0">
                                  <img
                                    src={comment.user.profilePic}
                                    alt={`${comment.user.name}'s profile`}
                                    className="profile-pic"
                                  />
                                  <span className="user-name">
                                    {comment.user.name}
                                  </span>
                                </div>
                                {editCommentId === comment.id ? (
                                  <div className="edit-reply">
                                    <input
                                      type="text"
                                      value={editedCommentText}
                                      onChange={(e) => setEditedCommentText(e.target.value)}
                                    />
                                    <button
                                      type="button"
                                      onClick={() => handleSaveComment(
                                          comment.reply_id,
                                          editedCommentText
                                        )}
                                    >
                                      Save
                                    </button>
                                  </div>
                                ) : (
                                  <div className="comment-text">
                                    {comment.text}
                                  </div>
                                )}
                                <div className="comment-btns">
                                  <button
                                    type="button"
                                    onClick={() => handleLikeComment(comment.reply_id)}
                                  >
                                    {comment.is_like ? (
                                      <img src={Likes} alt="Unlike" />
                                    ) : (
                                      <img src={UnLikes} alt="Like" />
                                    )}
                                    {' '}
                                    {comment.total_like}
                                  </button>
                                  {comment.user.name === userData.user_name && (
                                  <button
                                    type="button"
                                    onClick={() => handleEditComment(
                                        comment.id,
                                        comment.text
                                      )}
                                  >
                                    Edit
                                  </button>
                                  )}
                                  <button
                                    type="button"
                                    onClick={() => toggleReplies(comment.id)}
                                  >
                                    {/* {showReplies[comment.id] ? 'Hide' : 'Show'}
                                    {' '}
                                    Replies */}
                                    {showReplies[comment.id] ? 'Hide Replies' : `Show Replies (${comment.replies.length})`}
                                  </button>
                                </div>
                                {showReplies[comment.id] && (
                                  <ul className="replies-list">
                                    {comment.replies.map((reply) => (
                                      <li key={reply.id} className="reply-item">
                                        <div className="reply-header mb-0">
                                          <img
                                            src={reply.user.profilePic}
                                            alt={`${reply.user.name}'s profile`}
                                            className="profile-pic"
                                          />
                                          <span className="user-name">
                                            {reply.user.name}
                                          </span>
                                          {reply.user.name === userData.user_name && (
                                            <button
                                              type="button"
                                              onClick={() => {
                                                setCommentIndex(index)
                                                handleDeleteReply(
                                                comment.reply_id,
                                                reply.reply_id
                                              )
}}
                                              className="comment-delete"
                                            >
                                              <img
                                                src={Delete}
                                                alt="delete icon"
                                              />
                                            </button>
                                            )}
                                        </div>
                                        {editReplyId === reply.id ? (
                                          <div className="edit-reply">
                                            <input
                                              type="text"
                                              value={editedReplyText}
                                              onChange={(e) => setEditedReplyText(
                                                  e.target.value
                                                )}
                                            />
                                            <button
                                              type="button"
                                              onClick={() => handleSaveReply(
                                                  reply.reply_id,
                                                  editedReplyText
                                                )}
                                            >
                                              Save
                                            </button>
                                          </div>
                                        ) : (
                                          <div className="reply-text">
                                            {reply.text}
                                          </div>
                                        )}
                                        <div className="comment-btns">
                                          <button
                                            type="button"
                                            onClick={() => handleLikeReply(reply.reply_id)}
                                          >
                                            {reply.is_like ? (
                                              <img src={Likes} alt="Unlike" />
                                            ) : (
                                              <img src={UnLikes} alt="Like" />
                                            )}
                                            {' '}
                                            {reply.total_like}
                                          </button>
                                          {reply.user.name === userData.user_name && (
                                          <button
                                            type="button"
                                            onClick={() => handleEditReply(
                                                reply.id,
                                                reply.text
                                              )}
                                          >
                                            Edit
                                          </button>
                                          )}
                                        </div>
                                      </li>
                                    ))}
                                    <li className="add-reply">
                                      <input
                                        type="text"
                                        placeholder="Reply..."
                                        value={Replied}
                                        onChange={(e) => setShowReplied(e.target.value)}
                                      />
                                      <button
                                        type="button"
                                        onClick={() => handleAddReply(
                                            comment.reply_id,
                                            Replied
                                          )}
                                      >
                                        Reply
                                      </button>
                                    </li>
                                  </ul>
                                )}
                              </li>
                            ))}
                          </ul>
                        )}
                      </div>
                      {totalCommentPages > 1 && (
                        <CommentPagination
                          currentPage={currentPageComment}
                          totalPages={totalCommentPages}
                          onPageChange={handlePageChanges}
                        />
                      )}
                      <div className="add-comment">
                        <input
                          type="text"
                          placeholder="Add a comment..."
                          value={newComment}
                          onChange={(e) => setNewComment(e.target.value)}
                          name="reply"
                        />
                        {commentsList.length === 0 && (
                          <button
                            type="button"
                            className="comment_add_btn"
                            onClick={() => handleAddComment(
                                newComment,
                                '',
                                ''
                              )}
                          >
                            Add
                          </button>
                        )}
                        {commentsList.map((comment, i) => (
                          <button
                            key={i}
                            type="button"
                            onClick={() => handleAddComment(
                                newComment,
                                comment.user.profilePic,
                                comment.user.name
                              )}
                          >
                            Add
                          </button>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
          {previewModel && (
            <div className="add-item-model">
              <div className="add-item-content" ref={componentRef}>
                <div className="d-flex justify-content-between header pb-1">
                  <h3>Upload Files</h3>
                  <button
                    type="button"
                    className="border-0 bg-none p-0"
                    onClick={HandleClose}
                  >
                    <span>
                      <img className="close-btns" src={CloseIco} alt="" />
                    </span>
                  </button>
                </div>
                <div className="scroll-bars pb-0 mh-100">
                  <div className="item-details-input pt-0">
                    <div className="d-flex column-gap-2">
                      <div className="shots-stories w-50">
                        <div className="shots-stories-react">
                          <div>
                            <video
                              className="shots_size"
                              ref={videoRef}
                              width="100%"
                              height="200"
                              poster={selectedFilePath}
                              controls={false}
                              autoPlay
                              muted={isMuted}
                              playsInline
                              onClick={pauseHandel}
                            >
                              <source src={selectedVideo} type="video/mp4" />
                            </video>
                          </div>
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              alignContent: 'center',
                              width: '140px',
                              columnGap: '10px',
                              top: '11px',
                              left: '15px',
                              zIndex: '1',
                              position: 'absolute'
                            }}
                          >
                            <div>
                              <img
                                src={s3ImagePath + userData.profile_image}
                                alt="Avatar"
                                style={{
                                  'vertical-align': 'middle',
                                  width: '32px',
                                  height: '32px',
                                  borderRadius: '8px',
                                  border: '0.74px solid #FFF'
                                }}
                              />
                            </div>
                            <div
                              style={{
                                color: 'white',
                                fontWeight: '600',
                                fontSize: '12px'
                              }}
                            >
                              <p
                                style={{
                                  color: 'white',
                                  fontWeight: '600',
                                  fontSize: '10px',
                                  margin: 0,
                                  textAlign: 'start'
                                }}
                              >
                                {userData.user_name}
                              </p>
                              <p
                                style={{
                                  color: '#ffffff99',
                                  fontWeight: '400',
                                  fontSize: '12px',
                                  margin: 0,
                                  textAlign: 'start'
                                }}
                              >
                                {/* {{ timeDifference }}   */}
                              </p>
                            </div>
                          </div>
                          <div className="shots-control">
                            <div
                              className="shots-play-control"
                              onClick={pauseHandel}
                            >
                              {playPauseIcon}
                            </div>
                            <div
                              className="shots-volume-control"
                              onClick={VolumeHandel}
                            >
                              {playVolumeIcon}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="d-flex flex-column justify-content-between">
                        <PlatformTag
                          setPlatformTags={setPlatformTags}
                          Platformtags={Platformtags}
                          onItemSelect={(value) => getSelectedAppId(value)}
                        />
                        <div className="button-row">
                          <CommonButton
                            label="Cancel"
                            type="button"
                            onClick={HandleClose}
                            isPrimary={false}
                            disabled={loading}
                            context="Profile"
                          />
                          <CommonButton
                            label={isAppsSelected ? 'Publish' : 'Upload'}
                            loading={loading}
                            type="button"
                            onClick={handlePublishClick}
                            className={`primary-btn-model ${loading ? 'loading' : ''}`}
                            isPrimary
                            disabled={loading || !isAppsSelectedPreview}
                            context="Profile"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        {/* {items.length > visibleItems && (
          <div className="collapse-button text-center mt-3">
            <button type="button" onClick={showMoreItems}>
              <img src={DownIco} />
            </button>
          </div>
        )} */}
        {totalPages > 1 && (
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
        />
      )}
      </div>
      {ShowShotsModel && (
        <div className="add-item-model">
          <div className="add-item-content" ref={componentRef}>
            <div className="d-flex justify-content-between header pb-1">
              <h3>Upload Files</h3>
              <button
                type="button"
                className="border-0 bg-none p-0"
                onClick={HandleClose}
              >
                <span>
                  <img className="close-btns" src={CloseIco} alt="" />
                </span>
              </button>
            </div>
            <div className="scroll-bars pb-0 mh-100">
              <div className="item-details-input pt-0">
                <div className={selectedVideo ? 'd-flex column-gap-4 w-100' : ''}>
                  <div className={selectedVideo ? 'upload-input w-50' : 'upload-input upload-input-with-border shot_upload'}>
                    {!selectedVideo && (
                      <label htmlFor="upload-file">
                        <CommonInput
                          type="file"
                          id="upload-file"
                          accept="video/*"
                          onChange={handleVideoChange}
                          className="d-none"
                        />
                        {selectedVideo && (
                          <button
                            type="button"
                            className={`edit-button-model content-meneger-edit ${
                              isEditing ? 'hidden' : ''
                            }`}
                            onClick={() => {
                              setIsEditing(true)
                              document.getElementById('upload-file').click()
                            }}
                          />
                        )}
                        {!selectedVideo && (
                          <>
                            <div className="upload-img">
                              <img src={Upload} alt="" />
                            </div>
                            <h4>Upload a Shot</h4>
                          </>
                        )}
                      </label>
                    )}

                    {selectedVideo && (
                      <div className="shots-stories ">
                        <div>
                          <video
                            className="shots_size"
                            ref={videoRef}
                            width="100%"
                            height="200"
                            poster={selectedFilePath}
                            controls={false}
                            autoPlay
                            muted={isMuted}
                            playsInline
                            onClick={pauseHandel}
                          >
                            <source src={selectedVideo} type="video/mp4" />
                          </video>
                        </div>
                        <div className="shots-control1">
                          <div
                            className="shots-play-control"
                            onClick={pauseHandel}
                          >
                            {playPauseIcon}
                          </div>
                          <div
                            className="shots-volume-control"
                            onClick={VolumeHandel}
                          >
                            {playVolumeIcon}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className={selectedVideo ? 'w-50 position' : ''}>
                    <div>
                      <label>Add Text</label>
                      <CommonInput
                        name="title"
                        placeholder="Type here"
                        onChange={handleChange}
                        value={capitalizeFirstLetter(inputFields.title)}
                        maxLength={150}
                      />
                    </div>
                    <div className="add-tag">
                      <span>Add tag</span>
                      <div className="d-flex flex-wrap gap-2">
                        <ul className="mb-0 gap-2">
                          {tags.map((tag, index) => (
                            <li
                              key={index}
                              className={`tag-item ${
                                editingIndex === index ? 'editing' : ''
                              }`}
                            >
                              {editingIndex === index ? (
                                <div className="tag-edit-container">
                                  <CommonInput
                                    value={tag}
                                    onChange={(e) => handleTagChange(e, index)}
                                    onBlur={handleTagBlur}
                                    ref={(input) => input && input.focus()}
                                    className="edit-input"
                                  />
                                </div>
                              ) : (
                                <>
                                  {tag}
                                  <button
                                    type="button"
                                    className="remove-tag"
                                    onClick={() => handleRemoveTag(index)}
                                  >
                                    <img src={Remove} alt="Remove" />
                                  </button>
                                  <button
                                    type="button"
                                    className="edit-tag"
                                    onClick={() => handleEditTag(index)}
                                  />
                                </>
                              )}
                            </li>
                          ))}
                        </ul>
                        {tags.length < 4 && (
                          <button
                            type="button"
                            // className="add-tag-btns"
                            className={tags.length > 2 ? 'add-tag-btns mt-2' : 'add-tag-btns'}
                            onClick={handleAddTag}
                          >
                            <img src={PlusTag} alt="Add Tag" />
                            Add Tag
                          </button>
                        )}
                      </div>
                    </div>
                    <div className={selectedVideo ? 'button-row position_btn' : 'button-row'}>
                      <CommonButton
                        label="Back"
                        type="button"
                        onClick={HandleCancelPreview}
                        isPrimary={false}
                        context="Profile"
                      />
                      <CommonButton
                        label="Next"
                        type="button"
                        isPrimary
                        context="Profile"
                        disabled={!isFormComplete}
                        onClick={HandleShotCover}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {ShowShotsPreviewModel && (
        <div className="add-item-model">
          <div className="add-item-content" ref={componentRef}>
            <div className="d-flex justify-content-between header pb-1">
              <h3>Upload Files</h3>
              <button
                type="button"
                className="border-0 bg-none p-0"
                disabled={loading}
                onClick={HandleClose}
              >
                <span>
                  <img className="close-btns" src={CloseIco} alt="" />
                </span>
              </button>
            </div>
            <div className="scroll-bars pb-0 mh-100">
              <div className="item-details-input pt-0">
                <div className="d-flex column-gap-2">
                  <div className="shots-stories w-50">
                    <div className="shots-stories-react">
                      <div>
                        <video
                          className="shots_size"
                          ref={videoRef}
                          width="100%"
                          height="200"
                          poster={selectedFilePath}
                          controls={false}
                          autoPlay
                          muted={isMuted}
                          playsInline
                          onClick={pauseHandel}
                        >
                          <source src={selectedVideo} type="video/mp4" />
                        </video>
                      </div>
                      <div
                        style={{
                        display: 'flex',
                        alignItems: 'center',
                        alignContent: 'center',
                        width: '140px',
                        columnGap: '10px',
                        top: '11px',
                        left: '15px',
                        zIndex: '9999',
                        position: 'absolute'
                      }}
                      >
                        <div>
                          <img
                            src={s3ImagePath + userData.profile_image}
                            alt="Avatar"
                            style={{
                            'vertical-align': 'middle',
                            width: '32px',
                            height: '32px',
                            borderRadius: '8px',
                            border: '0.74px solid #FFF'
                          }}
                          />
                        </div>
                        <div
                          style={{
                          color: 'white',
                          fontWeight: '600',
                          fontSize: '12px'
                        }}
                        >
                          <p
                            style={{
                            color: 'white',
                            fontWeight: '600',
                            fontSize: '10px',
                            margin: 0,
                            textAlign: 'start'
                          }}
                          >
                            {userData.user_name}
                          </p>
                          <p
                            style={{
                            color: '#ffffff99',
                            fontWeight: '400',
                            fontSize: '12px',
                            margin: 0,
                            textAlign: 'start'
                          }}
                          >
                            {/* {{ timeDifference }}   */}
                          </p>
                        </div>
                      </div>
                      <div className="shots-control">
                        <div className="shots-play-control" onClick={pauseHandel}>
                          {playPauseIcon}
                        </div>
                        <div
                          className="shots-volume-control"
                          onClick={VolumeHandel}
                        >
                          {playVolumeIcon}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="d-flex flex-column justify-content-between">
                    <PlatformTag
                      setPlatformTags={setPlatformTags}
                      Platformtags={Platformtags}
                      onItemSelect={(value) => getSelectedAppId(value)}
                    />
                    <div className="button-row">
                      <CommonButton
                        label="Cancel"
                        type="button"
                        onClick={HandleCancelPreview}
                        isPrimary={false}
                        disabled={loading}
                        context="Profile"
                      />
                      <CommonButton
                        label={isAppsSelected ? 'Publish' : 'Upload'}
                        loading={loading}
                        type="button"
                        onClick={handlePublishClick}
                        className={`primary-btn-model ${loading ? 'loading' : ''}`}
                        isPrimary
                        context="Profile"
                        disabled={loading}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
export default ShotsDetail
