/* eslint-disable no-nested-ternary */
/* eslint-disable react/button-has-type */
import React from 'react'
import PropTypes from 'prop-types'
import './ButtonCommon.css'

const CommonButton = ({
  type = 'button',
  label,
  imgSrc,
  imgAlt = 'icon',
  onClick,
  disabled = false,
  isPrimary = false,
  className = '',
  loading = false,
  htmlFor = '',
  context = 'default',
  id
}) => {
    const buttonClass = isPrimary
    ? (context === 'Profile' ? 'primary-btn-model-store'
      : context === 'primary' ? 'primary-button'
      : context === 'local' ? 'local-primary-button'
      : 'primary-btn-model')
    : (context === 'Profile' ? 'secondary-btn-model-store'
      : context === 'local' ? 'local-secondary-button'
      : 'secondary-btn-model')

  return (
    <button
      type={type}
      id={id}
      className={`${buttonClass} ${className} ${loading ? 'loading' : ''}`}
      onClick={onClick}
      htmlFor={htmlFor}
      disabled={disabled || loading}
    >
      {imgSrc && <img src={imgSrc} alt={imgAlt} className="button-icon" />}
      <span>{label}</span>
      {loading && <span className="spinner" />}
    </button>
  )
}

CommonButton.propTypes = {
  type: PropTypes.string,
  label: PropTypes.string.isRequired,
  imgSrc: PropTypes.string,
  imgAlt: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  isPrimary: PropTypes.bool,
  className: PropTypes.string,
  context: PropTypes.oneOf(['Profile', 'primary', 'default', 'local']),
  loading: PropTypes.bool
}

export default CommonButton
