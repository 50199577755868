const isEmpty = (value) => {
  if (value === null || value === undefined) {
    return true
  }
  if (typeof value === 'string' && value.trim() === '') {
    return true
  }
  return false
}

export const capitalizeFirstLetter = (string) => {
  if (!string) return ''
  const cleanedString = string.replace(/^\s+/, '').replace(/\s+/g, ' ')
  return cleanedString.charAt(0).toUpperCase() + cleanedString.slice(1)
}

export const timeDifference = (createdAt) => {
  const createdDate = new Date(createdAt)
  const now = new Date()
  let difference = now - createdDate

  if (difference < 0) {
    difference = 0
  }

  const seconds = Math.floor(difference / 1000)
  const minutes = Math.floor(seconds / 60)
  const hours = Math.floor(minutes / 60)
  const days = Math.floor(hours / 24)

  if (seconds < 60) {
    return `${seconds} seconds ago`
  }
  if (minutes < 60) {
    return `${minutes} minutes ago`
  }
  if (hours < 24) {
    return `${hours} hours ago`
  }
  return `${days} days ago`
}

export const formatViews = (views) => {
  if (views >= 1_000_000_000) {
    return `${(views / 1_000_000_000).toFixed(1)}B`
  }
  if (views >= 1_000_000) {
    return `${(views / 1_000_000).toFixed(1)}M`
  }
  if (views >= 1_000) {
    return `${(views / 1_000).toFixed(1)}k`
  }
  return views.toString()
}

export const debounce = (func, wait) => {
  let timeout

  return (...args) => {
    clearTimeout(timeout)
    timeout = setTimeout(() => func(...args), wait)
  }
}

export const formatTimeLeft = (timeLeft) => {
  const [hours, minutes] = timeLeft.split(':').map(Number)

  if (hours > 0) {
    return `${hours} hour${hours > 1 ? 's' : ''} left`
  }
  if (minutes > 0) {
    return `${minutes} minute${minutes > 1 ? 's' : ''} left`
  }
  return 'Time is up'
}

export const formatDuration = (seconds) => {
  const hrs = Math.floor(seconds / 3600)
  const mins = Math.floor((seconds % 3600) / 60)
  const secs = Math.floor(seconds % 60)
  return `${hrs.toString().padStart(2, '0')}:${mins.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`
}

export const formatDurationPodcast = (seconds) => {
  const mins = Math.floor(seconds / 60)
  const secs = Math.floor(seconds % 60)
  return `${mins} M : ${secs.toString().padStart(2, '0')} S`
}

export const lockScroll = () => {
  document.body.style.overflow = 'hidden'
}

export const unlockScroll = () => {
  document.body.style.overflow = 'auto'
}

const Utility = {
  isEmpty,
  capitalizeFirstLetter,
  timeDifference,
  formatViews,
  debounce,
  formatTimeLeft,
  formatDuration,
  formatDurationPodcast,
  lockScroll,
  unlockScroll
}

export default Utility

