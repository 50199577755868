import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import SideBarPage from './SidebarPage'
import { OtpVerifications, ResendOtp } from '../../services/Service'
import '../../App.css'
import { dataDecrypt } from '../../services/http-services'
import CommonButton from '../ButtonComponent/ButtonCommon'
import CommonInput from '../InputComponet/CommonInput'

const OtpVerification = () => {
  const userData = JSON.parse(localStorage.getItem('userData'))
  const navigate = useNavigate()

  const [inputFields, setInputFields] = useState({
    email: userData?.email,
    otp: '',
    app_id: 2
  })
  const [errors, setErrors] = useState({})
  const [submitting, setSubmitting] = useState(false)
  const [isButtonEnabled, setIsButtonEnabled] = useState(false)
  const [countdown, setCountdown] = useState(59)
  const [canResend, setCanResend] = useState(false)
  const [firstTime, setFirstTime] = useState(true)
  const [loading, setLoading] = useState(false)

  const validateValues = (inputValues) => {
    const errors = {}
    if (inputValues.otp.length !== 6) {
      errors.otp = 'Please enter 6 digit otp'
    }
    return errors
  }

  const handleChange = (e) => {
    setInputFields({ ...inputFields, [e.target.name]: e.target.value })
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    setErrors(validateValues(inputFields))
    setSubmitting(true)
  }

  const finishSubmit = () => {
    setLoading(true)
    OtpVerifications({
        otp: inputFields.otp,
        email: inputFields.email,
        app_id: 2
    }).then((res) => {
      if (res.data.success) {
        navigate('/user-information')
        localStorage.setItem('token', res.data.token)
        localStorage.setItem('userData', JSON.stringify(dataDecrypt(res.data.data)))
        localStorage.removeItem('loginData')
        toast.success(res?.data?.message)
        setLoading(false)
      } else {
        toast.error(res?.data?.message)
        setLoading(false)
      }
    }).catch((error) => {
        console.log(error, 'error')
      })
  }

  const ResendOtpApiCall = () => {
    ResendOtp({
        email: inputFields.email,
        app_id: 2
    }).then((res) => {
      if (res?.data?.success) {
        console.log(res.data.data)
        toast.success('Otp Send Successfully.')
        setCountdown(59)
        setCanResend(false)
      } else {
        toast.error(res?.data?.message)
      }
    })
  }

  const redirectLogin = () => {
    navigate('/login')
    localStorage.clear()
  }

  useEffect(() => {
    if (firstTime) {
      setFirstTime(false)
    } else {
      if (canResend) {
        setCountdown(59)
      }
      const timer = setInterval(() => {
        setCountdown((prevCountdown) => {
          if (prevCountdown === 0) {
            clearInterval(timer)
            setCanResend(true)
          }
          return Math.max(prevCountdown - 1, 0)
        })
      }, 1000)
      return () => clearInterval(timer)
    }
  }, [firstTime, canResend])

  useEffect(() => {
    if (Object.keys(errors).length === 0 && submitting) {
      finishSubmit()
    }
  }, [errors])

  useEffect(() => {
    setIsButtonEnabled(inputFields.otp.length > 5)
  }, [inputFields])

  return (
    <div className=" auth-register">
      <div className=" bg-overlay row overlay-height">
        <div className="col-md-6 bg-primary-gredient">
          <SideBarPage />
        </div>
        <div className="col-md-6">
          <div className="inline-padding mt-5 pt-5">
            <h2 className="mb-5 mt-5">VERIFY EMAIL </h2>
            <form onSubmit={handleSubmit}>
              <p>
                <label className="clip-text">Please Enter Otp *</label>
                <div className="password-input">
                  <CommonInput
                    name="otp"
                    placeholder="Please enter otp"
                    onChange={handleChange}
                    required
                    type="number"
                  />
                </div>
                {errors.otp ? <p className="error-text">{errors.otp}</p> : null}
              </p>
              <div className="reset_password_btn">
                <p>
                  <CommonButton
                    label={canResend ? 'Resend Otp' : `Resend Otp in ${countdown}s`}
                    type="button"
                    id="resend_btn"
                    onClick={ResendOtpApiCall}
                    className="primary-button m-auto"
                    disabled={!canResend}
                    context="primary"
                    isPrimary
                  />
                </p>
                <p>
                  <CommonButton
                    label="Submit"
                    type="submit"
                    id="submit_btn"
                    loading={loading}
                    className="primary-button m-auto"
                    disabled={!isButtonEnabled || loading}
                    context="primary"
                    isPrimary
                  />
                </p>
              </div>
              <div className="otp_redirect_login">
                <span onClick={() => redirectLogin()}>
                  Login
                </span>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}
export default OtpVerification
