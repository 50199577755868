import React, { useEffect, useRef, useState } from 'react'
import { toast } from 'react-toastify'
import Skeleton from 'react-loading-skeleton'
// import Cropper from 'react-cropper'
import Building from '../../images/building.svg'
// import 'cropperjs/dist/cropper.css'
import ProfileLogo from '../../images/gbn-one.svg'
import Redirect from '../../images/redirect.svg'
// import Desk from '../../images/desk.svg'
// import Tab from '../../images/tab.svg'
// import Mob from '../../images/mob.svg'
// import View from '../../images/view.svg'
// import Image from '../../images/image.svg'
import LeftIco from '../../images/LeftIco.svg'
import MenuIcon from '../../images/MenuIcon.svg'
import './style.css'
import {
  editStoreProfile,
  imageUpload,
  profileDetails,
  s3ImagePath
} from '../../services/Service'
import { dataDecrypt } from '../../services/http-services'
import CommonImageUpload from '../ImageCroper/StoreImageCroper'
// import Image from '../../images/image.svg'
import SelectImg from '../../images/selectimg.svg'
import CommonButton from '../ButtonComponent/ButtonCommon'
import CommonInput from '../InputComponet/CommonInput'
import { lockScroll, unlockScroll } from '../../helpers/Utility'

const ProfileHeadStore = () => {
  const [profile, setProfile] = useState([])
  const [profileImg, setProfileImg] = useState(null)
  const [profileImgObj, setProfileImgObj] = useState(null)
  const [ShowProfileModel, setShowProfileModel] = useState(false)
  const [isEdited, setIsEdited] = useState(false)
  const [profileImgUrl, setProfileImgUrl] = useState(null)
  const [inputFields, setInputFields] = useState({
    icon: '',
    description: '',
    store_link: ''
  })
  const [isLoading, setIsLoading] = useState(false)

  const componentRef = useRef()

  const clearValue = () => {
    getStoreLink('')
    setInputFields({
      ...inputFields,
      description: '',
      store_link: '',
      icon: ''
    })
    setProfileImgUrl()
  }

  const getStoreLink = (storeLink) => {
    if (!storeLink) return undefined
    return storeLink.startsWith('http') ? storeLink : `http://${storeLink}`
  }

  const profileGet = async () => {
    setIsLoading(true)
    const dataId = localStorage.getItem('app_id')
    const parsedData = JSON.parse(dataId)
    const platFormData = {
      app_id: parsedData.id
    }
    await profileDetails(platFormData).then((res) => {
      if (res.data.success) {
        const data = dataDecrypt(res.data.data)
        setProfile(data)
        setInputFields({
          icon: data[0]?.profile_icon,
          description: data[0]?.description,
          store_link: data[0]?.store_link
        })
        setProfileImg(data[0]?.profile_icon)
        setIsLoading(false)
      }
    })
  }

  const EditProfile = (profileData) => {
    setShowProfileModel(true)
    setInputFields({
      ...inputFields,
      description: profileData.description,
      store_link: profileData.store_link,
      icon: profileData.profile_icon
    })
    setProfileImg(profileData.profile_icon)
  }

  const maxFileSizeMB = 8
  const maxFileSizeBytes = maxFileSizeMB * 1024 * 1024

  const handleFileChange = (file) => {
    setIsEdited(true)
    if (file.size > maxFileSizeBytes) {
      toast.error(`File size exceeds ${maxFileSizeMB} MB`)
      return
    }
    const reader = new FileReader()
    reader.onload = (e) => {
      setProfileImg(file)
      setProfileImgUrl(e.target.result)
      setProfileImgObj(file)
    }
    reader.readAsDataURL(file)
  }

  const handleChange = (e) => {
    setInputFields({ ...inputFields, [e.target.name]: e.target.value })
    setIsEdited(true)
  }

  const HandleCancel = () => {
    setShowProfileModel(false)
    setIsEdited(false)
    clearValue()
  }

  const handleSubmit = async (event) => {
    event.preventDefault()
    await finishSubmit()
    setShowProfileModel(false)
    clearValue()
  }

  const finishSubmit = async () => {
    if (profileImgObj) {
      const res = await imageUpload(profileImgObj)
      if (res.data.success) {
        inputFields.icon = dataDecrypt(res.data.data)
        await storeProfile()
      } else {
        toast.error(res?.data?.message)
      }
    } else {
      await storeProfile()
    }
  }

  const storeProfile = () => {
    const dataId = localStorage.getItem('app_id')
    const parsedData = JSON.parse(dataId)
    const platFormData = {
      app_id: parsedData.id,
      icon: inputFields.icon,
      description: inputFields?.description,
      store_link: inputFields?.store_link
    }
    editStoreProfile(platFormData, inputFields).then((res) => {
      if (res.data.success) {
        profileGet()
        toast.success(res?.data?.message)
        setIsEdited(false)
      } else {
        toast.error(res?.data?.message)
      }
    })
  }

  useEffect(() => {
    profileGet()
  }, [])

  useEffect(() => {
    if (ShowProfileModel) {
      lockScroll()
    } else {
      unlockScroll()
    }

    return () => {
      unlockScroll()
    }
  }, [ShowProfileModel])

  return (
    <div>
      <div className="profile-builder-main-header position-relative mb-40">
        {/* <div className="view-button">
          <button type="button" className="active">
            <img alt="" src={Desk} />
          </button>
          <button type="button">
            <img alt="" src={Tab} />
          </button>
          <button type="button">
            <img alt="" src={Mob} />
          </button>
          <button type="button">
            <img alt="" src={View} />
          </button>
        </div> */}
        {/* <div className="general-settings">
          <Link className="gredient-txt">General Settings</Link>
        </div> */}
      </div>
      {isLoading ? (
        <div className="profile-head-builder">
          <div className="profile-builder-header mb-20">
            <button type="button">
              <Skeleton width={25} height={25} />
            </button>
            <div className="profile-heading">
              <Skeleton width={35} height={35} />
              <span>
                <Skeleton width={150} height={25} />
              </span>
            </div>
            <button type="button">
              <Skeleton width={25} height={25} />
            </button>
          </div>

          <div className="profile-builder-main">
            <div className="profile-personal-detail">
              <Skeleton className="profile_ske_icon" />
              <div className="about-personal-profile">
                <h2>
                  <Skeleton width={150} height={20} />
                </h2>
                <h3>
                  <Skeleton width={180} height={20} />
                </h3>
                <p>
                  <Skeleton width={150} height={20} />
                </p>
                <span>
                  <Skeleton width={130} height={20} />
                </span>
              </div>
            </div>
            <div className="profile-account-details">
              <ul className="about-account-insignt">
                <li>
                  <h4>
                    <Skeleton width={20} />
                  </h4>
                  <p>
                    <Skeleton width={50} height={20} />
                  </p>
                </li>
                <li>
                  <h4>
                    <Skeleton width={20} />
                  </h4>
                  <p className="Follower_text">
                    <Skeleton width={50} height={20} />
                  </p>
                </li>
                <li>
                  <h4>
                    <Skeleton width={20} />
                  </h4>
                  <p>
                    <Skeleton width={50} height={20} />
                  </p>
                </li>
              </ul>
              <div className="follow-connenct">
                <div className="buttons-group">
                  <Skeleton
                    className="rounded-5 profile-skeleton-btn"
                    height={40}
                  />
                  <Skeleton
                    className="rounded-5 profile-skeleton-btn"
                    height={40}
                  />
                </div>
                <div>
                  <Skeleton className="rounded-5" width={60} height={60} />
                </div>
              </div>
              <div className="follwer-shows">
                <div className="follower-img-group d-flex">
                  <Skeleton
                    className="rounded-5 skeleton-img-group"
                    width={30}
                    height={30}
                  />
                  <Skeleton
                    className="rounded-5 skeleton-img-group"
                    width={30}
                    height={30}
                  />
                  <Skeleton
                    className="rounded-5 skeleton-img-group"
                    width={30}
                    height={30}
                  />
                  <Skeleton
                    className="rounded-5 skeleton-img-group"
                    width={30}
                    height={30}
                  />
                </div>
                <p>
                  <span className="gredient-txt">
                    <Skeleton className="follow-text" height={20} />
                  </span>
                </p>
              </div>
              <div className="profile-redirect">
                <Skeleton width={25} height={25} />
              </div>
            </div>
          </div>
        </div>
      ) : (
        profile.map((data, i) => (
          <div key={i} className="profile-head-builder">
            <div className="profile-builder-header mb-20">
              <button type="button">
                <img alt="" src={LeftIco} />
              </button>
              <div className="profile-heading">
                <img alt="" src={Building} />
                <span>{data.user_name}</span>
              </div>
              <button type="button" onClick={() => EditProfile(data)}>
                <img alt="" src={MenuIcon} />
              </button>
            </div>

            <div className="profile-builder-main">
              <div className="profile-personal-detail">
                <div className="profile-build-image">
                  <img alt="" src={s3ImagePath + data.profile_icon} />
                </div>
                <div className="about-personal-profile">
                  <h2>{data.user_name}</h2>
                  <h3>FITNESS & GESUNDHEIT</h3>
                  <p>
                    {' '}
                    {data.description.slice(0, 65)}
                    {data.description.length > 65 && '...'}
                  </p>
                  <span>Your Personal Trainer</span>
                </div>
              </div>
              <div className="profile-account-details">
                <ul className="about-account-insignt">
                  <li>
                    <h4>302</h4>
                    <p>Posts</p>
                  </li>
                  <li>
                    <h4>549 K</h4>
                    <p>Followers</p>
                  </li>
                  <li>
                    <h4>08 M</h4>
                    <p>Likes</p>
                  </li>
                </ul>
                <div className="follow-connenct">
                  <div className="buttons-group">
                    <button type="button" className="success-btn">
                      Follow +
                    </button>
                    <button type="button" className="primary-btn p-10">
                      Contact
                    </button>
                  </div>
                  <div>
                    <img alt="" src={ProfileLogo} />
                  </div>
                </div>
                <div className="follwer-shows">
                  <div className="follower-img-group">
                    <img alt="" src="./../images/task-profile1.png" />
                    <img alt="" src="./../images/task-profile2.png" />
                    <img alt="" src="./../images/task-profile3.png" />
                    <img alt="" src="./../images/task-profile4.png" />
                  </div>
                  <p>
                    <span className="gredient-txt">
                      <b>torbenschuett, jensahlen</b>
                      {' '}
                      und
                      <b> 10 weitere Personen</b>
                      {' '}
                      sind Follower
                    </span>
                  </p>
                </div>
                <div className="profile-redirect">
                  <a
                    className="gredient-txt"
                    target="_blank"
                    href={getStoreLink(data.store_link)}
                    rel="noreferrer"
                  >
                    <img alt="" src={Redirect} />
                    {data.store_link ? data.store_link : 'No link'}
                  </a>
                </div>
              </div>
            </div>
          </div>
        ))
      )}
      {ShowProfileModel && (
        <div className="add-item-model edit-profile">
          <div
            className="add-item-content edit-creator px-0 pb-0"
            ref={componentRef}
          >
            <h3 className="text-center">Edit Store Profile</h3>
            <form onSubmit={handleSubmit}>
              {/* <div className="scroll-bars position-relative pb-2 profile_modal"> */}
              <div className="scroll-bars-no-scroll position-relative pb-2 profile_modal">
                <div className="profile-pics w-100 h-100 mt-4">
                  <div className="profile-pic-image">
                    <CommonImageUpload
                      handleImageChange={handleFileChange}
                      index={0}
                      forImage="create-profile"
                      className="profile-image-store"
                      selectedImg={profileImgUrl || s3ImagePath + profileImg}
                      defaultImgCamera={SelectImg}
                    />
                  </div>
                </div>
                <div className="item-details-input pt-0">
                  <div>
                    <label className="mb-2">Decription</label>
                    <CommonInput
                      name="description"
                      placeholder="Type here"
                      onChange={handleChange}
                      className="description-input"
                      value={inputFields.description}
                      maxLength={250}
                      isTextArea
                    />
                  </div>
                  <div className="input-grid">
                    <div>
                      <label className="mb-2">Store Link</label>
                      <CommonInput
                        name="store_link"
                        placeholder="Type here"
                        onChange={handleChange}
                        value={inputFields.store_link}
                      />
                    </div>
                  </div>
                  <div className="button-row edit-model">
                    <CommonButton
                      label="Cancel"
                      onClick={HandleCancel}
                      isPrimary={false}
                      context="Profile"
                    />
                    <CommonButton
                      label="Save"
                      type="submit"
                      // onClick={VideoDetailModel}
                      isPrimary
                      disabled={!isEdited}
                      context="Profile"
                    />
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  )
}
export default ProfileHeadStore
