import React, { useState, useEffect, useRef } from 'react'
import { toast } from 'react-toastify'
import DatePicker from 'react-datepicker'
import { Link, useNavigate } from 'react-router-dom'
import SideBarPage from './SidebarPage'
import calendar from '../../images/calendar.svg'
import AddImageIco from '../../images/AddImageIco.svg'
import { imageUpload, userUpdateProfile } from '../../services/Service'
import 'react-datepicker/dist/react-datepicker.css'
import { dataDecrypt } from '../../services/http-services'

import '../../App.css'
import CommonImageUpload from '../ImageCroper/StoreImageCroper'
import useInfo from '../../images/useInfoImage.svg'
import CommonButton from '../ButtonComponent/ButtonCommon'
import CommonInput from '../InputComponet/CommonInput'

const UserInformation = () => {
  const navigate = useNavigate()

  const [profileImg, setProfileImg] = useState(null)
  const [profileImgObj, setProfileImgObj] = useState(null)
  const [inputFields, setInputFields] = useState({
    company_name: '',
    company_address: '',
    company_city: '',
    company_vat: '',
    company_zip_code: 1,
    date_of_birth: '',
    full_name: '',
    profile_image: ''
  })
  const [errors, setErrors] = useState({})
  const [submitting, setSubmitting] = useState(false)
  const [isButtonEnabled, setIsButtonEnabled] = useState(false)
  const [startDate, setStartDate] = useState(null)
  const [showSuccessModal] = useState(false)
  const [loading, setLoading] = useState(false)

  const datePickerRef = useRef(null)

  const validateValues = () => {
    const errors = {}
    if (!inputFields.full_name.match(/^[a-zA-ZÀ-Ÿ-. ]*$/)) {
      errors.full_name = 'Please enter valid name'
    }
    console.log(formatDate(startDate))
    if (!formatDate(startDate).match(/^\d{4}-\d{2}-\d{2}$/)) {
      errors.date_of_birth = 'Please enter valid date format'
    }
    if (!inputFields.company_zip_code.match(/^[0-9]*$/)) {
      errors.company_zip_code = 'Please enter valid zip code'
    }
    if (!inputFields.company_vat.match(/^[A-Z]{2}[A-Z0-9]{2,30}$/)) {
      errors.company_vat = 'Please enter valid Vat ID'
    }
    return errors
  }
  const allowedTypes = ['image/jpeg', 'image/png', 'image/jpg']
  const maxFileSizeMB = 8
  const maxFileSizeBytes = maxFileSizeMB * 1024 * 1024

  function formatDate(date) {
    const d = new Date(date)
    let month = `${d.getMonth() + 1}`
    let day = `${d.getDate()}`
    const year = d.getFullYear()

    if (month.length < 2) month = `0${month}`
    if (day.length < 2) day = `0${day}`

    return [year, month, day].join('-')
  }

  const handleImageUpload = (file) => {
    if (file) {
      if (allowedTypes.includes(file.type)) {
        if (file.size > maxFileSizeBytes) {
          toast.error(`File size exceeds ${maxFileSizeMB} MB`)
          return
        }
        const imgUrl = URL.createObjectURL(file)
        setProfileImg(imgUrl)
        setProfileImgObj(file)
      } else {
        toast.error('Please upload a valid image file (JPEG/JPG/PNG)')
      }
    }
  }

  // ... existing code ...

  const handleChange = (e) => {
    setInputFields({ ...inputFields, [e.target.name]: e.target.value })
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    setErrors(validateValues(inputFields))
    setSubmitting(true)
  }

  const finishSubmit = () => {
    imageUpload(profileImgObj).then((res) => {
      console.log(res)
      if (res.data.success) {
        inputFields.profile_image = dataDecrypt(res.data.data)
        userDataSubmit()
      } else {
        toast.error(res?.data?.message)
      }
    })
  }

  const userDataSubmit = () => {
    setLoading(true)
    const token = localStorage.getItem('token')
    inputFields.date_of_birth = startDate
    userUpdateProfile(inputFields, token).then((res) => {
      if (res.data.success) {
        localStorage.setItem(
          'userData',
          JSON.stringify(dataDecrypt(res.data.data))
        )
        navigate('/create-profile')
        setLoading(false)
        toast.success(res?.data?.message)
      } else {
        setLoading(false)
        toast.error(res?.data?.message)
      }
    })
  }

  const handleButtonClick = () => {
    if (datePickerRef.current) {
      datePickerRef.current.setOpen(true)
    }
  }

  const handleDateChange = (date) => {
    const today = new Date()
    const minDate = new Date(
      today.getFullYear() - 15,
      today.getMonth(),
      today.getDate()
    )

    if (date <= minDate) {
      setStartDate(date)
    } else {
      setStartDate(null)
      if (datePickerRef.current) {
        datePickerRef.current.setOpen(true)
      }
      toast.error(
        'App World requires everyone to be at least 15 years old before they can create an account '
      )
    }
  }
  const today = new Date()
  const minYear = today.getFullYear() - 15
  const maxYear = today.getFullYear() - 100

  const years = []
  for (let year = minYear; year >= maxYear; year--) {
    years.push(year)
  }

  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ]

  useEffect(() => {
    if (Object.keys(errors).length === 0 && submitting) {
      finishSubmit()
    }
  }, [errors])
  useEffect(() => {
    setIsButtonEnabled(
      inputFields.company_address.length > 0 &&
        inputFields.company_city.length > 0 &&
        inputFields.company_vat.length > 0 &&
        inputFields.company_zip_code.length > 5 &&
        startDate &&
        inputFields.full_name.length > 0 &&
        profileImg
    )
  }, [inputFields])

  return (
    <div className="auth-register user-info">
      <div className="row overlay-height bg-overlay">
        <div className="col-md-6 bg-primary-gredient">
          <SideBarPage />
        </div>
        <div className="col-md-6 h-100 overflow-y-auto">
          <div className="inline-padding">
            <h2 className="text-center">BUSINESS USER INFORMATION</h2>
            <div className="input-profile-img mb-4 mt-5">
              <div className="d-flex user-profile-crop">
                <div>
                  <CommonImageUpload
                    handleImageChange={handleImageUpload}
                    index={0}
                    forImage="profile"
                    className="profile-upload"
                    selectedImg={profileImg}
                    defaultImg={useInfo}
                    defaultImgCamera={AddImageIco}
                  />
                </div>
              </div>
            </div>
            <form onSubmit={handleSubmit}>
              <p>
                <label className="clip-text">Full Name *</label>
                <div>
                  <CommonInput
                    className="ps-3"
                    name="full_name"
                    placeholder="Enter full name"
                    required
                    onChange={handleChange}
                  />
                </div>
                {errors.full_name ? (
                  <p className="error-text">{errors.full_name}</p>
                ) : null}
              </p>
              <div className="d-flex col-gap-20">
                <p className="w-50">
                  <label className="clip-text">Date of Birth *</label>
                  <div className="celender">
                    <DatePicker
                      ref={datePickerRef}
                      selected={startDate}
                      required
                      showMonthDropdown
                      autoComplete="off"
                      showYearDropdown
                      onChange={handleDateChange}
                      name="date_of_birth"
                      dateFormat="yyyy-MM-dd"
                      placeholderText="Please Choose Date"
                      maxDate={
                        new Date(
                          today.getFullYear() - 15,
                          today.getMonth(),
                          today.getDate()
                        )
                      }
                      onKeyDown={(e) => {
                        if (e.key === 'Enter' || e.key === 'Tab') {
                          e.preventDefault()
                        }
                      }}
                      renderCustomHeader={({
                        date,
                        changeYear,
                        changeMonth,
                        decreaseMonth,
                        increaseMonth,
                        prevMonthButtonDisabled,
                        nextMonthButtonDisabled
                      }) => (
                        <div className="datepicker-header">
                          <button
                            type="button"
                            className="datepicker-button"
                            onClick={(e) => {
                              e.preventDefault()
                              decreaseMonth()
                            }}
                            disabled={prevMonthButtonDisabled}
                          >
                            {'<'}
                          </button>
                          <select
                            className="datepicker-select"
                            value={date.getFullYear()}
                            onChange={({ target: { value } }) => changeYear(Number(value))}
                          >
                            {years.map((option) => (
                              <option key={option} value={option}>
                                {option}
                              </option>
                            ))}
                          </select>
                          <select
                            className="datepicker-select"
                            value={months[date.getMonth()]}
                            onChange={({ target: { value } }) => {
                              changeMonth(months.indexOf(value))
                            }}
                          >
                            {months.map((option) => (
                              <option key={option} value={option}>
                                {option}
                              </option>
                            ))}
                          </select>
                          <button
                            type="button"
                            className="datepicker-button"
                            onClick={(e) => {
                              e.preventDefault()
                              increaseMonth()
                            }}
                            disabled={nextMonthButtonDisabled}
                          >
                            {'>'}
                          </button>
                        </div>
                      )}
                    />
                    <button
                      className="celender-button"
                      type="button"
                      onClick={handleButtonClick}
                    >
                      <img src={calendar} alt="" />
                    </button>
                  </div>
                  {errors.date_of_birth ? (
                    <p className="error-text">{errors.date_of_birth}</p>
                  ) : null}
                </p>
                <p className="w-50">
                  <label className="clip-text">Company Name (optional)</label>
                  <div className="">
                    <CommonInput
                      name="company_name"
                      className="ps-3"
                      placeholder="Enter company name"
                      onChange={handleChange}
                    />
                  </div>
                </p>
              </div>
              <h3>Company Address</h3>
              <div className="d-flex col-gap-20">
                <p className="w-50">
                  <label className="clip-text">Address *</label>
                  <div className="">
                    <CommonInput
                      name="company_address"
                      className="ps-3"
                      placeholder="Enter address"
                      required
                      onChange={handleChange}
                    />
                  </div>
                </p>
                <p className="w-50">
                  <label className="clip-text">VAT ID *</label>
                  <div className="">
                    <CommonInput
                      name="company_vat"
                      className="ps-3"
                      placeholder="Enter vat id"
                      required
                      onChange={handleChange}
                    />
                  </div>
                  {errors.company_vat ? (
                    <p className="error-text">{errors.company_vat}</p>
                  ) : null}
                </p>
              </div>

              <div className="d-flex col-gap-20">
                <p className="w-50">
                  <label className="clip-text">City *</label>
                  <div className="">
                    <CommonInput
                      name="company_city"
                      className="ps-3"
                      placeholder="Enter city"
                      required
                      onChange={handleChange}
                    />
                  </div>
                </p>
                <p className="w-50">
                  <label className="clip-text">Zip Code *</label>
                  <div className="">
                    <CommonInput
                      name="company_zip_code"
                      className="ps-3"
                      placeholder="Enter zip code"
                      required
                      maxLength={6}
                      onChange={handleChange}
                    />
                  </div>
                  {errors.company_zip_code ? (
                    <p className="error-text">{errors.company_zip_code}</p>
                  ) : null}
                </p>
              </div>

              <p className="d-flex justify-content-end mt-3">
                <CommonButton
                  label="Next"
                  type="submit"
                  id="sub_btn"
                  loading={loading}
                  className="primary-button w-auto"
                  disabled={!isButtonEnabled || loading}
                  context="primary"
                  isPrimary
                />
              </p>
            </form>
          </div>
        </div>
      </div>
      {showSuccessModal && (
        <div className="custom-model-overlay">
          <div className="success-modal">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="62"
              height="62"
              viewBox="0 0 62 62"
              fill="none"
            >
              <path
                d="M62 30.9998C62 37.131 60.1821 43.1245 56.7757 48.2224C53.3694 53.3204 48.5277 57.2938 42.8631 59.6402C37.1986 61.9865 30.9654 62.6005 24.952 61.4044C18.9386 60.2082 13.4152 57.2557 9.07979 52.9203C4.74436 48.5849 1.79188 43.0611 0.595741 37.0476C-0.600401 31.0342 0.0132298 24.8014 2.35955 19.1369C4.70586 13.4724 8.67931 8.6306 13.7772 5.22427C18.8752 1.81794 24.8687 0 30.9999 0C39.2203 0.00436442 47.1029 3.27177 52.9156 9.08446C58.7282 14.8972 61.9956 22.7794 62 30.9998Z"
                fill="url(#paint0_linear_1862_74860)"
              />
              <path
                d="M46.8556 23.8712L28.3674 42.0935C28.1057 42.3562 27.7935 42.565 27.4491 42.7074C27.1048 42.8498 26.7351 42.9231 26.3617 42.9231C25.9883 42.9231 25.6187 42.8498 25.2743 42.7074C24.93 42.565 24.6178 42.3562 24.356 42.0935L15.1046 32.9823C14.5853 32.4531 14.298 31.7436 14.3048 31.0071C14.3116 30.2707 14.6118 29.5663 15.1407 29.0465C15.6696 28.5267 16.3846 28.2331 17.1312 28.2291C17.8778 28.2251 18.5962 28.5113 19.1308 29.0255L26.369 36.1507L42.8294 19.8997C43.0937 19.6389 43.4073 19.4319 43.7527 19.2908C44.0981 19.1497 44.4684 19.0769 44.8423 19.0769C45.2162 19.0769 45.5865 19.1497 45.9319 19.2908C46.2773 19.4319 46.5912 19.6389 46.8556 19.8997C47.12 20.1604 47.3298 20.4701 47.4728 20.8108C47.6159 21.1515 47.6893 21.5164 47.6893 21.8852C47.6893 22.254 47.6159 22.6193 47.4728 22.96C47.3298 23.3007 47.12 23.6104 46.8556 23.8712Z"
                fill="white"
              />
              <defs>
                <linearGradient
                  id="paint0_linear_1862_74860"
                  x1="15.5"
                  y1="4.1509"
                  x2="46.5"
                  y2="57.8491"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#00FF00" />
                  <stop offset="0.61" stopColor="#00DA00" />
                  <stop offset="1" stopColor="#00C700" />
                </linearGradient>
              </defs>
            </svg>
            <h4>Registration Completed</h4>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. In massa
              ut imperdiet gravida amet, feugiat sit.
              {' '}
            </p>
            <Link to="/app-icons" className="w-100">
              <button className="secondary-button m-auto" type="button">
                <span>OK</span>
              </button>
            </Link>
          </div>
        </div>
      )}
    </div>
  )
}
export default UserInformation
