/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable max-len */
import React, { useCallback, useEffect, useState } from 'react'
import './Feeds.css'
import { toast } from 'react-toastify'
import Skeleton from 'react-loading-skeleton'
import DropLink from '../../images/three-dot.svg'
import Like from '../../images/group-like.svg'
import UnLike from '../../images/unlike.svg'
import Comment from '../../images/group-comment.svg'
import Share from '../../images/group-share.svg'
import Save from '../../images/group-save.svg'
import Saved from '../../images/group-saved.svg'
import {
  getFeed,
  likeFeed,
  saveFeed,
  s3ImagePath,
  s3ImagePathCreator,
  feedCommentData,
  feedCommentAddData,
  feedCommentEditData,
  feedCommentDeleteData,
  feedCommentLikeData,
  feedCommentReplyData,
  getReportReasons,
  feedReportData,
  creatorList
} from '../../services/Service'
import { dataDecrypt } from '../../services/http-services'
import CloseIco from '../../images/CloseIco.svg'
import Delete from '../../images/delete.svg'
import { debounce, lockScroll, unlockScroll } from '../../helpers/Utility'
import Loader from '../Loader/Loader'
import CommentPagination from '../Pagination/CommentPagination'
import CommonInput from '../InputComponet/CommonInput'
import Pagination from '../Pagination/Pagination'

const Feeds = () => {
  // const userData = JSON.parse(localStorage.getItem('userData'))
  const profileData = JSON.parse(localStorage.getItem('profileData'))

  const [feedData, setFeedData] = useState([])
  const [comments, setComments] = useState([])
  const [selectedId, setSelectedId] = useState('')
  const [commentSection, setCommentSection] = useState(false)
  const [newComment, setNewComment] = useState('')
  const [showReplies, setShowReplies] = useState({})
  const [Replied, setShowReplied] = useState('')
  const [editCommentId, setEditCommentId] = useState(null)
  const [editReplyId, setEditReplyId] = useState(null)
  const [editedCommentText, setEditedCommentText] = useState('')
  const [editedReplyText, setEditedReplyText] = useState('')
  const [isPopupOpen, setIsPopupOpen] = useState(false)
  const [popupData, setPopupData] = useState([])
  const [shareData, setShareData] = useState([])
  const [listData, setListData] = useState(false)
  const [postId, setPostId] = useState([])
  const [isLoadingComments, setIsLoadingComments] = useState(false)
  const [isFeedLoading, setIsFeedLoading] = useState(false)
  const [isFeedReportLoading, setIsFeedReportLoading] = useState(false)
  const [isCreatorListDataLoading, setIsCreatorListDataLoading] =
    useState(false)
  const [currentPageComment, setCurrentPageComment] = useState(1)
  const [currentPage, setCurrentPage] = useState(1)
  const [isDeleting, setIsDeleting] = useState(false)
  const [commentIndex, setCommentIndex] = useState(null)
  const [loadingCommentId, setLoadingCommentId] = useState(null)

  const [feedCurrentPage, setFeedCurrentPage] = useState(1)
  const [feedTotalPages, setFeedTotalPages] = useState(1)

  const getFeedData = (feedCurrentPage) => {
    setIsFeedLoading(true)
    const data = { app_id: JSON.parse(localStorage.getItem('app_id')).id }
    getFeed(data, feedCurrentPage).then((res) => {
      if (res.data.success) {
        setFeedData(dataDecrypt(res.data.data).data)
        setFeedCurrentPage(dataDecrypt(res.data.data).current_page)
        setFeedTotalPages(dataDecrypt(res.data.data).last_page)
        setIsFeedLoading(false)
      }
    })
  }

  const onClickLike = (post_id, index) => {
    const data = {
      app_id: JSON.parse(localStorage.getItem('app_id')).id,
      post_id
    }
    likeFeed(data).then((res) => {
      if (res.data.success) {
        const newArr = [...feedData]
        newArr[index].is_like = !newArr[index].is_like
        setFeedData(newArr)
        toast.success(res?.data?.message)
      }
    })
  }

  const onClickSave = (post_id, index) => {
    const data = {
      app_id: JSON.parse(localStorage.getItem('app_id')).id,
      post_id
    }
    saveFeed(data).then((res) => {
      if (res.data.success) {
        const newArr = [...feedData]
        newArr[index].is_save = !newArr[index].is_save
        setFeedData(newArr)
        toast.success(res?.data?.message)
      }
    })
  }

  const commentClear = () => {
    setComments([])
    setSelectedId([])
    setNewComment('')
    setShowReplied('')
    setEditedCommentText('')
    setEditedReplyText('')
  }

  const handlePageChange = (pageNumber) => {
    getFeedData(pageNumber)
    setFeedCurrentPage(pageNumber)
  }

  // feeds comment

  const onClickComment = (post_id) => {
    setSelectedId(post_id)
    if (!isLoadingComments) {
      setIsLoadingComments(true)
      feedComment(post_id)
      setCommentSection(true)
    }
  }

  const feedComment = (post_id) => {
    const dataId = localStorage.getItem('app_id')
    const parsedData = JSON.parse(dataId)
    const platFormData = {
      app_id: parsedData.id,
      post_id
    }
    feedCommentData(platFormData).then((res) => {
      if (res.data.success) {
        const dataComment = dataDecrypt(res.data.data).data.map((d, i) => ({
          id: i + 1,
          text: d.comment,
          post_id: d.post_id,
          is_like: d.is_like,
          reply_id: d.comment_id,
          total_like: d.total_like,
          reply: d.reply.map((subData, subIndex) => ({
            id: subIndex + 1,
            text: subData.comment,
            reply_id: subData.comment_id,
            total_like: subData.total_like,
            is_like: subData.is_like,
            user: {
              name: subData.user_name,
              profilePic: s3ImagePath + subData.profile_icon
            }
          })),
          user: {
            name: d.user_name,
            profilePic: s3ImagePath + d.profile_icon
          }
        }))
        setComments(dataComment)
        setIsLoadingComments(false)
      }
    })
  }

  const feedAddComment = (comment, selectedId) => {
    const dataId = localStorage.getItem('app_id')
    const parsedData = JSON.parse(dataId)
    const platFormData = {
      app_id: parsedData.id,
      post_id: selectedId,
      comment
    }
    feedCommentAddData(platFormData).then((res) => {
      if (res.data.success) {
        feedComment(selectedId)
        setLoadingCommentId(null)
      }
    })
  }

  const feedAddCommentReply = (comment_id, replyText) => {
    const dataId = localStorage.getItem('app_id')
    const parsedData = JSON.parse(dataId)
    const platFormData = {
      app_id: parsedData.id,
      comment_id,
      comment: replyText
    }
    feedCommentReplyData(platFormData).then((res) => {
      if (res.data.success) {
        feedComment(selectedId)
      }
    })
  }

  const feedCommentEdit = (reply_id, comment) => {
    const dataId = localStorage.getItem('app_id')
    const parsedData = JSON.parse(dataId)
    const platFormData = {
      app_id: parsedData.id,
      comment_id: reply_id,
      comment
    }
    feedCommentEditData(platFormData).then((res) => {
      if (res.data.success) {
        feedComment(selectedId)
      }
    })
  }

  const feedCommentEditReply = (comment_id, replyText) => {
    const dataId = localStorage.getItem('app_id')
    const parsedData = JSON.parse(dataId)
    const platFormData = {
      app_id: parsedData.id,
      post_id: selectedId,
      comment_id,
      comment: replyText
    }
    feedCommentEditData(platFormData).then((res) => {
      if (res.data.success) {
        feedComment(selectedId)
      }
    })
  }

  const feedCommentDelete = (reply_id) => {
    setIsDeleting(true)
    const dataId = localStorage.getItem('app_id')
    const parsedData = JSON.parse(dataId)
    const platFormData = {
      app_id: parsedData.id,
      comment_id: reply_id
    }
    feedCommentDeleteData(platFormData).then((res) => {
      if (res.data.success) {
        feedComment(selectedId)
        setIsDeleting(false)
        toast.success(res?.data?.message)
      } else {
        setIsDeleting(false)
        toast.success(res?.data?.message)
      }
    })
  }

  const feedCommentDeleteReply = (commentId, replyId) => {
    setIsDeleting(true)
    const dataId = localStorage.getItem('app_id')
    const parsedData = JSON.parse(dataId)
    const platFormData = {
      app_id: parsedData.id,
      comment_id: commentId,
      reply_id: replyId
    }
    feedCommentDeleteData(platFormData).then((res) => {
      if (res.data.success) {
        feedComment(selectedId)
        setIsDeleting(false)
        toast.success(res?.data?.message)
      } else {
        setIsDeleting(false)
        toast.success(res?.data?.message)
      }
    })
  }

  const feedCommentLike = (reply_id) => {
    const dataId = localStorage.getItem('app_id')
    const parsedData = JSON.parse(dataId)
    const platFormData = {
      app_id: parsedData.id,
      comment_id: reply_id
    }
    feedCommentLikeData(platFormData).then((res) => {
      if (res.data.success) {
        feedComment(selectedId)
        toast.success(res?.data?.message)
      }
    })
  }

  const feedCommentReplyLike = (reply_id) => {
    const dataId = localStorage.getItem('app_id')
    const parsedData = JSON.parse(dataId)
    const platFormData = {
      app_id: parsedData.id,
      comment_id: reply_id
    }
    feedCommentLikeData(platFormData).then((res) => {
      if (res.data.success) {
        feedComment(selectedId)
        toast.success(res?.data?.message)
      }
    })
  }

  const handleAddComment = (comment) => {
    if (newComment.trim() !== '') {
      const newCommentObj = {
        id: comments.length + 1,
        is_like: false,
        text: newComment,
        post_id: selectedId,
        total_like: 0,
        reply: [],
        user: {
          name: profileData?.user_name,
          profilePic: s3ImagePath + profileData?.profile_icon
        }
      }
      setLoadingCommentId(newCommentObj.id)
      setComments([...comments, newCommentObj])
      setNewComment('')
    }
    feedAddComment(comment, selectedId)
  }

  const handleLikeComment = (commentId) => {
    const updatedComments = comments.map((comment) => {
      if (comment.id === commentId) {
        return { ...comment, total_like: comment.total_like + 1 }
      }
      return comment
    })
    setComments(updatedComments)
    feedCommentLike(commentId)
  }

  const handleLikeReply = (commentId) => {
    const updatedComments = comments.map((comment) => {
      if (comment.id === commentId) {
        return { ...comment, total_like: comment.total_like + 1 }
      }
      return comment
    })
    setComments(updatedComments)
    feedCommentReplyLike(commentId)
  }

  const handleAddReply = (commentId, replyText) => {
    const updatedComments = comments.map((comment) => {
      if (comment.id === commentId) {
        return {
          ...comment,
          reply: [
            ...comment.reply,
            {
              id: comment.reply.length + 1,
              text: replyText,
              total_like: 0,
              user: {
                name: profileData.user_name,
                profilePic: s3ImagePath + profileData.profile_icon
              }
            }
          ]
        }
      }
      return comment
    })
    setComments(updatedComments)
    setShowReplied('')
    feedAddCommentReply(commentId, replyText)
  }

  const handleDeleteReply = (commentId, replyId) => {
    const updatedComments = comments.map((comment) => {
      if (comment.id === commentId) {
        return {
          ...comment,
          reply: comment.reply.filter((reply) => reply.id !== replyId)
        }
      }
      return comment
    })
    setComments(updatedComments)
    feedCommentDeleteReply(replyId, commentId)
  }

  const toggleReplies = (commentId, forceCloseAll = false) => {
    if (forceCloseAll) {
      setShowReplies({})
    } else {
      setShowReplies((prevState) => ({
        // ...prevState,
        [commentId]: !prevState[commentId]
      }))
    }
    setShowReplied('')
  }

  const HandleCancel = () => {
    setCommentSection(false)
    commentClear()
    toggleReplies(null, true)
  }

  const handleEditComment = (commentId, text) => {
    setEditCommentId(commentId)
    setEditedCommentText(text)
  }

  const handleSaveComment = (commentId, reply) => {
    const updatedComments = comments.map((comment) => (comment.id === commentId
        ? { ...comment, text: editedCommentText }
        : comment))
    feedCommentEdit(commentId, reply)
    setComments(updatedComments)
    setEditCommentId(null)
    setEditedCommentText('')
  }

  const handleEditReply = (replyId, text) => {
    setEditReplyId(replyId)
    setEditedReplyText(text)
  }

  const handleSaveReply = (commentId, replyId) => {
    const updatedComments = comments.map((comment) => (comment.id === commentId
        ? {
            ...comment,
            reply: comment.reply.map((reply) => (reply.id === replyId ? { ...reply, text: editedReplyText } : reply))
          }
        : comment))
    feedCommentEditReply(commentId, replyId)
    setComments(updatedComments)
    setEditReplyId(null)
    setEditedReplyText('')
  }

  const close = () => {
    setIsPopupOpen(false)
  }

  const showReportList = (post_id) => {
    setPostId(post_id)
    setIsPopupOpen(true)
    getReportReasonsData()
  }

  const getReportReasonsData = async () => {
    setIsFeedReportLoading(true)
    getReportReasons({}).then((res) => {
      if (res.data.success) {
        setPopupData(dataDecrypt(res.data.data))
        setIsFeedReportLoading(false)
      }
    })
  }

  const feedReport = (report_id) => {
    const platFormData = {
      post_id: postId,
      reason_id: report_id
    }
    feedReportData(platFormData).then((res) => {
      if (res.data.success) {
        toast.success(res?.data?.message)
        setIsPopupOpen(false)
      }
    })
  }

  const clearValue = () => {
    setShareData([])
  }
  const showShareList = () => {
    setListData(true)
    creatorListData()
  }
  const handleCancel = () => {
    setListData(false)
    clearValue()
  }

  const creatorListData = (search) => {
    setIsCreatorListDataLoading(true)
    const dataId = localStorage.getItem('app_id')
    const parsedData = JSON.parse(dataId)
    const platform = {
      app_id: parsedData.id,
      search
    }
    creatorList(platform).then((res) => {
      if (res.data.success) {
        setShareData(dataDecrypt(res.data.data))
        setIsCreatorListDataLoading(false)
      }
    })
  }

  const handleSearch = useCallback(
    debounce((inputVal) => creatorListData(inputVal), 500),
    []
  )

  // share list pagination

  const videosPerPage = 6
  const indexOfLastVideo = currentPage * videosPerPage
  const indexOfFirstVideo = indexOfLastVideo - videosPerPage
  const shareDataList = shareData.slice(indexOfFirstVideo, indexOfLastVideo)
  const totalVideos = shareData.length
  const totalPages = Math.ceil(totalVideos / videosPerPage)

  const paginate = (pageNumber) => setCurrentPage(pageNumber)

  const renderPaginationButtons = () => {
    const numButtonsToShow = 3

    let startPage = currentPage - Math.floor(numButtonsToShow / 2)
    startPage = Math.max(startPage, 1)

    let endPage = startPage + numButtonsToShow - 1
    if (endPage > totalPages) {
      endPage = totalPages
      startPage = Math.max(endPage - numButtonsToShow + 1, 1)
    }

    return [...Array(endPage - startPage + 1)].map((_, index) => {
      const pageNumber = startPage + index
      return (
        <button
          type="button"
          key={pageNumber}
          className={`pagination-buttons ${
            currentPage === pageNumber ? 'active' : ''
          }`}
          onClick={() => paginate(pageNumber)}
        >
          {pageNumber}
        </button>
      )
    })
  }

  const handlePrevPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1))
  }

  const handleNextPage = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages))
  }

  // comment pagination

  const commentsPerPage = 5
  const indexOfLastComment = currentPageComment * commentsPerPage
  const indexOfFirstComment = indexOfLastComment - commentsPerPage
  const commentsList = comments.slice(indexOfFirstComment, indexOfLastComment)
  const totalCommentPages = Math.ceil(comments.length / commentsPerPage)

  const handlePageChanges = (pageNumber) => {
    setCurrentPageComment(pageNumber)
  }

  useEffect(() => {
    getFeedData(feedCurrentPage)
  }, [])

  useEffect(() => {
    if (currentPageComment > totalCommentPages) {
      setCurrentPageComment(totalCommentPages || 1)
    }
  }, [comments, currentPageComment, totalCommentPages])

  useEffect(() => {
    if (listData || isPopupOpen || commentSection) {
      lockScroll()
    } else {
      unlockScroll()
    }

    return () => {
      unlockScroll()
    }
  }, [listData, isPopupOpen, commentSection])

  return (
    <div className="feeds-main">
      <div className="feed-section">
        {isFeedLoading ? (
          <div className="feeds-item common-card-body mt-30">
            <div className="feeds-profile">
              <div className="feeds-profile-main">
                <Skeleton circle width={45} height={45} />
                <ul>
                  <li className="name">
                    <Skeleton width={150} height={10} />
                  </li>
                  <li className="designation">
                    <Skeleton width={50} height={10} />
                  </li>
                </ul>
              </div>
              <div className="open_report">
                <Skeleton width={30} height={10} />
              </div>
            </div>

            <Skeleton
              className="mt-3 mb-3 rounded-3"
              max-width={1118}
              height={348}
            />
            <div className="feeds-detail">
              {/* <h6><Skeleton width={20} height={20} /></h6> */}
              {/* <p><Skeleton width={20} height={20} /></p> */}
            </div>
            <div className="feeds-button">
              <div className="feed-btn-group">
                <button type="button">
                  <Skeleton className="rounded-3" width={20} height={20} />
                  <Skeleton width={50} height={10} />
                </button>
                <button type="button">
                  <Skeleton className="rounded-3" width={20} height={20} />
                  <Skeleton width={50} height={10} />
                </button>
                <button type="button">
                  <Skeleton className="rounded-3" width={20} height={20} />
                  <Skeleton width={50} height={10} />
                </button>
              </div>
              <div className="save-btn">
                <button type="button">
                  <Skeleton className="rounded-3" width={20} height={20} />
                  <Skeleton width={40} height={10} />
                </button>
              </div>
            </div>
          </div>
        ) : (
          feedData.map((item, index) => (
            <div className="feeds-item common-card-body mt-30" key={index}>
              <div className="feeds-profile">
                <div className="feeds-profile-main">
                  <img alt="" src={s3ImagePath + item.profile_icon} />
                  <ul>
                    <li className="name">{item.user_name}</li>
                    <li className="designation">{item.location}</li>
                  </ul>
                </div>
                <div className="open_report">
                  <img
                    alt=""
                    src={DropLink}
                    onClick={() => showReportList(item.post_id)}
                  />
                </div>
              </div>
              <div
                className="feeds-video"
                style={{
                  background: `url(${s3ImagePathCreator + item.attachment})`,
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'cover',
                  backgroundPosition: 'center'
                }}
              />
              <div className="feeds-detail">
                <h6>{item.feed}</h6>
                <p>
                  {' '}
                  {item.description.slice(0, 65)}
                  {item.description.length > 65 && '...'}
                </p>
              </div>
              <div className="feeds-button">
                <div className="feed-btn-group">
                  {item.is_like === true ? (
                    <button
                      type="button"
                      onClick={() => onClickLike(item.post_id, index)}
                    >
                      <img alt="" src={Like} />
                      Unlike
                    </button>
                  ) : (
                    <button
                      type="button"
                      onClick={() => onClickLike(item.post_id, index)}
                    >
                      <img alt="" src={UnLike} />
                      Like
                    </button>
                  )}
                  <button
                    type="button"
                    onClick={() => onClickComment(item.post_id)}
                  >
                    <img alt="" src={Comment} />
                    Comment
                  </button>
                  <button type="button" onClick={showShareList}>
                    <img alt="" src={Share} />
                    Share
                  </button>
                </div>
                <div className="save-btn">
                  {item.is_save === true ? (
                    <button
                      type="button"
                      onClick={() => onClickSave(item.post_id, index)}
                    >
                      <img alt="" src={Saved} />
                      {' '}
                      UnSave
                    </button>
                  ) : (
                    <button
                      type="button"
                      onClick={() => onClickSave(item.post_id, index)}
                    >
                      <img alt="" src={Save} />
                      {' '}
                      Save
                    </button>
                  )}
                </div>
              </div>

              {listData && (
                <div className="add-item-model edit-profile">
                  <div className="add-item-content">
                    <div className="d-flex justify-content-between header">
                      <h3>Share List</h3>
                      <div className="items-filter">
                        <div className="position-relative input-position">
                          <CommonInput
                            placeholder="Search Follower"
                            onChange={(e) => {
                              handleSearch(e.target.value)
                            }}
                          />
                        </div>
                      </div>
                      <button
                        type="button"
                        className="border-0 bg-none p-0"
                        onClick={handleCancel}
                      >
                        <span>
                          <img className="" src={CloseIco} alt="" />
                        </span>
                      </button>
                    </div>

                    <div className="scroll-bars feed-scroll">
                      <div className="item-details-input pt-0">
                        <div className="list_data">
                          {isCreatorListDataLoading
                            ? Array(6)
                                .fill(0)
                                .map((_, index) => (
                                  <div
                                    className="d-flex justify-content-start list_table"
                                    key={index}
                                  >
                                    <span className="number_index">
                                      <Skeleton
                                        className="rounded-3"
                                        width={20}
                                        height={20}
                                      />
                                    </span>
                                    <Skeleton circle width={45} height={45} />
                                    <span className="user-name">
                                      <Skeleton
                                        className="rounded-3"
                                        width={200}
                                        height={25}
                                      />
                                    </span>
                                  </div>
                                ))
                            : shareDataList.map((item, index) => {
                                const calculatedIndex =
                                  (currentPage - 1) * videosPerPage + index + 1
                                return (
                                  <div
                                    className="d-flex justify-content-start list_table"
                                    key={index}
                                  >
                                    <span className="number_index">
                                      {calculatedIndex}
                                    </span>
                                    <img
                                      src={s3ImagePath + item.profile_icon}
                                      className="profile-pic"
                                      alt="profile"
                                    />
                                    <span className="user-name">
                                      {item.user_name}
                                    </span>
                                  </div>
                                )
                              })}
                        </div>
                      </div>
                    </div>
                    {totalPages > 1 && (
                      <div className="pagination-containers-list-comment">
                        <button
                          type="button"
                          className={`pagination-button-comment ${
                            currentPage === 1 ? 'disabled' : ''
                          }`}
                          onClick={handlePrevPage}
                          disabled={currentPage === 1}
                        >
                          Prev
                        </button>
                        {renderPaginationButtons()}
                        <button
                          type="button"
                          className={`pagination-button ${
                            currentPage === totalPages ? 'disabled' : ''
                          }`}
                          onClick={handleNextPage}
                          disabled={currentPage === totalPages}
                        >
                          Next
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
          ))
        )}
        {feedTotalPages > 1 && (
          <Pagination
            currentPage={feedCurrentPage}
            totalPages={feedTotalPages}
            onPageChange={handlePageChange}
          />
        )}
      </div>
      {isPopupOpen && (
        <div className="add-item-model">
          <div className="add-item-content feed-report">
            <div className="d-flex justify-content-between header">
              <h3>Report Feed</h3>
              <button
                type="button"
                className="border-0 bg-none p-0"
                onClick={close}
              >
                <span>
                  <img className="" src={CloseIco} alt="clock icon" />
                </span>
              </button>
            </div>

            <div className="scroll-bars">
              <div className="item-details-input pt-0">
                {isFeedReportLoading ? (
                  Array(8)
                    .fill(0)
                    .map((_, index) => (
                      <div
                        className="d-flex justify-content-start list_table gap-3 mb-4"
                        key={index}
                      >
                        <Skeleton
                          className="rounded-3"
                          width={20}
                          height={20}
                        />
                        <Skeleton
                          className="rounded-3"
                          width={200}
                          height={20}
                        />
                      </div>
                    ))
                ) : (
                  <div className="list_data">
                    {popupData.map((list, index) => {
                      return (
                        <div
                          className="d-flex justify-content-start list_table"
                          key={index}
                        >
                          <div
                            onClick={() => feedReport(list.report_id)}
                            className="table_data"
                          >
                            <span className="number_index">
                              {list.report_id}
                            </span>
                            <span className="user-name">{list.reason}</span>
                          </div>
                        </div>
                      )
                    })}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
      {commentSection && (
        <div className="add-item-modal">
          <div className="add-item-contents p-4">
            <div className="d-flex align-items-center justify-content-between">
              <h3 className="mb-0">Feed Comment Section</h3>
              <button
                type="button"
                className="secondary-btn-modal"
                onClick={HandleCancel}
              >
                <img className="" src={CloseIco} alt="close icon" />
              </button>
            </div>
            <div className="comment-section">
              {isLoadingComments ? (
                <div>
                  <div className="comments-container">
                    <ul className="comments-list">
                      <li className="comment-item">
                        <button type="button" className="comment-delete">
                          <Skeleton
                            className="rounded-3"
                            width={20}
                            height={20}
                          />
                        </button>
                        <div className="comment-header mb-0 gap-2">
                          <Skeleton circle width={45} height={45} />
                          <span className="user-name">
                            <Skeleton width={150} height={20} />
                          </span>
                        </div>
                        <div className="comment-btns">
                          <button type="button">
                            <Skeleton width={20} height={20} />
                          </button>
                          <Skeleton width={20} height={20} />
                          <button type="button">
                            <Skeleton width={45} height={20} />
                          </button>
                          <button type="button">
                            <Skeleton width={45} height={20} />
                          </button>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div className="add-comment">
                    <input
                      type="text"
                      placeholder="Add a comment..."
                      value={newComment}
                      onChange={(e) => setNewComment(e.target.value)}
                      name="reply"
                    />
                    <button
                      type="button"
                      onClick={() => handleAddComment(newComment)}
                    >
                      Add
                    </button>
                  </div>
                </div>
              ) : (
                <div>
                  <div className="comments-container">
                    {commentsList.length === 0 ? (
                      <div className="no-comments-message">No Comments Yet</div>
                    ) : (
                      <ul className="comments-list">
                        {commentsList.map((comment, index) => (
                          <li key={comment.id} className="comment-item">
                            {isDeleting && (
                              <div className="loader-overlay">
                                {commentIndex === index && <Loader />}
                              </div>
                            )}
                            {loadingCommentId === comment.id && (
                            <div className="loader-overlay">
                              <Loader />
                            </div>
                            )}
                            {comment?.user?.name === profileData?.user_name && (
                              <button
                                type="button"
                                className="comment-delete"
                                onClick={() => {
                                  setCommentIndex(index)
                                  feedCommentDelete(comment.reply_id)
                                }}
                              >
                                <img src={Delete} alt="delete icon" />
                              </button>
                            )}
                            <div className="comment-header mb-0">
                              <img
                                src={comment?.user?.profilePic}
                                alt={`${comment?.user?.name}'s profile`}
                                className="profile-pic"
                              />
                              <span className="user-name">
                                {comment?.user?.name}
                              </span>
                            </div>
                            {editCommentId === comment.id ? (
                              <div className="edit-reply">
                                <input
                                  type="text"
                                  value={editedCommentText}
                                  onChange={(e) => setEditedCommentText(e.target.value)}
                                />
                                <button
                                  type="button"
                                  onClick={() => handleSaveComment(
                                      comment.reply_id,
                                      editedCommentText
                                    )}
                                >
                                  Save
                                </button>
                              </div>
                            ) : (
                              <div className="comment-text">{comment.text}</div>
                            )}
                            <div className="comment-btns">
                              <button
                                type="button"
                                onClick={() => handleLikeComment(comment.reply_id)}
                              >
                                {comment.is_like ? (
                                  <img src={Like} alt="Unlike" />
                                ) : (
                                  <img src={UnLike} alt="Like" />
                                )}
                                {' '}
                                {comment?.total_like}
                              </button>
                              {comment?.user?.name === profileData?.user_name && (
                                <button
                                  type="button"
                                  onClick={() => handleEditComment(comment.id, comment.text)}
                                >
                                  Edit
                                </button>
                              )}
                              <button
                                type="button"
                                onClick={() => toggleReplies(comment.id)}
                              >
                                {/* {showReplies[comment.id] ? 'Hide' : 'Show'}
                                {' '}
                                Replies */}
                                {showReplies[comment.id] ? 'Hide Replies' : `Show Replies (${comment?.reply?.length})`}
                              </button>
                            </div>
                            {showReplies[comment.id] && (
                              <ul className="replies-list">
                                {comment.reply.map((reply) => (
                                  <li key={reply.id} className="reply-item">
                                    <div className="reply-header mb-0">
                                      <img
                                        src={reply?.user?.profilePic}
                                        alt={`${reply?.user?.name}'s profile`}
                                        className="profile-pic"
                                      />
                                      <span className="user-name">
                                        {reply.user.name}
                                      </span>
                                      {reply?.user?.name ===
                                        profileData?.user_name && (
                                        <button
                                          type="button"
                                          onClick={() => {
                                            setCommentIndex(index)
                                            handleDeleteReply(
                                              comment.reply_id,
                                              reply.reply_id
                                            )
                                          }}
                                          className="comment-delete"
                                        >
                                          <img src={Delete} alt="delete icon" />
                                        </button>
                                      )}
                                    </div>
                                    {editReplyId === reply.id ? (
                                      <div className="edit-reply">
                                        <input
                                          type="text"
                                          value={editedReplyText}
                                          onChange={(e) => setEditedReplyText(e.target.value)}
                                        />
                                        <button
                                          type="button"
                                          onClick={() => handleSaveReply(
                                              reply.reply_id,
                                              editedReplyText
                                            )}
                                        >
                                          Save
                                        </button>
                                      </div>
                                    ) : (
                                      <div className="reply-text">
                                        {reply.text}
                                      </div>
                                    )}
                                    <div className="comment-btns">
                                      <button
                                        type="button"
                                        onClick={() => handleLikeReply(reply.reply_id)}
                                      >
                                        {reply.is_like ? (
                                          <img src={Like} alt="Unlike" />
                                        ) : (
                                          <img src={UnLike} alt="Like" />
                                        )}
                                        {' '}
                                        {reply.total_like}
                                      </button>
                                      {reply?.user?.name ===
                                        profileData?.user_name && (
                                        <button
                                          type="button"
                                          onClick={() => handleEditReply(
                                              reply.id,
                                              reply.text
                                            )}
                                        >
                                          Edit
                                        </button>
                                      )}
                                    </div>
                                  </li>
                                ))}
                                <li className="add-reply">
                                  <input
                                    type="text"
                                    placeholder="Reply..."
                                    value={Replied}
                                    onChange={(e) => setShowReplied(e.target.value)}
                                  />
                                  <button
                                    type="button"
                                    onClick={() => handleAddReply(comment.reply_id, Replied)}
                                  >
                                    Reply
                                  </button>
                                </li>
                              </ul>
                            )}
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                  {totalCommentPages > 1 && (
                  <CommentPagination
                    currentPage={currentPageComment}
                    totalPages={totalCommentPages}
                    onPageChange={handlePageChanges}
                  />
                  )}
                  <div className="add-comment">
                    <input
                      type="text"
                      placeholder="Add a comment..."
                      value={newComment}
                      onChange={(e) => setNewComment(e.target.value)}
                      name="reply"
                    />
                    <button
                      type="button"
                      className="comment_add_btn"
                      onClick={() => handleAddComment(
                            newComment,
                          )}
                    >
                      Add
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
export default Feeds
