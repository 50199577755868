/* eslint-disable no-unused-vars */
/* eslint-disable no-useless-escape */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-lone-blocks */
import React, { useEffect, useMemo, useState } from 'react'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import local_add_img from '../../../images/add_a_photo.svg'
import Remove from '../../../images/close-black.svg'
import Back from '../../../images/back-btn.svg'
import CommonImageUpload from '../../ImageCroper/StoreImageCroper'
import CommonInput from '../../InputComponet/CommonInput'
import CommonButton from '../../ButtonComponent/ButtonCommon'
import { capitalizeFirstLetter } from '../../../helpers/Utility'
import {
  cityList,
  countryList,
  imageUploadLocal,
  organizerAdd,
  organizerEdit,
  provinceList,
  s3ImagePathLocal
} from '../../../services/Service'
import { compressImage, dataDecrypt } from '../../../services/http-services'
import Dropdown from '../../Dropdown/Dropdown'
import MapComponent from '../../MapComponent/MapComponent'
import plusgredient from '../../../images/plus-rounded.svg'
import plusimg from '../../../images/plusimg.svg'
import Loader from '../../Loader/Loader'

const eventPopup = ({
  onCancel,
  defaultData = {},
  apiSuccess = () => {},
  setIsShowEventList,
  setIsShow,
  particularEventApiCall = () => {},
  editEvent
}) => {
  const navigate = useNavigate()
  const [isCountryList, setIsCountryList] = useState([])
  const [isProvinceList, setIsProvinceList] = useState([])
  const [isCityListData, setIsCityListData] = useState([])
  const [inputFields, setInputFields] = useState({
    name: '',
    description: '',
    city_id: '',
    zip_code: '',
    longitude: '',
    latitude: '',
    website: '',
    logo: '',
    images: [],
    contact_name1: '',
    contact_name2: '',
    contact_designation1: '',
    contact_designation2: '',
    contact_phone1: '',
    contact_phone2: '',
    contact_profile_pic: [],
    organizer_id: '',
    country_id: '',
    province_id: '',
    country: '',
    city_name: '',
    province_name: ''
  })
  const [markerPosition, setMarkerPosition] = useState(null)

  const [isImageLoading, setIsImageLoading] = useState({
    index: null,
    loading: false
  })
  const [draggedIndex, setDraggedIndex] = useState(null)
  const [errors, setErrors] = useState('')
  const [isStep1Modified, setIsStep1Modified] = useState(false)
  const [isStep2Modified, setIsStep2Modified] = useState(false)
  const [isStep3Modified, setIsStep3Modified] = useState(false)

  const urlRegex =
    /^(https?|ftp):\/\/(([a-z\d]([a-z\d-]*[a-z\d])?\.)+[a-z]{2,}|localhost)(\/[-a-z\d%_.~+]*)*(\?[;&a-z\d%_.~+=-]*)?(\#[-a-z\d_]*)?$/i

  const handleCancelStep2 = () => {
    onCancel()
  }

  const handleCancels = () => {
    setCurrentStep(0)
    onCancel()
  }

  const onChange = (e) => {
    const { name, value } = e.target

    setInputFields((prev) => ({
      ...prev,
      [name]: value
    }))

    if (name === 'website' && urlRegex.test(value)) {
      setErrors('')
    }
  }

  const onUploadImage = async (file, fieldName, index) => {
    if (file) {
      const reader = new FileReader()
      reader.onload = async () => {
        const imageFile = await compressImage(file)
        const coverFormData = new FormData()
        coverFormData.append('file', imageFile)
        {
          if (fieldName === 'contact_profile_pic') {
            coverFormData.append('type', 'profile_pic')
          } else if (fieldName === 'logo') {
            coverFormData.append('type', 'logo')
          } else {
            coverFormData.append('type', 'event')
          }
        }
        const uploadResponse = await imageUploadLocal(coverFormData)

        if (!uploadResponse.data.success) {
          throw new Error(
            uploadResponse.data.message || `${fieldName} upload failed`
          )
        }

        const decryptedImageData = dataDecrypt(uploadResponse.data.data)

        setInputFields((prev) => {
          const prevData = prev[fieldName]
          if (
            (index !== undefined || index !== null) &&
            Array.isArray(prevData)
          ) {
            prevData[index] = decryptedImageData
          }
          return {
            ...prev,
            [fieldName]: fieldName === 'logo' ? decryptedImageData : prevData
          }
        })
      }

      reader.readAsDataURL(file)
    }
  }

  const handleMapPositionChange = (lat, lng) => {
    setMarkerPosition({ lat, lng })
    setInputFields((prev) => ({
      ...prev,
      latitude: lat,
      longitude: lng
    }))
  }

  const handleClick = () => {
    document.getElementById('upload_button').click()
  }

  const removeImage = (index) => {
    const updatedImages = [...inputFields.images]
    updatedImages.splice(index, 1)
    setInputFields((prev) => ({
      ...prev,
      images: updatedImages
    }))
  }

  const handleDragStart = (index) => {
    setDraggedIndex(index)
  }

  const handleDrop = (index) => {
    if (index !== draggedIndex) {
      setInputFields((prev) => {
        const copy = [...prev.images]
        const b = copy[draggedIndex]
        copy[draggedIndex] = copy[index]
        copy[index] = b
        return {
          ...prev,
          images: copy
        }
      })

      setDraggedIndex(null)
    }
  }

  const handleLoad = (index) => {
    setIsImageLoading({
      index,
      loading: true
    })
    setTimeout(() => {
      setIsImageLoading({
        index: null,
        loading: false
      })
    }, 500)
  }

  const renderImg = useMemo(() => {
    return (
      <>
        {inputFields.images.map((image, index) => {
          return (
            <div
              className={`image position-relative ${
                index === 0 ? 'first-image' : ''
              }`}
              key={index}
              alt={`image-${index}`}
              draggable
              onDragStart={() => {
                handleDragStart(index)
              }}
              onDragOver={(event) => event.preventDefault()}
              onDragEnter={(event) => event.preventDefault()}
              onDrop={(event) => {
                event.preventDefault()
                handleDrop(index)
              }}
            >
              {isImageLoading.index === index && isImageLoading.loading && (
                <Loader />
              )}
              <img
                className={`image position-relative ${
                  index === 0 ? 'first-image' : ''
                }`}
                src={
                  typeof image === 'string'
                    ? s3ImagePathLocal + image
                    : URL.createObjectURL(image)
                }
                alt={`image-${index}`}
                style={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover'
                }}
                onLoad={() => handleLoad(index)}
              />
              <button
                className="image_remove_icon"
                type="button"
                onClick={() => removeImage(index)}
              >
                <img src={Remove} alt="" />
              </button>
              <button type="button" className="plus-img">
                <CommonImageUpload
                  handleImageChange={(file) => {
                    onUploadImage(file, 'images', index)
                  }}
                  selectedImg=""
                  defaultImg={plusimg}
                  className="plsimg"
                />
              </button>
            </div>
          )
        })}
      </>
    )
  }, [inputFields.images, handleDragStart])

  // api call

  const countryListData = () => {
    countryList({}).then((res) => {
      if (res.data.success) {
        setIsCountryList(dataDecrypt(res.data.data))
      }
    })
  }

  const provinceListData = (data) => {
    provinceList(data).then((res) => {
      if (res.data.success) {
        setIsProvinceList(dataDecrypt(res.data.data))
      }
    })
  }

  const cityListData = (data) => {
    cityList(data).then((res) => {
      if (res.data.success) {
        setIsCityListData(dataDecrypt(res.data.data).data)
      }
    })
  }

  const organizerAddApiCall = () => {
    let payload = {
      name: inputFields.name,
      description: inputFields.description,
      city_id: inputFields.city_id,
      zip_code: inputFields.zip_code,
      longitude: inputFields.longitude,
      latitude: inputFields.latitude,
      website: inputFields.website,
      logo: inputFields.logo,
      images: inputFields.images,
      contact_name: [
        inputFields.contact_name1,
        inputFields.contact_name2
      ].filter((item) => item !== '' && item !== null),
      contact_designation: [
        inputFields.contact_designation1,
        inputFields.contact_designation2
      ].filter((item) => item !== '' && item !== null),
      contact_phone: [
        inputFields.contact_phone1,
        inputFields.contact_phone2
      ].filter((item) => item !== '' && item !== null),
      contact_profile_pic: inputFields.contact_profile_pic,
      organizer_id: inputFields.organizer_id
    }
    payload = Object.fromEntries(
      Object.entries(payload).filter(
        ([key, value]) => !(
            value === '' ||
            (Array.isArray(value) &&
              (value.length === 0 || value.every((item) => item === '')))
          )
      )
    )

    if (Object.keys(defaultData).length > 0) {
      organizerEdit(payload).then((res) => {
        if (res.data.success) {
          toast.success(res?.data?.message)
          apiSuccess()
          setCurrentStep(0)
          particularEventApiCall(inputFields.organizer_id)
          setIsShowEventList(false)
          setIsShow(true)
        } else {
          toast.error(res?.data?.message)
        }
      })
    } else {
      organizerAdd(payload).then((res) => {
        if (res.data.success) {
          toast.success(res?.data?.message)
          apiSuccess()
          setCurrentStep(0)
          navigate('/event')
        } else {
          toast.error(res?.data?.message)
        }
      })
    }
  }

  const getCountryName = (country_id) => {
    const categoryObj = isCountryList.find(
      (cat) => cat.country_id === country_id
    )
    return categoryObj ? categoryObj.country_name : 'Select country'
  }
  const getProvinceName = (province_id) => {
    const categoryObj = isProvinceList.find(
      (cat) => cat.province_id === province_id
    )
    return categoryObj ? categoryObj.province_name : 'Select province'
  }
  const getCityName = (city_id) => {
    const categoryObj = isCityListData.find((cat) => cat.city_id === city_id)
    return categoryObj ? categoryObj.city_name : 'Select city'
  }

  useEffect(() => {
    provinceListData({
      country_id: inputFields.country_id
    })
  }, [inputFields.country_id])

  useEffect(() => {
    cityListData({
      province_id: inputFields.province_id
    })
  }, [inputFields.province_id])

  useEffect(() => {
    countryListData()
  }, [])

  useEffect(() => {
    if (Object.keys(defaultData).length > 0) {
      setInputFields(defaultData)
    }
  }, [defaultData])

  const [currentStep, setCurrentStep] = useState(1)

  const handleStartStep2 = () => {
    setErrors('')
    if (inputFields.website && !urlRegex.test(inputFields.website)) {
      setErrors('Website URL format is not valid')
      return
    }
    if (currentStep === 1 && editEvent) {
      setCurrentStep(3)
    } else {
      setCurrentStep(currentStep + 1)
    }
  }

  // const handleNextClick = () => {
  //   if (currentStep < 3) {
  //     setCurrentStep(currentStep + 1)
  //   } else {
  //     organizerAddApiCall()
  //   }
  // }

  // const handleBackClick = () => {
  //   if (currentStep > 1) {
  //     setCurrentStep(currentStep - 1)
  //   }
  // }

  const handleNextClick = () => {
    if (currentStep < 3) {
      if (currentStep === 1 && editEvent) {
        setCurrentStep(3)
      } else {
        setCurrentStep(currentStep + 1)
      }
    } else {
      organizerAddApiCall()
    }
  }

  const handleBackClick = () => {
    if (currentStep > 1) {
      if (currentStep === 3 && editEvent) {
        setCurrentStep(1)
      } else {
        setCurrentStep(currentStep - 1)
      }
    }
  }

  useEffect(() => {
    setIsStep1Modified(
      inputFields.logo !== defaultData.logo ||
        inputFields.name !== defaultData.name ||
        inputFields.country_id !== defaultData.country_id ||
        inputFields.province_id !== defaultData.province_id ||
        inputFields.city_id !== defaultData.city_id ||
        inputFields.zip_code !== defaultData.zip_code ||
        inputFields.website !== defaultData.website ||
        inputFields.description !== defaultData.description
    )
    setIsStep2Modified(
      inputFields.contact_profile_pic !== defaultData.contact_profile_pic ||
        inputFields.contact_name1 !== defaultData.contact_name1 ||
        inputFields.contact_name2 !== defaultData.contact_name2 ||
        inputFields.contact_designation1 !== defaultData.contact_designation1 ||
        inputFields.contact_designation2 !== defaultData.contact_designation2 ||
        inputFields.contact_phone1 !== defaultData.contact_phone1 ||
        inputFields.contact_phone2 !== defaultData.contact_phone2
    )
    setIsStep3Modified(inputFields.images !== defaultData.images)
  }, [inputFields, defaultData])

  const isStep1Valid = () => {
    return (
      isStep1Modified &&
      inputFields.logo &&
      inputFields.name &&
      inputFields.country_id &&
      inputFields.province_id &&
      inputFields.city_id &&
      inputFields.zip_code.length > 5 &&
      inputFields.website &&
      inputFields.description &&
      markerPosition
    )
  }

  const isStep2Valid = () => {
    return (
      isStep2Modified &&
      inputFields.contact_profile_pic.length === 2 &&
      inputFields.contact_name1 &&
      inputFields.contact_name2 &&
      inputFields.contact_designation1 &&
      inputFields.contact_designation2 &&
      inputFields.contact_phone1.length > 9 &&
      inputFields.contact_phone2.length > 9
    )
  }
  const isStep3Valid = () => {
    return isStep3Modified && inputFields.images.length === 1
  }

  return (
    <div className="content-manager">
      {currentStep === 1 && (
        <div className="add-item-model">
          <div className="add-item-content local_type_modal contact_padding">
            <div className="type_close mb-2 local_sticky_header">
              <div className="d-flex align-items-center col-gap-10">
                <img
                  src={Back}
                  className="back_btn"
                  alt="back"
                  onClick={handleCancelStep2}
                />
                EVENT ORGANIZER DETAILS
              </div>
              <button
                className="cancel_icon"
                type="button"
                onClick={handleCancels}
              >
                <img src={Remove} alt="" />
              </button>
            </div>
            <div className="item-details-input pt-0 local_scroll_bars">
              <div className="logo_btn mb-2">
                <div className="add_logo">
                  <CommonImageUpload
                    id="upload_button"
                    name="local_upload_logo"
                    handleImageChange={(file) => onUploadImage(file, 'logo')}
                    index={0}
                    forImage="local_logo"
                    className="profile-upload"
                    selectedImg={
                      inputFields.logo
                        ? `${s3ImagePathLocal}${inputFields.logo}`
                        : ''
                    }
                    defaultImg={local_add_img}
                  />
                </div>
                <div className="upload_btn_logo">
                  <CommonButton
                    label="UPLOAD FILE"
                    context="local"
                    className="local-secondary-button mb-2"
                    onClick={handleClick}
                  />
                  <p className="image_validation mb-0">
                    At least 400x400 px recommended.
                    <br />
                    JPEG or PNG is allowed
                  </p>
                </div>
              </div>
              <div className="input-grid">
                <div>
                  <label>Organizer Name</label>
                  <CommonInput
                    placeholder="Enter name"
                    name="name"
                    onChange={onChange}
                    value={capitalizeFirstLetter(inputFields.name)}
                  />
                </div>
                <div>
                  <label>Country</label>
                  <Dropdown
                    title={getCountryName(inputFields.country_id)}
                    name="type_id"
                    onChanged={(d) => {
                      setInputFields((prev) => ({
                        ...prev,
                        country_id: d.id,
                        country: d.title
                      }))
                    }}
                    Data={isCountryList.map((d) => ({
                      title: d.country_name,
                      id: d.country_id
                    }))}
                    customClass="local-dropdowns"
                  />
                </div>
              </div>
              <div className="input-grid mb-3">
                <div>
                  <label>Province List</label>
                  <Dropdown
                    title={getProvinceName(inputFields.province_id)}
                    name="type_id"
                    onChanged={(d) => {
                      setInputFields((prev) => ({
                        ...prev,
                        province_id: d.id,
                        province_name: d.title
                      }))
                    }}
                    Data={isProvinceList.map((d) => ({
                      title: d.province_name,
                      id: d.province_id
                    }))}
                    customClass="local-dropdowns"
                  />
                </div>
                <div>
                  <label>City List</label>
                  <Dropdown
                    title={getCityName(inputFields.city_id)}
                    name="type_id"
                    onChanged={(d) => {
                      setInputFields({
                        ...inputFields,
                        city_id: d.id,
                        city_name: d.title
                      })
                    }}
                    Data={isCityListData.map((d) => ({
                      title: d.city_name,
                      id: d.city_id
                    }))}
                    customClass="local-dropdowns"
                  />
                </div>
              </div>
              <div className="input-grid">
                <div>
                  <label>Zip Code</label>
                  <CommonInput
                    placeholder="Enter here"
                    name="zip_code"
                    type="number"
                    className="mb-0"
                    onChange={onChange}
                    pattern="/^-?\d+\.?\d*$/"
                    maxLength={6}
                    onKeyPress={(e) => {
                      if (e.target.value.length === 6) {
                        e.preventDefault()
                      }
                    }}
                    value={inputFields.zip_code}
                  />
                </div>
                <div>
                  <label>Website Link</label>
                  <CommonInput
                    placeholder="Enter website link"
                    name="website"
                    onChange={onChange}
                    value={inputFields.website}
                  />
                  {errors && <div className="error-message">{errors}</div>}
                </div>
              </div>
              <div className="mt-3 mb-3">
                <label>Please Select Location In Map</label>
                <MapComponent
                  inputFields={inputFields}
                  setInputFields={setInputFields}
                  onMapPositionChange={handleMapPositionChange}
                  markerPosition={markerPosition}
                />
              </div>
              <div className="">
                <div>
                  <label>Description</label>
                  <CommonInput
                    placeholder="Briefly describe about your event"
                    name="description"
                    onChange={onChange}
                    isTextArea
                    value={capitalizeFirstLetter(inputFields.description)}
                    maxLength={250}
                  />
                </div>
              </div>
            </div>
            <div className="item-details-input pt-0">
              <div className="local_button_row local_sticky_btn">
                <CommonButton
                  label="Back"
                  onClick={handleCancelStep2}
                  isPrimary={false}
                  context="Profile"
                />
                <CommonButton
                  label="Next"
                  type="button"
                  isPrimary
                  onClick={handleStartStep2}
                  context="Profile"
                  disabled={!isStep1Valid()}
                />
              </div>
            </div>
          </div>
        </div>
      )}

      {currentStep === 2 && (
        <div className="add-item-model">
          <div className="add-item-content local_type_modal contact_padding">
            <div className="type_close mb-2 local_sticky_header">
              <div className="d-flex align-items-center col-gap-10">
                <img
                  src={Back}
                  className="back_btn"
                  alt="back"
                  onClick={handleBackClick}
                />
                Contact Person
              </div>
              <button
                className="cancel_icon"
                type="button"
                onClick={handleCancels}
              >
                <img src={Remove} alt="" />
              </button>
            </div>
            <div className="item-details-input pt-0 local_contact_scroll_bars">
              <h6 className="fw-bolder">Contact Person</h6>
              <div className="logo_btn">
                <div className="image_upload_section">
                  <div className="add_photos mb-4 mt-2">
                    <div className="add_logo_text text-center align-self-center">
                      <CommonImageUpload
                        name="local_upload_logo"
                        handleImageChange={(file) => onUploadImage(file, 'contact_profile_pic', 0)}
                        index={0}
                        forImage="local_photo"
                        className="profile-upload"
                        selectedImg={
                          inputFields.contact_profile_pic[0]
                            ? `${s3ImagePathLocal}${inputFields.contact_profile_pic[0]}`
                            : ''
                        }
                        defaultImg={local_add_img}
                      />
                    </div>
                  </div>
                </div>
                <div className="person_detail_section">
                  <div>
                    <label>Name</label>
                    <CommonInput
                      placeholder="Name"
                      onChange={onChange}
                      name="contact_name1"
                      maxLength={20}
                      allowTextOnly
                      value={capitalizeFirstLetter(inputFields.contact_name1)}
                    />
                  </div>
                  <div>
                    <label>Designation</label>
                    <CommonInput
                      placeholder="Designation"
                      onChange={onChange}
                      name="contact_designation1"
                      allowTextOnly
                      maxLength={20}
                      value={capitalizeFirstLetter(
                        inputFields.contact_designation1
                      )}
                    />
                  </div>
                  <div>
                    <label>Phone Number</label>
                    <CommonInput
                      placeholder="+4915234213935"
                      name="contact_phone1"
                      type="number"
                      pattern="/^-?\d+\.?\d*$/"
                      maxLength={10}
                      onChange={onChange}
                      onKeyPress={(e) => {
                        if (e.target.value.length === 10) {
                          e.preventDefault()
                        }
                      }}
                      value={inputFields.contact_phone1}
                    />
                  </div>
                </div>
              </div>
              <div className="logo_btn">
                <div className="image_upload_section">
                  <div className="add_photos mb-4 mt-2">
                    <div className="add_logo_text text-center align-self-center">
                      <CommonImageUpload
                        name="local_upload_logo"
                        handleImageChange={(file) => onUploadImage(file, 'contact_profile_pic', 1)}
                        index={1}
                        forImage="local_photo"
                        className="profile-upload"
                        selectedImg={
                          inputFields.contact_profile_pic[1]
                            ? `${s3ImagePathLocal}${inputFields.contact_profile_pic[1]}`
                            : ''
                        }
                        defaultImg={local_add_img}
                      />
                    </div>
                  </div>
                </div>
                <div className="person_detail_section">
                  <div>
                    <label>Name</label>
                    <CommonInput
                      placeholder="Name"
                      onChange={onChange}
                      name="contact_name2"
                      allowTextOnly
                      maxLength={20}
                      value={capitalizeFirstLetter(inputFields.contact_name2)}
                    />
                  </div>
                  <div>
                    <label>Designation</label>
                    <CommonInput
                      placeholder="Designation"
                      onChange={onChange}
                      name="contact_designation2"
                      allowTextOnly
                      maxLength={20}
                      value={capitalizeFirstLetter(
                        inputFields.contact_designation2
                      )}
                    />
                  </div>
                  <div>
                    <label>Phone Number</label>
                    <CommonInput
                      placeholder="+4915234213935"
                      name="contact_phone2"
                      type="number"
                      pattern="/^-?\d+\.?\d*$/"
                      maxLength={10}
                      onChange={onChange}
                      onKeyPress={(e) => {
                        if (e.target.value.length === 10) {
                          e.preventDefault()
                        }
                      }}
                      value={inputFields.contact_phone2}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="item-details-input pt-0">
              <div className="local_button_row local_sticky_btn">
                <CommonButton
                  label="Skip"
                  onClick={handleNextClick}
                  isPrimary={false}
                  context="Profile"
                />
                <CommonButton
                  label="Next"
                  type="button"
                  isPrimary
                  onClick={handleNextClick}
                  context="Profile"
                  disabled={!isStep2Valid()}
                />
              </div>
            </div>
          </div>
        </div>
      )}

      {currentStep === 3 && (
        <div className="add-item-model">
          <div className="add-item-content local_type_modal">
            <div className="type_close mb-2">
              <div className="d-flex align-items-center col-gap-10">
                <img
                  src={Back}
                  className="back_btn"
                  alt="back"
                  onClick={handleBackClick}
                />
                EVENT PHOTOS
              </div>
              <button
                className="cancel_icon"
                type="button"
                onClick={handleCancels}
              >
                <img src={Remove} alt="" />
              </button>
            </div>
            <div className="hotel_image_upload_section">
              <div
                className={
                  inputFields.images.length < 3
                    ? 'input-image-show justify-content-start'
                    : 'input-image-show justify-content-center'
                }
              >
                {renderImg}
                {inputFields.images.length < 4 && (
                  <div className="input-img-label">
                    <CommonImageUpload
                      handleImageChange={(file) => onUploadImage(file, 'images', inputFields.images.length)}
                      buttonText="Add Image"
                      selectedImg=""
                      defaultImg={plusgredient}
                      className="plsimg"
                    />
                  </div>
                )}
              </div>
            </div>
            <div className="local_button_row">
              <CommonButton
                label="Skip"
                onClick={handleNextClick}
                isPrimary={false}
                context="Profile"
              />
              <CommonButton
                label="Next"
                type="button"
                isPrimary
                onClick={handleNextClick}
                context="Profile"
                disabled={!isStep3Valid()}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
export default eventPopup
